<div *ngIf="showLoader">
  <lib-loading></lib-loading>
</div>
<div class="text-wait" *ngIf="showWaitText">
  {{ 'wait-nuvei-payment' | translate }}
</div>

<div class="form">
  <form id="card_number">
    <div id="card-field-placeholder" class="some initial css-classes">
      <!-- SFC Card widget will be inserted here. -->
    </div>
    <!-- Used to display form errors. -->
    <div id="scard-errors" role="alert"></div>
  </form>
</div>
