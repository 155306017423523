import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { environment } from '@env/environment';
import { NotificationService, PaymentMethod, TypeMessage } from 'repository';
import { CreateSubscriptionService } from '../create-subscription.service';
import { ErrorCode, Payment, TypeOfCreateSubscription } from '../enum';
import { IPaymentData } from '../interfaces';

// TODO: fix any
// tslint:disable-next-line:no-any
declare let Stripe: any;

@Component({
  selector: 'lib-payment-credit-card',
  templateUrl: './payment-credit-card.component.html',
  styleUrls: ['./payment-credit-card.component.scss'],
})
export class PaymentCreditCardComponent implements OnChanges {
  @Input() update: boolean;
  @Input() subscriptionsId: number;
  @Input() type: TypeOfCreateSubscription;
  @Input() promocodeId = 0;
  @Input() price = 0;

  @Input() set isPaid(value: boolean) {
    if (value === this._isPaid) {
      return;
    }

    this._isPaid = value;
    this.isPaidChange.emit(this._isPaid);
  }

  get isPaid() {
    return this._isPaid;
  }

  @Output() isPaidChange = new EventEmitter<boolean>();

  @Input() subscription: IPaymentData;
  @Output() subscriptionChange = new EventEmitter<IPaymentData>();

  @Output() next = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  // TODO: fix any
  // tslint:disable-next-line:no-any
  handler: any = null;

  private _isPaid = false;
  private readonly _style = {
    base: {
      color: '#dddddd',
      background: '#333333',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#dddddd',
        background: '#333333',
      },
      ':-webkit-autofill': {
        color: '#dddddd',
        background: '#333333',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  private readonly _stripe = Stripe(environment.stripe_key, {
    locale: 'en',
  });
  // TODO: fix any
  // tslint:disable-next-line:no-any
  private _card: any;

  private _clientSecret: string;

  constructor(
    private readonly _createSubscriptionService: CreateSubscriptionService,
    private readonly _notification: NotificationService
  ) {
    this._addInput();
  }

  ngOnChanges(): void {
    if (!this.update) return;

    this._showInput();
  }

  submit(): boolean {
    this.loading.emit(true);
    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this._createSubscribe((data) => {
          this._getClientSecret(data);
        });

        break;
      case TypeOfCreateSubscription.RESET_ACCOUNT:
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this._resetAccount((data) => {
          this._getClientSecret(data);
        });

        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this._createSubscriptionService.changePaymentMethod(
          Payment.STRIPE,
          this.subscriptionsId,
          (data) => {
            this._getClientSecret(data);
          }
        );
        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this._payAgreement((data) => {
          this._getClientSecret(data);
        });

        break;
      case TypeOfCreateSubscription.DEPOSIT:
        this._depositWallet((data) => {
          this._getClientSecret(data);
        });

        break;
      default:
        break;
    }

    return false;
  }

  get(): null {
    return null;
  }

  private _getClientSecret(data: IPaymentData): void {
    if (!data) {
      this.loading.emit(false);

      return;
    } else if (
      data.errorDoublePayment === ErrorCode.ERROR_CODE_DOUBLE_PAYMENT
    ) {
      this.loading.emit(false);
      // Show error to your customer
      this._notification.showBlankNotification(
        'Error: Payment has already been made, please wait for your account status to be updated',
        TypeMessage.ERROR
      );

      return;
    }

    this.isPaid = true;

    this._clientSecret = data?.clientSecret;
    this._clientSecret
      ? this._payWithCard(this._clientSecret)
      : this._setNewCard(data?.setupIntentClientSecret);
  }

  private _showInput(): void {
    if (!this._card) return;

    setTimeout(() => {
      this._card.mount('#card-element');
    });
  }

  private _resetAccount(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.resetAccount(
      PaymentMethod.CREDIT_CARD,
      this.price,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      }
    );
  }

  private _payAgreement(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.payAgreement(
      PaymentMethod.CREDIT_CARD,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      }
    );
  }

  private _depositWallet(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.depositWallet(
      PaymentMethod.CREDIT_CARD,
      this.price,
      (data) => {
        cb(data);
      }
    );
  }

  private _createSubscribe(cb: (data: IPaymentData | undefined) => void): void {
    this.loading.emit(true);
    if (this.subscription) {
      this.subscriptionChange.emit(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.loading.emit(false);
      }
      this.isPaid = true;
      cb(this.subscription);

      return;
    }

    this._createSubscriptionService.createSubscribe(
      PaymentMethod.CREDIT_CARD,
      this.promocodeId,
      (data) => {
        const subscriptionRes = data?.subscriptionRes;
        if (subscriptionRes) {
          this.subscription = data;
          this.subscriptionChange.emit(this.subscription);
        } else {
          if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
            this.loading.emit(false);
          }
          this.isPaid = true;
        }
        cb(data);
      }
    );
  }

  private _addInput(): void {
    const elements = this._stripe.elements();
    this._card = elements.create('card', { style: this._style });
  }

  private _payWithCard(clientSecret: string): void {
    this.loading.emit(true);
    this._stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this._card,
        },
      })
      // TODO: fix any
      // tslint:disable-next-line:no-any
      .then((result: any) => {
        const error = result.error;
        if (error) {
          this.loading.emit(false);

          // Show error to your customer
          this._notification.showBlankNotification(
            'Error: ' + error.message,
            TypeMessage.ERROR
          );

          return;
        }

        // The payment succeeded!
        this.next.emit();
        this.loading.emit(false);
      })
      .catch(() => {
        this._notification.showBlankNotification(
          'Something went wrong...',
          TypeMessage.ERROR
        );
        this.loading.emit(false);
      });
  }

  private _setNewCard(clientSecret: string): void {
    this._stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: this._card,
        },
      })
      // TODO: fix any
      // tslint:disable-next-line:no-any
      .then((result: any) => {
        const error = result.error;
        if (error) {
          this.loading.emit(false);

          // Show error to your customer
          this._notification.showBlankNotification(
            'Error: ' + error.message,
            TypeMessage.ERROR
          );

          return;
        }

        // The payment succeeded!
        this.next.emit();
      })
      .catch(() => {
        this._notification.showBlankNotification(
          'Something went wrong...',
          TypeMessage.ERROR
        );
        this.loading.emit(false);
      });
  }
}
