import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ISign } from '../interfaces/sign.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  triggerInterval$ = new Subject();

  constructor(private readonly _http: HttpClient) {}

  sign(): Observable<ISign> {
    return this._http.post<ISign>(
      `${environment.agreementsUrl}/api/Agreements/sign`,
      {}
    );
  }

  check(): Observable<void> {
    return this._http.post<void>(
      `${environment.agreementsUrl}/api/Agreements/check`,
      {}
    );
  }
}
