<div>
  <div
    class="platform {{
      platform.chosen ? 'platform-chosen' : 'platform-not-chosen'
    }}"
    *ngFor="let platform of platformList"
    (click)="platform.chosen = !platform.chosen"
  >
    <div class="checkbox"></div>
    <p class="text-in-center">{{ platform.name }}</p>
    <img [src]="platform.icon" alt="" />
  </div>
  <p class="text-in-center">See full list of available platforms</p>
</div>
