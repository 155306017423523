import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { ICandleItem } from 'repository';

@Component({
  selector: 'lib-level-system',
  templateUrl: './level-system.component.html',
  styleUrls: ['./level-system.component.scss'],
})
export class LevelSystemComponent implements OnChanges {
  @Input() current = 0;

  candleList1: ICandleItem[] = [
    {
      start: 0,
      end: 476,
      main: {
        top: '360px',
        height: '12px',
      },
      candle: {
        height: '25px',
        top: '351px',
      },
      infoBlock: '251px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 457,
      end: 962,
      main: {
        top: '340px',
        height: '12px',
      },
      candle: {
        top: '340px',
        height: '20px',
      },
      infoBlock: '240px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 962,
      end: 1429,
      main: {
        top: '336px',
        height: '8px',
      },
      candle: {
        top: '331px',
        height: '16px',
      },
      infoBlock: '231px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 1430,
      end: 1905,
      main: {
        top: '318px',
        height: '20px',
      },
      candle: {
        top: '318px',
        height: '24px',
      },
      infoBlock: '218px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 1906,
      end: 2381,
      main: {
        top: '306px',
        height: '12px',
      },
      candle: {
        top: '306px',
        height: '15px',
      },
      infoBlock: '206px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 2382,
      end: 2857,
      main: {
        top: '310px',
        height: '4px',
      },
      candle: {
        top: '310px',
        height: '7px',
      },
      infoBlock: '210px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 2858,
      end: 3333,
      main: {
        top: '320px',
        height: '15px',
      },
      candle: {
        top: '315px',
        height: '20px',
      },
      infoBlock: '215px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 3334,
      end: 3809,
      main: {
        top: '325px',
        height: '5px',
      },
      candle: {
        top: '316px',
        height: '14px',
      },
      infoBlock: '216px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 3810,
      end: 4285,
      main: {
        top: '313px',
        height: '13px',
      },
      candle: {
        top: '308px',
        height: '25px',
      },
      infoBlock: '208px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 4285,
      end: 4761,
      main: {
        top: '330px',
        height: '8px',
      },
      candle: {
        top: '325px',
        height: '13px',
      },
      infoBlock: '225px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 4762,
      end: 5237,
      main: {
        top: '331px',
        height: '12px',
      },
      candle: {
        top: '320px',
        height: '19px',
      },
      infoBlock: '220px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 5238,
      end: 5713,
      main: {
        top: '330px',
        height: '15px',
      },
      candle: {
        top: '330px',
        height: '21px',
      },
      infoBlock: '230px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 5714,
      end: 6189,
      main: {
        top: '320px',
        height: '20px',
      },
      candle: {
        top: '315px',
        height: '30px',
      },
      infoBlock: '210px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 6190,
      end: 6665,
      main: {
        top: '315px',
        height: '11px',
      },
      candle: {
        top: '309px',
        height: '12px',
      },
      infoBlock: '209px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 6666,
      end: 7141,
      main: {
        top: '318px',
        height: '6px',
      },
      candle: {
        top: '318px',
        height: '11px',
      },
      infoBlock: '218px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 7142,
      end: 7617,
      main: {
        top: '315px',
        height: '7px',
      },
      candle: {
        top: '308px',
        height: '14px',
      },
      infoBlock: '208px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 7618,
      end: 8093,
      main: {
        top: '299px',
        height: '10px',
      },
      candle: {
        top: '296px',
        height: '16px',
      },
      infoBlock: '196px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 8094,
      end: 8569,
      main: {
        top: '285px',
        height: '20px',
      },
      candle: {
        top: '275px',
        height: '25px',
      },
      infoBlock: '175px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 8570,
      end: 9045,
      main: {
        top: '292px',
        height: '19px',
      },
      candle: {
        top: '283px',
        height: '31px',
      },
      infoBlock: '183px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 9046,
      end: 9521,
      main: {
        top: '313px',
        height: '6px',
      },
      candle: {
        top: '299px',
        height: '26px',
      },
      infoBlock: '199px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 9522,
      end: 9999,
      main: {
        top: '326px',
        height: '9px',
      },
      candle: {
        top: '326px',
        height: '14px',
      },
      infoBlock: '226px',
      border: 'border-down',
      class: 'down',
    },
  ];
  candleList2: ICandleItem[] = [
    {
      start: 10000,
      end: 10348,
      main: {
        top: '320px',
        height: '16px',
      },
      candle: {
        top: '315px',
        height: '14px',
      },
      infoBlock: '215px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 10349,
      end: 10696,
      main: {
        top: '330px',
        height: '15px',
      },
      candle: {
        top: '326px',
        height: '25px',
      },
      infoBlock: '226px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 10697,
      end: 11044,
      main: {
        top: '335px',
        height: '15px',
      },
      candle: {
        top: '335px',
        height: '28px',
      },
      infoBlock: '235px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 11045,
      end: 11392,
      main: {
        top: '325px',
        height: '10px',
      },
      candle: {
        top: '315px',
        height: '25px',
      },
      infoBlock: '215px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 11393,
      end: 11740,
      main: {
        top: '310px',
        height: '5px',
      },
      candle: {
        top: '303px',
        height: '20px',
      },
      infoBlock: '203px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 11741,
      end: 12088,
      main: {
        top: '305px',
        height: '5px',
      },
      candle: {
        top: '299px',
        height: '25px',
      },
      infoBlock: '199px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 12089,
      end: 12436,
      main: {
        top: '300px',
        height: '7px',
      },
      candle: {
        top: '295px',
        height: '15px',
      },
      infoBlock: '195px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 12437,
      end: 12784,
      main: {
        top: '300px',
        height: '10px',
      },
      candle: {
        top: '290px',
        height: '15px',
      },
      infoBlock: '190px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 12785,
      end: 13132,
      main: {
        top: '305px',
        height: '20px',
      },
      candle: {
        top: '305px',
        height: '25px',
      },
      infoBlock: '205px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 13133,
      end: 13480,
      main: {
        top: '320px',
        height: '20px',
      },
      candle: {
        top: '310px',
        height: '30px',
      },
      infoBlock: '210px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 13481,
      end: 13828,
      main: {
        top: '330px',
        height: '10px',
      },
      candle: {
        top: '325px',
        height: '25px',
      },
      infoBlock: '225px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 13829,
      end: 14176,
      main: {
        top: '310px',
        height: '15px',
      },
      candle: {
        top: '310px',
        height: '20px',
      },
      infoBlock: '210px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 14177,
      end: 14524,
      main: {
        top: '300px',
        height: '15px',
      },
      candle: {
        top: '295px',
        height: '25px',
      },
      infoBlock: '295px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 14525,
      end: 14872,
      main: {
        top: '285px',
        height: '5px',
      },
      candle: {
        top: '285px',
        height: '15px',
      },
      infoBlock: '185px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 14873,
      end: 15220,
      main: {
        top: '275px',
        height: '15px',
      },
      candle: {
        top: '275px',
        height: '20px',
      },
      infoBlock: '135px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 15221,
      end: 15568,
      main: {
        top: '283px',
        height: '5px',
      },
      candle: {
        top: '278px',
        height: '10px',
      },
      infoBlock: '178px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 15569,
      end: 15916,
      main: {
        top: '290px',
        height: '10px',
      },
      candle: {
        top: '280px',
        height: '15px',
      },
      infoBlock: '180px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 15917,
      end: 16264,
      main: {
        top: '270px',
        height: '5px',
      },
      candle: {
        top: '270px',
        height: '10px',
      },
      infoBlock: '170px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 16265,
      end: 16612,
      main: {
        top: '265px',
        height: '5px',
      },
      candle: {
        top: '260px',
        height: '15px',
      },
      infoBlock: '160px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 16613,
      end: 16960,
      main: {
        top: '255px',
        height: '5px',
      },
      candle: {
        top: '247px',
        height: '15px',
      },
      infoBlock: '147px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 16961,
      end: 17308,
      main: {
        top: '235px',
        height: '15px',
      },
      candle: {
        top: '230px',
        height: '10px',
      },
      infoBlock: '130px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 17309,
      end: 17656,
      main: {
        top: '225px',
        height: '15px',
      },
      candle: {
        top: '220px',
        height: '20px',
      },
      infoBlock: '120px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 17657,
      end: 18004,
      main: {
        top: '220px',
        height: '10px',
      },
      candle: {
        top: '215px',
        height: '20px',
      },
      infoBlock: '115px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 18005,
      end: 18352,
      main: {
        top: '210px',
        height: '5px',
      },
      candle: {
        top: '205px',
        height: '15px',
      },
      infoBlock: '105px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 18353,
      end: 18700,
      main: {
        top: '220px',
        height: '12px',
      },
      candle: {
        top: '210px',
        height: '28px',
      },
      infoBlock: '110px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 18701,
      end: 19048,
      main: {
        top: '230px',
        height: '15px',
      },
      candle: {
        top: '225px',
        height: '30px',
      },
      infoBlock: '125px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 19049,
      end: 19396,
      main: {
        top: '215px',
        height: '18px',
      },
      candle: {
        top: '215px',
        height: '25px',
      },
      infoBlock: '115px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 19397,
      end: 19744,
      main: {
        top: '213px',
        height: '15px',
      },
      candle: {
        top: '207px',
        height: '30px',
      },
      infoBlock: '107px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 19745,
      end: 20092,
      main: {
        top: '225px',
        height: '5px',
      },
      candle: {
        top: '225px',
        height: '15px',
      },
      infoBlock: '125px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 20093,
      end: 20440,
      main: {
        top: '230px',
        height: '20px',
      },
      candle: {
        top: '230px',
        height: '30px',
      },
      infoBlock: '130px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 20441,
      end: 20788,
      main: {
        top: '220px',
        height: '5px',
      },
      candle: {
        top: '215px',
        height: '20px',
      },
      infoBlock: '115px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 20788,
      end: 21136,
      main: {
        top: '203px',
        height: '15px',
      },
      candle: {
        top: '190px',
        height: '35px',
      },
      infoBlock: '90px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 21137,
      end: 21484,
      main: {
        top: '180px',
        height: '30px',
      },
      candle: {
        top: '175px',
        height: '35px',
      },
      infoBlock: '75px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 21485,
      end: 21832,
      main: {
        top: '187px',
        height: '10px',
      },
      candle: {
        top: '175px',
        height: '25px',
      },
      infoBlock: '75px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 21833,
      end: 22180,
      main: {
        top: '195px',
        height: '15px',
      },
      candle: {
        top: '195px',
        height: '25px',
      },
      infoBlock: '95px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 22181,
      end: 22528,
      main: {
        top: '205px',
        height: '5px',
      },
      candle: {
        top: '200px',
        height: '15px',
      },
      infoBlock: '100px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 22529,
      end: 22876,
      main: {
        top: '195px',
        height: '25px',
      },
      candle: {
        top: '185px',
        height: '40px',
      },
      infoBlock: '85px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 22877,
      end: 23224,
      main: {
        top: '200px',
        height: '10px',
      },
      candle: {
        top: '200px',
        height: '20px',
      },
      infoBlock: '100px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 23225,
      end: 23572,
      main: {
        top: '215px',
        height: '5px',
      },
      candle: {
        top: '210px',
        height: '15px',
      },
      infoBlock: '110px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 23573,
      end: 23920,
      main: {
        top: '230px',
        height: '5px',
      },
      candle: {
        top: '215px',
        height: '30px',
      },
      infoBlock: '115px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 23921,
      end: 24268,
      main: {
        top: '245px',
        height: '5px',
      },
      candle: {
        top: '235px',
        height: '20px',
      },
      infoBlock: '135px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 24269,
      end: 24616,
      main: {
        top: '255px',
        height: '15px',
      },
      candle: {
        top: '255px',
        height: '20px',
      },
      infoBlock: '155px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 24616,
      end: 24999,
      main: {
        top: '275px',
        height: '5px',
      },
      candle: {
        top: '275px',
        height: '10px',
      },
      infoBlock: '145px',
      border: 'border-down',
      class: 'down',
    },
  ];
  candleList3: ICandleItem[] = [
    {
      start: 25000,
      end: 25581,
      main: {
        top: '285px',
        height: '20px',
      },
      candle: {
        top: '275px',
        height: '30px',
      },
      infoBlock: '175px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 25582,
      end: 26162,
      main: {
        top: '270px',
        height: '5px',
      },
      candle: {
        top: '260px',
        height: '15px',
      },
      infoBlock: '160px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 26163,
      end: 26743,
      main: {
        top: '280px',
        height: '15px',
      },
      candle: {
        top: '270px',
        height: '30px',
      },
      infoBlock: '170px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 26744,
      end: 27324,
      main: {
        top: '305px',
        height: '10px',
      },
      candle: {
        top: '295px',
        height: '20px',
      },
      infoBlock: '195px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 27325,
      end: 27905,
      main: {
        top: '320px',
        height: '15px',
      },
      candle: {
        top: '310px',
        height: '25px',
      },
      infoBlock: '210px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 27906,
      end: 28486,
      main: {
        top: '300px',
        height: '20px',
      },
      candle: {
        top: '300px',
        height: '30px',
      },
      infoBlock: '200px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 28487,
      end: 29067,
      main: {
        top: '290px',
        height: '25px',
      },
      candle: {
        top: '280px',
        height: '40px',
      },
      infoBlock: '180px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 29068,
      end: 29648,
      main: {
        top: '300px',
        height: '15px',
      },
      candle: {
        top: '295px',
        height: '20px',
      },
      infoBlock: '195px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 29649,
      end: 30229,
      main: {
        top: '310px',
        height: '10px',
      },
      candle: {
        top: '310px',
        height: '20px',
      },
      infoBlock: '210px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 30230,
      end: 30810,
      main: {
        top: '320px',
        height: '5px',
      },
      candle: {
        top: '305px',
        height: '20px',
      },
      infoBlock: '205px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 30811,
      end: 31391,
      main: {
        top: '335px',
        height: '8px',
      },
      candle: {
        top: '325px',
        height: '20px',
      },
      infoBlock: '225px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 31392,
      end: 31972,
      main: {
        top: '310px',
        height: '30px',
      },
      candle: {
        top: '300px',
        height: '40px',
      },
      infoBlock: '200px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 31973,
      end: 32553,
      main: {
        top: '316px',
        height: '14px',
      },
      candle: {
        top: '310px',
        height: '25px',
      },
      infoBlock: '210px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 32554,
      end: 33134,
      main: {
        top: '305px',
        height: '5px',
      },
      candle: {
        top: '297px',
        height: '15px',
      },
      infoBlock: '197px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 33135,
      end: 33715,
      main: {
        top: '293px',
        height: '5px',
      },
      candle: {
        top: '286px',
        height: '15px',
      },
      infoBlock: '186px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 33716,
      end: 34296,
      main: {
        top: '270px',
        height: '10px',
      },
      candle: {
        top: '270px',
        height: '16px',
      },
      infoBlock: '170px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 34297,
      end: 34877,
      main: {
        top: '255px',
        height: '10px',
      },
      candle: {
        top: '250px',
        height: '20px',
      },
      infoBlock: '150px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 34878,
      end: 35458,
      main: {
        top: '245px',
        height: '15px',
      },
      candle: {
        top: '240px',
        height: '20px',
      },
      infoBlock: '140px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 35459,
      end: 36039,
      main: {
        top: '235px',
        height: '20px',
      },
      candle: {
        top: '230px',
        height: '30px',
      },
      infoBlock: '130px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 36040,
      end: 36620,
      main: {
        top: '240px',
        height: '10px',
      },
      candle: {
        top: '240px',
        height: '25px',
      },
      infoBlock: '140px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 36621,
      end: 37201,
      main: {
        top: '250px',
        height: '15px',
      },
      candle: {
        top: '240px',
        height: '30px',
      },
      infoBlock: '140px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 37202,
      end: 37782,
      main: {
        top: '245px',
        height: '10px',
      },
      candle: {
        top: '235px',
        height: '25px',
      },
      infoBlock: '135px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 37783,
      end: 38363,
      main: {
        top: '255px',
        height: '5px',
      },
      candle: {
        top: '240px',
        height: '25px',
      },
      infoBlock: '140px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 38364,
      end: 38944,
      main: {
        top: '265px',
        height: '8px',
      },
      candle: {
        top: '260px',
        height: '22px',
      },
      infoBlock: '160px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 38945,
      end: 39525,
      main: {
        top: '285px',
        height: '10px',
      },
      candle: {
        top: '275px',
        height: '25px',
      },
      infoBlock: '175px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 39526,
      end: 40106,
      main: {
        top: '290px',
        height: '20px',
      },
      candle: {
        top: '290px',
        height: '30px',
      },
      infoBlock: '190px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 40107,
      end: 40687,
      main: {
        top: '285px',
        height: '22px',
      },
      candle: {
        top: '285px',
        height: '30px',
      },
      infoBlock: '185px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 40688,
      end: 41268,
      main: {
        top: '315px',
        height: '15px',
      },
      candle: {
        top: '310px',
        height: '30px',
      },
      infoBlock: '210px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 41269,
      end: 41849,
      main: {
        top: '305px',
        height: '20px',
      },
      candle: {
        top: '305px',
        height: '30px',
      },
      infoBlock: '205px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 41850,
      end: 42430,
      main: {
        top: '310px',
        height: '20px',
      },
      candle: {
        top: '300px',
        height: '40px',
      },
      infoBlock: '200px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 42430,
      end: 43011,
      main: {
        top: '330px',
        height: '5px',
      },
      candle: {
        top: '330px',
        height: '20px',
      },
      infoBlock: '230px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 43012,
      end: 43592,
      main: {
        top: '320px',
        height: '25px',
      },
      candle: {
        top: '320px',
        height: '30px',
      },
      infoBlock: '220px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 43593,
      end: 44173,
      main: {
        top: '335px',
        height: '5px',
      },
      candle: {
        top: '325px',
        height: '10px',
      },
      infoBlock: '225px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 44174,
      end: 44754,
      main: {
        top: '330px',
        height: '20px',
      },
      candle: {
        top: '325px',
        height: '25px',
      },
      infoBlock: '225px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 44755,
      end: 45335,
      main: {
        top: '345px',
        height: '5px',
      },
      candle: {
        top: '345px',
        height: '15px',
      },
      infoBlock: '245px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 45336,
      end: 45916,
      main: {
        top: '340px',
        height: '5px',
      },
      candle: {
        top: '330px',
        height: '25px',
      },
      infoBlock: '230px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 45917,
      end: 46497,
      main: {
        top: '330px',
        height: '10px',
      },
      candle: {
        top: '320px',
        height: '25px',
      },
      infoBlock: '220px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 46498,
      end: 47078,
      main: {
        top: '310px',
        height: '20px',
      },
      candle: {
        top: '295px',
        height: '20px',
      },
      infoBlock: '195px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 47079,
      end: 47659,
      main: {
        top: '305px',
        height: '20px',
      },
      candle: {
        top: '290px',
        height: '40px',
      },
      infoBlock: '190px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 47660,
      end: 48240,
      main: {
        top: '285px',
        height: '5px',
      },
      candle: {
        top: '275px',
        height: '20px',
      },
      infoBlock: '175px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 48241,
      end: 48821,
      main: {
        top: '260px',
        height: '15px',
      },
      candle: {
        top: '250px',
        height: '30px',
      },
      infoBlock: '150px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 48242,
      end: 49402,
      main: {
        top: '230px',
        height: '35px',
      },
      candle: {
        top: '220px',
        height: '50px',
      },
      infoBlock: '120px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 49403,
      end: 49999,
      main: {
        top: '235px',
        height: '10px',
      },
      candle: {
        top: '235px',
        height: '20px',
      },
      infoBlock: '100px',
      border: 'border-down',
      class: 'down',
    },
  ];
  candleList4: ICandleItem[] = [
    {
      start: 50000,
      end: 51162,
      main: {
        top: '245px',
        height: '15px',
      },
      candle: {
        top: '240px',
        height: '30px',
      },
      infoBlock: '140px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 51163,
      end: 52324,
      main: {
        top: '245px',
        height: '25px',
      },
      candle: {
        top: '245px',
        height: '30px',
      },
      infoBlock: '145px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 52325,
      end: 53486,
      main: {
        top: '210px',
        height: '20px',
      },
      candle: {
        top: '210px',
        height: '25px',
      },
      infoBlock: '110px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 53487,
      end: 54648,
      main: {
        top: '195px',
        height: '5px',
      },
      candle: {
        top: '190px',
        height: '25px',
      },
      infoBlock: '90px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 54649,
      end: 55810,
      main: {
        top: '190px',
        height: '20px',
      },
      candle: {
        top: '175px',
        height: '40px',
      },
      infoBlock: '75px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 55811,
      end: 56972,
      main: {
        top: '160px',
        height: '30px',
      },
      candle: {
        top: '150px',
        height: '40px',
      },
      infoBlock: '50px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 56973,
      end: 58134,
      main: {
        top: '150px',
        height: '20px',
      },
      candle: {
        top: '140px',
        height: '35px',
      },
      infoBlock: '40px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 58135,
      end: 59296,
      main: {
        top: '190px',
        height: '20px',
      },
      candle: {
        top: '190px',
        height: '35px',
      },
      infoBlock: '40px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 59297,
      end: 60458,
      main: {
        top: '215px',
        height: '5px',
      },
      candle: {
        top: '210px',
        height: '15px',
      },
      infoBlock: '110px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 60459,
      end: 61620,
      main: {
        top: '190px',
        height: '30px',
      },
      candle: {
        top: '185px',
        height: '45px',
      },
      infoBlock: '85px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 61621,
      end: 62782,
      main: {
        top: '160px',
        height: '15px',
      },
      candle: {
        top: '160px',
        height: '25px',
      },
      infoBlock: '60px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 62783,
      end: 63944,
      main: {
        top: '155px',
        height: '5px',
      },
      candle: {
        top: '150px',
        height: '15px',
      },
      infoBlock: '50px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 63945,
      end: 65106,
      main: {
        top: '150px',
        height: '5px',
      },
      candle: {
        top: '140px',
        height: '25px',
      },
      infoBlock: '40px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 65107,
      end: 66268,
      main: {
        top: '164px',
        height: '5px',
      },
      candle: {
        top: '155px',
        height: '20px',
      },
      infoBlock: '55px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 66269,
      end: 67430,
      main: {
        top: '145px',
        height: '15px',
      },
      candle: {
        top: '145px',
        height: '20px',
      },
      infoBlock: '45px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 67431,
      end: 68592,
      main: {
        top: '140px',
        height: '5px',
      },
      candle: {
        top: '140px',
        height: '15px',
      },
      infoBlock: '40px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 68593,
      end: 69754,
      main: {
        top: '160px',
        height: '20px',
      },
      candle: {
        top: '150px',
        height: '30px',
      },
      infoBlock: '50px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 68593,
      end: 69754,
      main: {
        top: '160px',
        height: '8px',
      },
      candle: {
        top: '145px',
        height: '22px',
      },
      infoBlock: '45px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 69755,
      end: 70916,
      main: {
        top: '140px',
        height: '25px',
      },
      candle: {
        top: '135px',
        height: '35px',
      },
      infoBlock: '35px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 70917,
      end: 72078,
      main: {
        top: '130px',
        height: '8px',
      },
      candle: {
        top: '120px',
        height: '17px',
      },
      infoBlock: '20px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 72079,
      end: 73240,
      main: {
        top: '120px',
        height: '15px',
      },
      candle: {
        top: '100px',
        height: '35px',
      },
      infoBlock: '0px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 73241,
      end: 74402,
      main: {
        top: '100px',
        height: '20px',
      },
      candle: {
        top: '90px',
        height: '30px',
      },
      infoBlock: '-10px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 74403,
      end: 75564,
      main: {
        top: '120px',
        height: '20px',
      },
      candle: {
        top: '120px',
        height: '25px',
      },
      infoBlock: '20px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 75565,
      end: 76726,
      main: {
        top: '110px',
        height: '15px',
      },
      candle: {
        top: '100px',
        height: '30px',
      },
      infoBlock: '0px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 76727,
      end: 77888,
      main: {
        top: '100px',
        height: '15px',
      },
      candle: {
        top: '95px',
        height: '20px',
      },
      infoBlock: '-5px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 77889,
      end: 79050,
      main: {
        top: '90px',
        height: '5px',
      },
      candle: {
        top: '90px',
        height: '15px',
      },
      infoBlock: '-10px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 79051,
      end: 80212,
      main: {
        top: '95px',
        height: '5px',
      },
      candle: {
        top: '80px',
        height: '20px',
      },
      infoBlock: '-20px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 80213,
      end: 81374,
      main: {
        top: '70px',
        height: '20px',
      },
      candle: {
        top: '60px',
        height: '30px',
      },
      infoBlock: '-40px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 81375,
      end: 82536,
      main: {
        top: '90px',
        height: '10px',
      },
      candle: {
        top: '80px',
        height: '25px',
      },
      infoBlock: '-20px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 82537,
      end: 83698,
      main: {
        top: '107px',
        height: '5px',
      },
      candle: {
        top: '100px',
        height: '15px',
      },
      infoBlock: '0px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 83699,
      end: 84860,
      main: {
        top: '116px',
        height: '5px',
      },
      candle: {
        top: '110px',
        height: '15px',
      },
      infoBlock: '10px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 84861,
      end: 86022,
      main: {
        top: '120px',
        height: '10px',
      },
      candle: {
        top: '120px',
        height: '20px',
      },
      infoBlock: '20px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 86023,
      end: 87184,
      main: {
        top: '135px',
        height: '10px',
      },
      candle: {
        top: '130px',
        height: '25px',
      },
      infoBlock: '30px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 87185,
      end: 88346,
      main: {
        top: '130px',
        height: '15px',
      },
      candle: {
        top: '125px',
        height: '20px',
      },
      infoBlock: '25px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 88347,
      end: 89508,
      main: {
        top: '145px',
        height: '15px',
      },
      candle: {
        top: '140px',
        height: '30px',
      },
      infoBlock: '40px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 89509,
      end: 90670,
      main: {
        top: '165px',
        height: '10px',
      },
      candle: {
        top: '165px',
        height: '25px',
      },
      infoBlock: '65px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 90671,
      end: 91832,
      main: {
        top: '190px',
        height: '15px',
      },
      candle: {
        top: '180px',
        height: '30px',
      },
      infoBlock: '80px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 91833,
      end: 92994,
      main: {
        top: '174px',
        height: '10px',
      },
      candle: {
        top: '170px',
        height: '20px',
      },
      infoBlock: '70px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 92995,
      end: 94156,
      main: {
        top: '154px',
        height: '5px',
      },
      candle: {
        top: '150px',
        height: '30px',
      },
      infoBlock: '50px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 94157,
      end: 95318,
      main: {
        top: '175px',
        height: '8px',
      },
      candle: {
        top: '170px',
        height: '20px',
      },
      infoBlock: '70px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 95319,
      end: 96480,
      main: {
        top: '185px',
        height: '15px',
      },
      candle: {
        top: '175px',
        height: '30px',
      },
      infoBlock: '75px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 96481,
      end: 97642,
      main: {
        top: '160px',
        height: '25px',
      },
      candle: {
        top: '160px',
        height: '30px',
      },
      infoBlock: '60px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 97643,
      end: 99999,
      main: {
        top: '155px',
        height: '25px',
      },
      candle: {
        top: '150px',
        height: '30px',
      },
      infoBlock: '60px',
      border: 'border-up',
      class: 'up',
    },
  ];
  candleList5: ICandleItem[] = [
    {
      start: 100000,
      end: 100454,
      main: {
        top: '145px',
        height: '15px',
      },
      candle: {
        top: '140px',
        height: '30px',
      },
      infoBlock: '40px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 100455,
      end: 100908,
      main: {
        top: '135px',
        height: '20px',
      },
      candle: {
        top: '135px',
        height: '25px',
      },
      infoBlock: '35px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 100909,
      end: 101362,
      main: {
        top: '130px',
        height: '5px',
      },
      candle: {
        top: '120px',
        height: '15px',
      },
      infoBlock: '20px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 101361,
      end: 101816,
      main: {
        top: '115px',
        height: '20px',
      },
      candle: {
        top: '110px',
        height: '30px',
      },
      infoBlock: '10px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 101817,
      end: 102270,
      main: {
        top: '130px',
        height: '20px',
      },
      candle: {
        top: '130px',
        height: '30px',
      },
      infoBlock: '30px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 102270,
      end: 102724,
      main: {
        top: '130px',
        height: '5px',
      },
      candle: {
        top: '125px',
        height: '10px',
      },
      infoBlock: '25px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 102725,
      end: 103178,
      main: {
        top: '140px',
        height: '15px',
      },
      candle: {
        top: '130px',
        height: '20px',
      },
      infoBlock: '30px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 103179,
      end: 103632,
      main: {
        top: '135px',
        height: '8px',
      },
      candle: {
        top: '135px',
        height: '18px',
      },
      infoBlock: '35px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 103633,
      end: 104086,
      main: {
        top: '148px',
        height: '5px',
      },
      candle: {
        top: '135px',
        height: '30px',
      },
      infoBlock: '55px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 104087,
      end: 104540,
      main: {
        top: '140px',
        height: '5px',
      },
      candle: {
        top: '130px',
        height: '20px',
      },
      infoBlock: '30px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 104541,
      end: 104994,
      main: {
        top: '125px',
        height: '20px',
      },
      candle: {
        top: '115px',
        height: '30px',
      },
      infoBlock: '20px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 104995,
      end: 105448,
      main: {
        top: '115px',
        height: '20px',
      },
      candle: {
        top: '100px',
        height: '40px',
      },
      infoBlock: '0px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 105449,
      end: 105902,
      main: {
        top: '100px',
        height: '5px',
      },
      candle: {
        top: '90px',
        height: '20px',
      },
      infoBlock: '-10px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 105903,
      end: 106356,
      main: {
        top: '90px',
        height: '10px',
      },
      candle: {
        top: '80px',
        height: '30px',
      },
      infoBlock: '-20px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 106357,
      end: 106810,
      main: {
        top: '60px',
        height: '20px',
      },
      candle: {
        top: '50px',
        height: '40px',
      },
      infoBlock: '-50px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 106811,
      end: 107264,
      main: {
        top: '40px',
        height: '20px',
      },
      candle: {
        top: '40px',
        height: '30px',
      },
      infoBlock: '-60px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 107265,
      end: 107718,
      main: {
        top: '40px',
        height: '10px',
      },
      candle: {
        top: '30px',
        height: '30px',
      },
      infoBlock: '-70px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 107719,
      end: 108172,
      main: {
        top: '60px',
        height: '8px',
      },
      candle: {
        top: '60px',
        height: '20px',
      },
      infoBlock: '-40px',
      border: 'border-down',
      class: 'down',
    },
    {
      start: 108171,
      end: 108626,
      main: {
        top: '40px',
        height: '20px',
      },
      candle: {
        top: '40px',
        height: '30px',
      },
      infoBlock: '-60px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 108627,
      end: 109080,
      main: {
        top: '25px',
        height: '10px',
      },
      candle: {
        top: '20px',
        height: '30px',
      },
      infoBlock: '-80px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 109081,
      end: 109534,
      main: {
        top: '15px',
        height: '20px',
      },
      candle: {
        top: '0px',
        height: '40px',
      },
      infoBlock: '-100px',
      border: 'border-up',
      class: 'up',
    },
    {
      start: 109535,
      end: 9999999999999,
      main: {
        top: '0px',
        height: '20px',
      },
      candle: {
        top: '-10px',
        height: '30px',
      },
      infoBlock: '-90px',
      border: 'border-up',
      class: 'up',
    },
  ];

  lightning2 = '/assets/rewards/tier/lightning-2.svg';
  lightning3 = '/assets/rewards/tier/lightning-3.svg';
  lightning4 = '/assets/rewards/tier/lightning-4.svg';
  lightning5 = '/assets/rewards/tier/lightning-5.svg';

  tier = 0;
  candleList: {
    type: number;
    list?: ICandleItem[];
    top?: string;
    tier?: number;
    currentLightning?: string;
  }[] = [
    { type: 1, list: this.candleList1 },
    { type: 2, top: '358px', tier: 1, currentLightning: this.lightning2 },
    { type: 1, list: this.candleList2 },
    { type: 2, top: '320px', tier: 2, currentLightning: this.lightning3 },
    { type: 1, list: this.candleList3 },
    { type: 2, top: '275px', tier: 3, currentLightning: this.lightning4 },
    { type: 1, list: this.candleList4 },
    { type: 2, top: '212px', tier: 4, currentLightning: this.lightning5 },
    { type: 1, list: this.candleList5 },
  ];
  @ViewChild('chartBlock') public chartBlock: ElementRef;

  ngOnChanges(): void {
    if (this.current > 0 && this.current <= 9999) {
      this.tier = 1;
    } else if (this.current >= 10000 && this.current <= 24999) {
      this.tier = 2;
    } else if (this.current >= 25000 && this.current <= 49999) {
      this.tier = 3;
    } else if (this.current >= 50000 && this.current <= 99999) {
      this.tier = 4;
    } else if (this.current >= 100000) {
      this.tier = 5;
    }

    const windowWidth = this.chartBlock.nativeElement.clientWidth;
    this.chartBlock.nativeElement.scrollTo({
      left:
        this.chartBlock.nativeElement.scrollLeft +
        (this.current / windowWidth) * this.tier,
      behavior: 'smooth',
    });
  }
}
