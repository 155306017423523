<div class="tooltip-block-info">
  <div class="flex-block">
    <div class="lightning">
      <img [src]="currentLightning" alt="lightning" width="30" height="30" />
    </div>
    <div class="tier-title">{{ 'tier' | translate }} {{ tier }}</div>
  </div>
  <div class="flex-block">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      {{ 'required-text-points' | translate }}
      <span class="bold-text"> {{ tierList[tier - 1].points }}</span>
      {{ 'tier-text-points' | translate }}
    </div>
  </div>

  <div class="flex-block" *ngIf="tierList[tier - 1].reset">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      {{ 'tier-text-free' | translate }}
      <span class="bold-text">{{ 'tier-text-reset' | translate }}</span>
    </div>
  </div>

  <div class="flex-block" *ngIf="tierList[tier - 1].freeReset">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      {{ 'tier-text-free' | translate }}
      <span class="bold-text">
        {{ tierList[tier - 1].freeReset }}
        {{ 'tier-text-account' | translate }}
      </span>
    </div>
  </div>

  <div class="flex-block" *ngIf="tierList[tier - 1].proActivation">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      <span class="bold-text">
        {{ tierList[tier - 1].proActivation }}
        {{ 'tier-text-pro' | translate }}
      </span>
      {{ 'tier-text-fee' | translate }}
    </div>
  </div>

  <div class="flex-block" *ngIf="tierList[tier - 1].freePro">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      {{ 'tier-text-free' | translate }}
      <span class="bold-text">
        {{ 'tier-text-pro-account' | translate }}
      </span>
    </div>
  </div>

  <div class="flex-block" *ngIf="tierList[tier - 1].macbook">
    <div><img [src]="tierIcon" alt="icon" width="14" height="14" /></div>
    <div>
      <span class="bold-text">
        {{ 'tier-text-macbook-pro' | translate }}
      </span>
    </div>
  </div>
</div>
