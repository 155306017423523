import { Inject } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ROOT } from './root';

export abstract class FileLoader {
  constructor(
    @Inject(TransferState) protected transferState: TransferState,
    @Inject(ROOT) protected root: string
  ) {}

  abstract loadFile(path: string): Observable<object>;

  getPath(...args: string[]) {
    return `${this.root}${args.join('/')}`;
  }
}
