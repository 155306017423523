import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BaseComponentsComponent } from './base-components.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';
import { TakeprofitTitleComponent } from './takeprofit-title/takeprofit-title.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { HeaderComponent } from './header/header.component';
import { ModalModule } from '@app/_modal';
import { CommonPopUpModule } from 'common-pop-up';
import { ModalWindowModule } from 'modal-window';
import { Translate } from 'localization';
import { ChangeLangModule } from 'change-lang';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoaderModule } from 'loader';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    BaseComponentsComponent,
    HeaderComponent,
    TakeprofitTitleComponent,
    FooterComponent,
    LogoComponent,
    ContactUsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzPaginationModule,
    NzTabsModule,
    NzFormModule,
    NzDropDownModule,
    NzAvatarModule,
    NzMessageModule,
    NzNotificationModule,
    FormsModule,
    ReactiveFormsModule,
    CommonPopUpModule,
    ChangeLangModule,
    NotifierModule.withConfig(customNotifierOptions),
    ModalModule,
    ModalWindowModule,
    MatDialogModule,
    Translate,
    RecaptchaModule,
    LoaderModule
  ],
  exports: [
    BaseComponentsComponent,
    HeaderComponent,
    TakeprofitTitleComponent,
    FooterComponent,
    LogoComponent,
    ContactUsComponent,
  ],
})
export class BaseComponentsModule {}
