<main>
  <lib-takeprofit-title [desktopOnly]="true" id="logo"></lib-takeprofit-title>

  <lib-login-reset-password
    [showCloseBtn]="false"
    [show]="showType === ResetComponent.RESET_FORM"
    (nativeReset)="showSuccessfulUpdate($event)"
  ></lib-login-reset-password>

  <lib-login-password-successful-update
    [show]="showType !== ResetComponent.RESET_FORM"
    [type]="showType"
  ></lib-login-password-successful-update>
</main>
