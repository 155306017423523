import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { IAmountPayBody } from '../../interfaces/amount-pay-body.interface';
import { IPaymentData } from '../../interfaces/payment-data.interface';
import { IPayment } from '../../interfaces/payment.interface';
import { ISubscriptionPayBody } from '../../interfaces/subscription-pay-body.interface';
import { ISubscriptionExpressPayBody } from 'create-subscription';

@Injectable({
  providedIn: 'root',
})
export class WalletService implements IPayment {
  constructor(private readonly _http: HttpClient) {}

  createSubscriptions(body: ISubscriptionPayBody): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Subscriptions/wallet`,
      body
    );
  }

  createSubscriptionsExpress(
    body: ISubscriptionExpressPayBody
  ): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Payments/wallet/express`,
      body
    );
  }

  resetAccount(body: IAmountPayBody): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Payments/wallet`,
      body
    );
  }

  payAgreement(body: IAmountPayBody): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Payments/wallet/agreement`,
      body
    );
  }

  depositWallet(body: IAmountPayBody): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Wallets/deposit/stripe`,
      body
    );
  }
}
