<form
  [formGroup]="form"
  class="login-form primary-form"
  (ngSubmit)="handleSubmit()"
>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.username">
      <input
        nz-input
        type="email"
        name="username"
        id="username"
        class="primary-input"
        formControlName="username"
        placeholder="Email"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.password">
      <div class="password-box">
        <input
          nz-input
          [type]="handlePasswordType(showPassword)"
          name="password"
          id="password"
          class="primary-input"
          formControlName="password"
          placeholder="Password"
        />
        <div class="indicators">
          <img
            class="show-switch"
            (click)="showPassword = !showPassword"
            [src]="passwordIconUrl(showPassword)"
          />
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>

  <div class="primary-flex-end">
    <button
      type="button"
      id="forgot-password"
      class="primary-invisible"
      (click)="forgotPassword()"
    >
      {{ 'forgot' | translate }}
    </button>
  </div>

  <button
    type="submit"
    class="primary-block-by-center button-nav primary-btn login-btn"
  >
    {{ 'login_up' | translate }}
  </button>
</form>
