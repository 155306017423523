import { NgModule } from '@angular/core';
import { DialogTitleComponent } from './dialog-title.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseComponentsModule } from 'base-components';
import { Translate } from 'localization';

@NgModule({
  declarations: [DialogTitleComponent],
  imports: [
    MatCardModule,
    CommonModule,
    MatDialogModule,
    BaseComponentsModule,
    Translate,
  ],
  exports: [DialogTitleComponent],
})
export class DialogTitleModule {}
