<header id='header' class='space-between'>
  <ng-container *ngIf='!isControlCenter'>
    <a id='title' class='element' routerLink='/control-center'>
      <lib-takeprofit-title [desktopOnly]='true'></lib-takeprofit-title>
    </a>
    <div class='back-link'>
      <a routerLink='/control-center'>
      <img
        id='back-icon'
        class='back-arrow'
        src='/assets/user-info/back.png'
        alt='go back'
      />
      </a>
      <a routerLink='/control-center'>{{
        'back-to-control-center' | translate
        }}</a>
    </div>
  </ng-container>
  <ng-container *ngIf='isControlCenter'>
    <a id='title2' class='element' routerLink='/control-center'>
      <lib-takeprofit-title [desktopOnly]='true'></lib-takeprofit-title>
    </a>
  </ng-container>

  <div id='user-info'>
    <a
      id='free-guide'
      target='_blank'
      href='https://takeprofittrader.myclickfunnels.com/playbook--36698'
      class='element knowledge-base'
    >{{ 'free-playbook' | translate }}</a
    >
    <a
      id='knowledgeBase'
      target='_blank'
      href='https://takeprofittraderhelp.zendesk.com/hc/en-us'
      class='element knowledge-base'
    >{{ 'knowledge-base' | translate }}</a
    >
    <div style='position: relative'>
      <a
        id='become-an-affiliate'
        href='#'
        class='element knowledge-base'
        (click)='addNewAffiliateCode($event)'
      >{{ 'become-an-affiliate' | translate }}
      </a
      >
      <ng-container *ngIf='affiliateNotification$|async as affiliateNotification'>
        <div class='notification-block' *ngIf='affiliateNotification?.length>0'></div>
      </ng-container>
    </div>

    <div id='user' class='element user-menu-area' nzPlacement='bottomRight'>
      <a
        nz-dropdown
        nzTrigger='click'
        [nzDropdownMenu]='menu'
        class='block-menu-link'
        (nzVisibleChange)='rotateIcon($event)'
      >
        <span class='user-login-name'>{{ username }}</span>
        <img
          id='user-icon'
          class='element'
          [src]='user.photo'
          (error)='loadImageError()'
          alt='user icon'
          srcset=''
        />
        <lib-notification
          style='position: absolute; right: 22px;top:0'
          [notification]='1'
          [small]='true'
        >
        </lib-notification>

        <img
          [ngClass]='{ rotate: isShowMenu }'
          id='button-nav'
          class='element arrow-down'
          src='/assets/header/button-down.png'
          alt='button down'
          srcset=''
        />
      </a>

      <nz-dropdown-menu #menu='nzDropdownMenu'>
        <div class='primary-dropdown-relative'>
          <lib-header-user-info-menu class='fixed'></lib-header-user-info-menu>
        </div>
      </nz-dropdown-menu>
    </div>
    <lib-change-lang></lib-change-lang>
  </div>
  <button
    class='open-menu-btn primary-btn '
    (click)="modalService.open('mobile-menu-modal')"
  >
    <i class='fas fa-bars'></i>
  </button>
  <jw-modal id='mobile-menu-modal'>
    <div class='mobile-menu-container'>
      <div class='close-wrapper'>
        <button
          type='button'
          class='close-menu-btn primary-btn'
          (click)="modalService.close('mobile-menu-modal')"
        >
          <i class='fas fa-times'></i>
        </button>
      </div>
      <div class='mobile'>
        <div class='user-avatar'>
          <img
            id='user-icon-mobile'
            class='element'
            [src]='user.photo'
            alt='user icon'
            srcset=''
          />
          <div class='name'>
            <p id='firstName'>{{ user.tradingFirstName }}</p>
            <p id='lastName'>{{ user.tradingLastName }}</p>
          </div>
        </div>
        <hr class='line' />
        <div class='user-links'>
          <a
            *ngFor='let item of MenuLinks'
            (click)="modalService.close('mobile-menu-modal')"
            [routerLink]='item?.url'
            class='mobile-login'
          >{{ item?.title | translate }}
            <lib-notification
              [notification]='item?.notification'
              [small]='true'
            >
            </lib-notification
            >
          </a>
        </div>
        <hr class='line' />
        <div class='user-knowledge'>
          <a
            target='_blank'
            routerLink='/playbook'
            class='element guide-mobile'
          >{{ 'free-playbook' | translate }}</a
          >
          <a
            id='knowledgeBase-mobile'
            target='_blank'
            href='https://takeprofittraderhelp.zendesk.com/hc/en-us'
            class='element knowledge-base'
          >{{ 'knowledge-base' | translate }}</a
          >
          <div style='position: relative'>

            <a
              id='become-an-affiliate-mobile'
              href='#'
              class='element guide-mobile'
              (click)='addNewAffiliateCode($event)'
            >{{ 'become-an-affiliate' | translate }}</a
            >
            <ng-container *ngIf='affiliateNotification$|async as affiliateNotification'>
              <div class='notification-block' style='right: 4rem' *ngIf='affiliateNotification.length>0'></div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class='mobile-btns-wrapper user-sign-out'>
        <hr class='line' />
        <a (click)='signOut()' class='mobile-login'>{{
          'sign-out' | translate
          }}</a>
      </div>

      <lib-change-lang [menu]='true'></lib-change-lang>
    </div>
  </jw-modal>
</header>
