import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateSubscriptionService } from '../create-subscription.service';
import { ErrorCode, Payment, TypeOfCreateSubscription } from '../enum';
import { IPaymentData } from '../interfaces';

import {
  ErrorFormService,
  ErrorsMap,
  IErrorMessage,
  NotificationService,
  PaymentMethod,
  TypeMessage,
} from 'repository';

@Component({
  selector: 'lib-payment-credit-card-nmi',
  templateUrl: './payment-credit-card-nmi.component.html',
  styleUrls: ['./payment-credit-card-nmi.component.scss'],
})
export class PaymentCreditCardNmiComponent {
  @Input() update: boolean;
  @Input() subscriptionsId: number;
  @Input() type: TypeOfCreateSubscription;
  @Input() promocodeId = 0;
  @Input() price = 0;

  @Input() set isPaid(value: boolean) {
    if (value === this._isPaid) {
      return;
    }

    this._isPaid = value;
    this.isPaidChange.emit(this._isPaid);
  }

  get isPaid() {
    return this._isPaid;
  }

  @Output() isPaidChange = new EventEmitter<boolean>();

  @Input() subscription: IPaymentData;
  @Output() subscriptionChange = new EventEmitter<IPaymentData>();

  @Output() next = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    CcNumber: {
      required: 'Card number is required',
      pattern: 'The card number consists only of numbers',
      minlength: 'Minimum card length 16 characters',
      maxlength: 'Maximum card length 16 characters',
    },
    CcExpMonth: {
      required: 'Expiration date is required',
      pattern: 'Expiration date consists only of numbers',
      minlength: 'Minimum expiration month length 2 characters',
      maxlength: 'Maximum expiration month length 2 characters',
    },

    CcExpYear: {
      required: 'Expiration date is required',
      pattern: 'Expiration date consists only of numbers',
      minlength: 'Minimum expiration year length 2 characters',
      maxlength: 'Maximum expiration year length 2 characters',
    },
  };
  public errors: ErrorsMap<string> = {};

  public form: FormGroup = new FormGroup({
    CcNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(16),
      Validators.maxLength(16),
    ]),
    CcExpMonth: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(2),
      Validators.minLength(2),
    ]),
    CcExpYear: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(2),
      Validators.minLength(2),
    ]),
  });

  private _isPaid = false;

  constructor(
    private readonly _createSubscriptionService: CreateSubscriptionService,
    private readonly _notification: NotificationService,
    private readonly _errorFormService: ErrorFormService
  ) {}

  submit(): boolean {
    this.errors = this._errorFormService.verifyError(
      this.form,
      this.errorMessages
    );

    if (this.form.invalid) {
      return;
    }

    this.loading.emit(true);
    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this._createSubscribe((data) => {
          this._getClientSecretNmi(data);
        });

        break;
      case TypeOfCreateSubscription.RESET_ACCOUNT:
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this._resetAccount((data) => {
          this._getClientSecretNmi(data);
        });

        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this._createSubscriptionService.changePaymentMethod(
          Payment.NMI,
          this.subscriptionsId,
          (data) => {
            this._getClientSecretNmi(data);
          },
          this.form.get('CcNumber').value,
          this.form.get('CcExpMonth').value + this.form.get('CcExpYear').value
        );
        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this._payAgreement((data) => {
          this._getClientSecretNmi(data);
        });

        break;
      case TypeOfCreateSubscription.DEPOSIT:
        this._depositWallet((data) => {
          this._getClientSecretNmi(data);
        });
        break;

      default:
        break;
    }

    return false;
  }

  private _createSubscribe(cb: (data: IPaymentData | undefined) => void): void {
    this.loading.emit(true);
    if (this.subscription) {
      this.subscriptionChange.emit(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.loading.emit(false);
      }
      this.isPaid = true;
      cb(this.subscription);

      return;
    }
    this._createSubscriptionService.createSubscribe(
      PaymentMethod.CREDIT_CARD_NMI,
      this.promocodeId,
      (data) => {
        const subscriptionRes = data?.subscriptionRes;
        if (subscriptionRes) {
          this.subscription = data;
          this.subscriptionChange.emit(this.subscription);
        } else {
          if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
            this.loading.emit(false);
          }
          this.isPaid = true;
        }
        cb(data);
      },
      this.form.get('CcNumber').value,
      this.form.get('CcExpMonth').value + this.form.get('CcExpYear').value
    );
  }

  private _resetAccount(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.resetAccount(
      PaymentMethod.CREDIT_CARD_NMI,
      this.price,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      },
      this.form.get('CcNumber').value,
      this.form.get('CcExpMonth').value + this.form.get('CcExpYear').value
    );
  }

  private _getClientSecretNmi(data: IPaymentData): void {
    if (!data) {
      this.loading.emit(false);

      return;
    } else if (
      data.errorDoublePayment === ErrorCode.ERROR_CODE_DOUBLE_PAYMENT
    ) {
      this.loading.emit(false);
      // Show error to your customer
      this._notification.showBlankNotification(
        'Error: Payment has already been made, please wait for your account status to be updated',
        TypeMessage.ERROR
      );

      return;
    }

    this.next.emit();
    this.loading.emit(false);
  }

  private _payAgreement(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.payAgreement(
      PaymentMethod.CREDIT_CARD_NMI,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      },
      this.form.get('CcNumber').value,
      this.form.get('CcExpMonth').value + this.form.get('CcExpYear').value
    );
  }

  private _depositWallet(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.depositWallet(
      PaymentMethod.CREDIT_CARD_NMI,
      this.price,
      (data) => {
        cb(data);
      },
      this.form.get('CcNumber').value,
      this.form.get('CcExpMonth').value + this.form.get('CcExpYear').value
    );
  }
}
