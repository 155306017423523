import { NgModule } from '@angular/core';
import { CommonPopUpComponent } from './common-pop-up/common-pop-up.component';
import { CommonModule } from '@angular/common';
import { LoaderModule } from 'loader';

@NgModule({
  declarations: [CommonPopUpComponent],
  imports: [
    CommonModule,
    LoaderModule
  ],
  exports: [CommonPopUpComponent]
})
export class CommonPopUpModule { }
