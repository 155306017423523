import { UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'lib-exist-affiliate-window',
  templateUrl: './exist-affiliate-window.component.html',
  styleUrls: ['./exist-affiliate-window.component.scss']
})

export class ExistAffiliateWindowComponent {
  icon = '/assets/success-registration.svg';
}
