import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injectable,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { AccountsModule } from 'accounts';
import { Configuration, IdentityModule } from 'identity';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BaseComponentsModule } from 'projects/base-components/src/public-api';
import { CreateSubscriptionModule } from 'projects/create-subscription';
import { LoginModule } from 'projects/login/src/public-api';
import { SignUpModule } from 'projects/sign-up/src/public-api';
import { UserModule } from 'user';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModalModule } from './common-modal';
import { ChoosePlatformComponent } from './components/choose-platform/choose-platform.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ServerURLInterceptor } from './server-url-interceptor';
import { ModalModule } from './_modal';
import { UserCabinetModule } from 'user-cabinet';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Router } from '@angular/router';
import { Translate } from 'localization';
import { BrowserFileModule } from 'platform-file-loader';
import { StorageModule } from 'repository';
import {
  RecaptchaModule,
} from 'ng-recaptcha';
import { CookieBannerModule } from 'cookie-banner';
/*import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';*/
/*
Sentry.init({
  dsn: environment.sentryDsn,
  debug: true,
  environment: environment.production ? 'prod' : 'dev',
  integrations: [new BrowserTracing({})],
  tracesSampleRate: 1,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  // tslint:disable-next-line: no-any
  handleError(error: any) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
  }
}*/

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  // tslint:disable-next-line:no-any
  handleError(error: any) {
    const router = this.injector.get(Router);
    // tslint:disable-next-line:no-console
    console.log('URL: ' + router.url);
    if (error instanceof HttpErrorResponse) {
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
    } else {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.message);
    }
  }
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IdentityModule.forRoot(
      () =>
        new Configuration({
          withCredentials: true,
          basePath: environment.identityUrl,
        })
    ),
    AccountsModule.forRoot(
      () =>
        new Configuration({
          withCredentials: true,
          basePath: environment.accountsUrl,
        })
    ),

    UserModule.forRoot(),
    CreateSubscriptionModule,
    AppRoutingModule, // TODO: must be imported as the last module as it contains the fallback route
    FormsModule,
    ReactiveFormsModule,
    BrowserTransferStateModule,
    RecaptchaModule,
    HttpClientModule,
    Translate.forRoot(),
    BrowserFileModule.forRoot(),
    ModalModule,
    StorageModule.forRoot(),
    CommonModalModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    CarouselModule,
    ReactiveFormsModule,
    BaseComponentsModule,
    LoginModule,
    SignUpModule,
    UserCabinetModule,
    NzToolTipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
    }),
    CookieBannerModule,
  ],
  declarations: [
    AppComponent,
    ChoosePlatformComponent,
    PageNotFoundComponent,
    ResetPasswordComponent,
  ],
  exports: [AppComponent],

  providers: [
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerURLInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
