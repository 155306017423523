import { Component } from '@angular/core';

@Component({
  selector: 'app-choose-platform',
  templateUrl: './choose-platform.component.html',
  styleUrls: ['./choose-platform.component.scss'],
})
export class ChoosePlatformComponent {
  public readonly platformList = [
    { chosen: true, name: 'Meta trader', icon: '' },
    { chosen: false, name: 'Ninja Trader', icon: '' },
    { choose: false, name: 'Trade Station', icon: '' },
    { chosen: false, name: 'TT Platform', icon: '' },
  ];
}
