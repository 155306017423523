import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-tier-tooltip',
  templateUrl: './tier-tooltip.component.html',
  styleUrls: ['./tier-tooltip.component.scss'],
})
export class TierTooltipComponent {
  @Input() tier: number;
  @Input() currentTier: number;
  @Input() currentLightning: string;

  get tierIcon(): string {
    if (this.tier <= this.currentTier) {
      return '/assets/rewards/success.svg';
    } else if (this.tier <= this.currentTier) {
      return '/assets/rewards/success.svg';
    } else if (this.tier <= this.currentTier) {
      return '/assets/rewards/success.svg';
    } else {
      return '/assets/rewards/not-small-available.svg';
    }
  }

  tierList = [
    {
      points: '10K',
      reset: true,
      freeReset: ' $25K ',
      proActivation: false,
      freePro: false,
      macbook: false,
    },
    {
      points: '25K',
      reset: true,
      freeReset: ' $75K ',
      proActivation: false,
      freePro: false,
      macbook: false,
    },
    {
      points: '50K',
      reset: true,
      freeReset: ' $150K ',
      proActivation: '$130 ',
      freePro: false,
      macbook: false,
    },
    {
      points: '100K',
      reset: false,
      freeReset: false,
      proActivation: false,
      freePro: true,
      macbook: true,
    },
  ];
}
