import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculationType, TypeOfCreateSubscription } from '../enum';
import { IValidatePromocodeBody } from '../interfaces';
import { PromocodeService } from '../services/promocode.service';
import { User } from 'user';
import {
  AccountType,
  ActionTypePromocode,
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IAccountSize,
  IErrorMessage,
  LocalStorage,
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lib-add-promocode',
  templateUrl: './add-promocode.component.html',
  styleUrls: ['./add-promocode.component.scss'],
})
export class AddPromocodeComponent implements OnInit, OnChanges {
  @Input()
  public update: boolean;

  @Input()
  public actionType: ActionTypePromocode;
  @Input()
  public accountType: AccountType;

  @Input()
  public isConfirmSubscription = false;

  @Input()
  public set textPrice(value: string) {
    if (value === this._textPrice) {
      return;
    }
    this.isShowForm = false;
    this._textPrice = value;
  }

  public get textPrice(): string {
    return this._textPrice;
  }

  @Input()
  public price: number;

  @Input()
  public type: TypeOfCreateSubscription;

  @Output()
  public readonly codeChange = new EventEmitter<number>();

  @Output()
  public readonly promoPriceChange = new EventEmitter<number>();

  public isShowForm = false;
  public isValidPromocode = false;
  public calculationType: CalculationType;
  public showLoader = false;
  public errors: ErrorsMap<string> = {};

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    code: {
      required: 'This field is required',
      pattern: 'Only capital letters and numbers are allowed.',
      maxlength: 'Max length 30 letters',
    },
  };

  public readonly CalculationType = CalculationType;
  public readonly ActionType = ActionTypePromocode;
  public readonly TypeOfCreateSubscription = TypeOfCreateSubscription;

  public discount = 0;
  public total = '';

  public form: FormGroup;

  private _textPrice = '';

  constructor(
    private readonly user: User,
    private readonly _formBuilder: FormBuilder,
    private readonly _promocodeService: PromocodeService,
    private readonly _errorHttpService: ErrorHttpService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _localStorage: LocalStorage
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      code: [
        this.getReferralCode(),
        [
          Validators.required,
          Validators.pattern('^[A-Z0-9]+$'),
          Validators.maxLength(30),
        ],
      ],
    });
  }

  ngOnChanges(): void {
    if (this.isConfirmSubscription) {
      this.updatedReferralLink();
    }
  }

  submit(): void {
    const form = this.form;

    if (form.invalid) {
      this.errors = this._errorFormService.verifyError(
        form,
        this.errorMessages
      );

      return;
    }

    this.showLoader = true;

    const code = form.value.code.trim();

    const account: IAccountSize = JSON.parse(
      this._localStorage.getItem('accountSize')
    );

    const body: IValidatePromocodeBody = {
      code,
      actionType: this.actionType,
      amount: this.price,
      accountType: this.accountType,
      isNewExpress:
        this.actionType === ActionTypePromocode.NEW_USER && account?.id === 12,
    };

    this._promocodeService
      .verificate(body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.codeChange.emit(res.id);
          this.discount = res.discount;
          this.total = res.total.toFixed(2);
          this.promoPriceChange.emit(+this.total);
          this.calculationType = res.calculationType;
          this.isValidPromocode = true;
          this.showLoader = false;
        },
        (error) => {
          this._errorHttpService.showMessage(error);
          this.showLoader = false;
        }
      );
  }

  addForm(): void {
    this.isShowForm = true;
  }

  closeForm(): void {
    this.isShowForm = false;
    this.isValidPromocode = false;
    this.codeChange.emit(null);
    this.promoPriceChange.emit(this.price);
  }

  private updatedReferralLink(): void {
    this.form.controls.code.setValue(this.getReferralCode());

    if (this.user.status) {
      this.closeForm();

      return;
    }

    if (this.isShowForm || !this.textPrice) {
      return;
    }

    this.submit();
  }

  private getReferralCode(): string | null {
    const user = this.user;
    const account: IAccountSize = JSON.parse(
      this._localStorage.getItem('accountSize')
    );
    const FirstResetPromocode: string = this._localStorage.getItem(
      'FirstResetPromocode'
    );

    if (FirstResetPromocode) {
      this.actionType = ActionTypePromocode.CREATE_TEST_AND_FIRST_RESET;

      this.isShowForm = true;

      setTimeout(() => {
        this.submit();
        this._localStorage.removeItem('FirstResetPromocode');
      }, 500);

      return FirstResetPromocode;
    }

    return user?.status || account?.id === 12 ? null : user.referralCode;
  }
}
