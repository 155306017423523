import { ModuleWithProviders, NgModule } from '@angular/core';
import { FileLoader } from '../file.loader';
import { ROOT } from '../root';
import { BrowserFileLoader } from './browser-file.loader';

@NgModule()
export class BrowserFileModule {
  static forRoot(
    config: { root: string } = { root: '/' }
  ): ModuleWithProviders<BrowserFileModule> {
    return {
      ngModule: BrowserFileModule,
      providers: [
        {
          provide: FileLoader,
          useClass: BrowserFileLoader,
        },
        {
          provide: ROOT,
          useValue: config.root,
        },
      ],
    };
  }
}
