import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lib-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class StepIndicatorComponent implements OnChanges{

  @Input() number: number;
  @Input() steps = 1;

  public indicators: number[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.steps) {
      this.indicators = Array.from({ length: this.steps }, (_, index) => index + 1);
    }
  }

}
