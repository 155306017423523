<nz-modal
  class="tpt-modal-window"
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null"
  [nzCentered]="true"
  [nzMaskClosable]="showClose"
  [nzWidth]="680"
>
  <div #modalContent></div>
  <div *ngIf="showCloseIcon">
    <p class="primary-close" (click)="handleCancel()">
      <img src="./assets/close-icon.svg" alt="close" />
    </p>
  </div>
  <div [ngClass]="{ 'hide-content': isLoading }">
    <ng-content></ng-content>
  </div>
  <section
    id="loading"
    *ngIf="isLoading"
    class="primary-dialog-popup primary-flex-center"
  >
    <lib-loading></lib-loading>
  </section>
</nz-modal>
