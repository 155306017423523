import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import { IWalletTransaction } from '../interafaces/i-wallet-transaction';
import { IWalletInfo } from '../interafaces/i-wallet-info';
import { PaymentPlatforms } from 'withdraw';
import { IAmountPayBody, IPaymentData } from 'create-subscription';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  AmericanAccountExist,
  AmericanCitizen,
  BankInfoType,
  IBackInfo,
  ICheckPlaidCountry,
  IHandleInfo,
  IPlaidCreateAccessToken,
  IPlaidCreateLinkToken,
  IPlaidInfo,
  IWithdrawDto,
  IWithdrawRequestList
} from 'repository';

@Injectable({
  providedIn: 'root'
})
export class WalletInfoService {
  loadData$ = new Subject();
  getRequestInfo$ = new BehaviorSubject<IHandleInfo>({} as IHandleInfo);
  transferToken$ = new BehaviorSubject<IHandleInfo>({} as IHandleInfo);
  identityVerificationToken$ = new BehaviorSubject<IHandleInfo>(
    {} as IHandleInfo
  );
  showAffiliateWindow$: BehaviorSubject<number> = new BehaviorSubject<number>(99);

  get requestPlaidInfo(): IHandleInfo {
    return this.getRequestInfo$.getValue();
  }

  constructor(private readonly _http: HttpClient) {
  }

  getWalletTransactions(): Observable<IWalletTransaction[]> {
    return this._http.get<IWalletTransaction[]>(
      `${environment.paymentsUrl}/api/Wallets/transactions`
    );
  }

  identityVerificationAffiliateRetry(userId: number) {
    const body = {
      clientUserId: btoa(String(userId))
    };

    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/PlaidCustomers/identity-verification-retry`,
      body
    );
  }

  getWallets(): Observable<IWalletInfo> {
    return this._http.get<IWalletInfo>(
      `${environment.paymentsUrl}/api/Wallets`
    );
  }

  getWithdrawRequest(userId: number): Observable<IWithdrawRequestList[]> {
    return this._http.get<IWithdrawRequestList[]>(
      `${environment.paymentsUrl}/api/accounts/${userId}`
    );
  }

  deposit(
    type: PaymentPlatforms,
    body: IAmountPayBody
  ): Observable<IPaymentData> {
    return this._http.post<IPaymentData>(
      `${environment.paymentsUrl}/api/Wallets/deposit/${type}`,
      body
    );
  }

  loadPlaidCustomer(id: number): Observable<IPlaidInfo> {
    return this._http.get<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/${id}`
    );
  }

  loadBankInfoCustomer(id: number): Observable<IBackInfo> {
    return this._http.get<IBackInfo>(
      `${environment.paymentsUrl}/api/BanksInfo/${id}`
    );
  }

  createPlaidRequest(body: {
    plaidClientId: string;
    americanCitizen: AmericanCitizen;
    userId: number;
  }): Observable<IPlaidInfo> {
    return this._http.post<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/`,
      body
    );
  }

  getTaxForm(taxForm: number): Observable<{ link: string }> {
    return this._http.post<{ link: string }>(
      `${environment.agreementsUrl}/api/Agreements/sign-tax-form?taxFormType=${taxForm}`,
      {}
    );
  }

  checkCountry(countryCode: number): Observable<ICheckPlaidCountry> {
    return this._http.get<ICheckPlaidCountry>(
      `${environment.paymentsUrl}/api/PlaidSupportedCountry/${countryCode}`
    );
  }

  createPlaidLinkToken(body: {
    clientUserId: string;
  }): Observable<IPlaidCreateLinkToken> {
    return this._http.post<IPlaidCreateLinkToken>(
      `${environment.paymentsUrl}/api/PlaidCustomers/plaid-link-token`,
      body
    );
  }

  createPlaidAccessToken(
    publicToken: string
  ): Observable<IPlaidCreateAccessToken> {
    const body = {
      publicToken
    };

    return this._http.post<IPlaidCreateAccessToken>(
      `${environment.paymentsUrl}/api/PlaidCustomers/exchange-plaid-link-token`,
      body
    );
  }

  saveAccessToken(
    accessToken: IPlaidCreateAccessToken,
    plaidInfo: IPlaidInfo
  ): Observable<IPlaidInfo> {
    const body = {
      userAccessToken: accessToken.accessToken,
      itemId: accessToken.itemId,
      plaidClientId: plaidInfo.plaidClientId,
      isVerified: plaidInfo.isVerified
    };

    return this._http.put<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/${plaidInfo.id}`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  getPlaidAccounts(accessToken: string): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.get<any>(
      `${environment.paymentsUrl}/api/PlaidCustomers/plaid-get-bank-accounts?accessToken=${accessToken}`
    );
  }

  setVerification(plaidClientId: string) {
    const body = {
      ClientId: environment.plaid_clientId,
      secret: environment.plaid_secret,
      TemplateId: environment.plaid_template,
      GaveConsent: true,
      IsShareable: true,
      clientUserId: plaidClientId
    };

    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/PlaidCustomers/identity-verification-create`,
      body
    );
  }

  updateBankInfo(body: IWithdrawDto): Observable<null> {
    return this._http.put<null>(
      `${environment.paymentsUrl}/api/BanksInfo`,
      body
    );
  }

  saveBankInfo(body: IWithdrawDto): Observable<null> {
    return this._http.post<null>(
      `${environment.paymentsUrl}/api/BanksInfo`,
      body
    );
  }

  updatePlaidInfo(body: IPlaidInfo, plaidId: number): Observable<IPlaidInfo> {
    return this._http.put<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/${plaidId}`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  updateWithdrawRequest(body: { requestId: number }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/InternationRequest/make-submitted`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  internationalWithdrawRequest(body: {
    requestId: number;
    bankInfoType: BankInfoType;
    // tslint:disable-next-line:no-any
  }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/InternationRequest`,
      body
    );
  }

  setTransferId(body: {
    withdrawPlaidRequestId: number;
    walletId: number;
    transferId: string;
    // tslint:disable-next-line:no-any
  }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/Wallets/set_transfer_id`,
      body
    );
  }

  createTransfer(body: {
    userId: number;
    clientId: string;
    secret: string;
    accessToken: string;
    accountId: string;
    legalName: string;
    amount: string;
  }): Observable<string> {
    return this._http.post<string>(
      `${environment.paymentsUrl}/api/PlaidCustomers/transfer-create`,
      body
    );
  }
}
