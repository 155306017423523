import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ISubscription } from 'repository';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class CreateSubscriptionHttpService {
  constructor(private readonly _http: HttpClient) {}

  create(body: ISubscription): Observable<number> {
    return this._http.post<number>(
      `${environment.accountsUrl}/api/Accounts/pending`,
      body
    );
  }
}
