import { NgModule } from '@angular/core';
import { UserCabinetPageComponent } from './user-cabinet-page/user-cabinet-page.component';
import { CabinetPageRoutingModule } from './cabinet-page-routing.module';
import { CommonModule } from '@angular/common';
import { WalletNotificationModule } from 'wallet-notification';
import { BaseComponentsModule } from 'base-components';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ModalModule } from '@app/_modal';
import { UserInfoModule } from './user-info.module';
import { Translate } from 'localization';

@NgModule({
  declarations: [UserCabinetPageComponent],
  imports: [
    CabinetPageRoutingModule,
    CommonModule,
    WalletNotificationModule,
    BaseComponentsModule,
    NzDropDownModule,
    ModalModule,
    WalletNotificationModule,
    UserInfoModule,
    Translate,
  ],
})
export class UserCabinetModule {}
