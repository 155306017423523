import { UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'lib-confirm-affiliate-window',
  templateUrl: './confirm-affiliate-window.component.html',
  styleUrls: ['./confirm-affiliate-window.component.scss']
})
export class ConfirmAffiliateWindowComponent {
  icon = '/assets/success registration.svg';
}
