export enum PlaidProducts {
  assets,
  auth,
  employment,
  identity,
  income_verification,
  identity_verification,
  investments,
  liabilities,
  payment_initiation,
  standing_orders,
  transactions,
  transfer,
  signal,
}
