import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { AccountsService, LoginInputModel, UsersService } from '../../../identity';
import { IError } from 'protractor/built/exitCodes';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { ErrorCodes, IValidateFields, NotificationService, PlaidVerificationStatus, TypeMessage } from 'repository';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

interface IUserInfo {
  address: string;
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  plaidVerificationStatus: number;
  identityVerificationId: string;
  zipCode: string;
  tradingAddress: string;
  tradingCity: string;
  tradingFirstName: string;
  tradingLastName: string;
  countryCode: number;
  attemptCounter: number;
  phoneNumber: string;
  id: number;
  stateCode: number;
  status: number;
  referralCode?: string;
  photo: string;
  lockoutEnd: string | null;
  phoneNumberConfirmed: boolean;
}

const clientInfo = {
  clientId: environment.clientId,
  clientSecret: environment.clientSecret,
  scope: environment.scope
};

@UntilDestroy()
@Injectable()
export class User {
  firstName: string;
  lastName: string;
  countryCode: number;
  attemptCounter: number;
  plaidVerificationStatus: number;
  stateCode: number;
  zipCode: string;
  city: string;
  phoneNumber: string;
  identityVerificationId: string;
  address: string;
  email: string;
  photo: string;
  tradingAddress: string;
  tradingCity: string;
  tradingFirstName: string;
  tradingLastName: string;
  walletSize: number;
  status: number;
  referralCode: string;
  phoneNumberConfirmed: boolean;
  lockoutEnd: string | null;
  id: number;
  companyName: string;

  // tslint:disable-next-line:no-any
  private _refreshToken?: Observable<any>;

  private _isLogined = false;
  private readonly inputRequirementsName = new RegExp('^[A-Za-z\\s]*$');
  private readonly inputRequirements = new RegExp('^(?=.*[A-Za-z])[A-Za-z0-9\\s!#$%^&*()_+=\\-~\\][{}|\\/?.><]*$');
  private readonly inputRequirementsAddress = new RegExp('^(?=.*[A-Za-z])(?=.*\\s)(?!\\s)[A-Za-z0-9\\s!#$%^&*()_+=\\-~\\][{}|\\/?.><]*(?<!\\s)$');

  get isLogined(): boolean {
    return this._isLogined;
  }

  set isLogined(value: boolean) {
    this._isLogined = value;

    if (!value) {
      this.firstName = '';
      this.lastName = '';
      this.countryCode = 0;
      this.attemptCounter = 0;
      this.stateCode = 0;
      this.plaidVerificationStatus = 0;
      this.zipCode = '';
      this.city = '';
      this.companyName = '';
      this.identityVerificationId = '';
      this.address = '';
      this.tradingAddress = '';
      this.tradingCity = '';
      this.tradingFirstName = '';
      this.tradingLastName = '';
      this.email = '';
      this.photo = '';
      this.status = 1;
      this.referralCode = '';
      this.id = null;
    }
  }

  get fullName() {
    return `${this.tradingFirstName} ${this.tradingLastName}`;
  }

  get abbreviation() {
    return `${this.tradingFirstName[0]}${this.tradingLastName[0]}`;
  }

  get walletAmount() {
    return this.walletSize;
  }

  constructor(
    private readonly _accountsService: AccountsService,
    private readonly _usersService: UsersService,
    private readonly _notificationService: NotificationService,
    private readonly _router: Router,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
    this.firstName = '';
    this.lastName = '';
    this.countryCode = 0;
    this.attemptCounter = 0;
    this.walletSize = 0;
    this.stateCode = 0;
    this.plaidVerificationStatus = 0;
    this.zipCode = '';
    this.identityVerificationId = '';
    this.city = '';
    this.address = '';
    this.tradingAddress = '';
    this.tradingCity = '';
    this.tradingFirstName = '';
    this.tradingLastName = '';
    this.email = '';
    this.status = 1;
    this.referralCode = '';
    this.photo = 'assets/user-info/default-icon.png';
  }

  async init(): Promise<void> {
    try {
      await this._init().toPromise();
      /*      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.id.toString(),
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        });
      });*/
    } catch (e) {
      console.error(e);
    }
  }

  validateFirstName(firstName: string): boolean {
    return this.inputRequirementsName.test(firstName) && firstName.length > 1 && firstName.length<=30;
  }

  // Метод для валидации фамилии пользователя
  validateLastName(lastName: string): boolean {
    return this.inputRequirementsName.test(lastName) && lastName.length > 1  && lastName.length<=30;
  }

  // Метод для валидации адреса
  validateAddress(address: string): boolean {
    return this.inputRequirementsAddress.test(address) && address.length > 2 && address.length<=60;
  }

  // Метод для валидации города
  validateCity(city: string): boolean {
    return this.inputRequirements.test(city) && city.length > 1  && city.length<=30;
  }

  get validateUserData(): IValidateFields {
    return {
      firstNameValid: this.validateFirstName(this.firstName),
      lastNameValid: this.validateLastName(this.lastName),
      addressValid: this.validateAddress(this.address),
      cityValid: this.validateCity(this.city)
    };
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  refreshToken(): Observable<any> {
    if (this.checkCookies('refresh_token=')) {
      return this._accountsService.accountsRefreshTokenPost(clientInfo);
    } else {
      return of(false);
    }
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  signOut(): Observable<any> {
    return this._accountsService.accountsLogoutGet('response').pipe(
      tap(() => {
        this.isLogined = false;
        this._notificationService.showBlankNotification(
          'You have successfully signed out',
          TypeMessage.SUCCESS
        );
      }),
      catchError((error) => {
        this.isLogined = false;

        return throwError(error);
      })
    );
  }

  login(command: LoginInputModel): Observable<void> {
    return this._accountsService
      .accountsLoginPost({ ...command, ...clientInfo })
      .pipe(mergeMap((i) => this._loadUser()));
  }

  updateUserInfo(): Observable<IUserInfo> {
    return this._loadUser();
  }

  checkCookies(key: string) {
    if (isPlatformBrowser(this._platformId)) {
      return (
        document.cookie.split(';').filter((item) => item.includes(key)).length >
        0
      );
    }
  }

  setDefaultPhoto() {
    this.photo = 'assets/user-info/default-icon.png';
  }

  get isVerifiedUser(): boolean {
    return this.plaidVerificationStatus === PlaidVerificationStatus.Success;
  }

  get isUserInfo(): boolean {
    return !!(
      this.countryCode !== null &&
      this.city &&
      this.address &&
      this.phoneNumber &&
      this.zipCode
    );
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  private _init(): Observable<any> {
    if (this._refreshToken) {
      return this._refreshToken;
    }

    this._refreshToken = this.refreshToken().pipe(
      shareReplay(1),
      mergeMap(() => this._loadUser()),
      tap(() => (this._refreshToken = undefined)),
      catchError((err: IError) => {
        this.isLogined = false;
        this._refreshToken = undefined;

        return of(err);
      })
    );

    return this._refreshToken;
  }

  private _loadUser() {
    return this._usersService.apiUsersUserInfoGet().pipe(
      tap((data) => {
        const userInfo: IUserInfo = data ?? {};
        this.id = userInfo.id;
        this.firstName = userInfo.firstName;
        this.lastName = userInfo.lastName;
        this.countryCode = userInfo.countryCode;
        this.attemptCounter = userInfo.attemptCounter;
        this.phoneNumber = userInfo.phoneNumber;
        this.identityVerificationId = userInfo.identityVerificationId;
        this.plaidVerificationStatus = userInfo.plaidVerificationStatus;
        this.phoneNumberConfirmed = userInfo.phoneNumberConfirmed;
        this.stateCode = userInfo.stateCode;
        this.city = userInfo.city;
        this.tradingAddress = userInfo.tradingAddress;
        this.tradingCity = userInfo.tradingCity;
        this.tradingFirstName = userInfo.tradingFirstName;
        this.tradingLastName = userInfo.tradingLastName;
        this.address = userInfo.address;
        this.companyName = userInfo.companyName;
        this.zipCode = userInfo.zipCode;
        this.email = userInfo.email;
        this.status = userInfo.status;
        this.referralCode = userInfo.referralCode;
        this.lockoutEnd = userInfo.lockoutEnd;
        this.isLogined = true;
        /*
        if (this.lockoutEnd) {
          this.signOut()
            .pipe(untilDestroyed(this))
            .subscribe((val) => {
              this._notificationService.showBlankNotification(
                'Your account has been blocked',
                TypeMessage.ERROR
              );
              this._router.navigate(['/']).then();
            });
        }*/

        this._usersService
          .getUserPhoto()
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            if (res.url) {
              this.photo = res.url;
            }
          });

        this._usersService
          .getWallets()
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            this.walletSize = res.balance;
          });
      }),
      catchError((e) => {
        if (
          e instanceof HttpErrorResponse &&
          e.status === ErrorCodes.ACCESS_DENIED
        ) {
          this.isLogined = false;
        }

        return of(e);
      })
    );
  }
}
