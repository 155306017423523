import { NgModule } from '@angular/core';
import { SharedTitleModalComponent } from './dialog-header/shared-title-modal.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SelectTradingPlatformComponent } from './select-trading-platform/select-trading-platform.component';
import { TranslateModule } from '@ngx-translate/core';
import { LevelSystemComponent } from './level-system/level-system/level-system.component';
import { LevelSystemChartComponent } from './level-system/level-system-chart/level-system-chart.component';
import { LevelSystemLineComponent } from './level-system/level-system-line/level-system-line.component';
import { TierTooltipComponent } from './level-system/tier-tooltip/tier-tooltip.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { StepIndicatorComponent } from './step-indicator/step-indicator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { DateCalendarByPartsComponent } from './date-calendar-by-parts/date-calendar-by-parts.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [
    SharedTitleModalComponent,
    SelectTradingPlatformComponent,
    LevelSystemComponent,
    LevelSystemChartComponent,
    LevelSystemLineComponent,
    TierTooltipComponent,
    QuestionnaireComponent,
    StepIndicatorComponent,
    DateCalendarByPartsComponent
  ],
  imports: [CommonModule, MatDialogModule, TranslateModule, NzToolTipModule, ReactiveFormsModule, NgxIntlTelInputModule, NzFormModule, NzGridModule, NzInputModule, NzRadioModule, FormsModule, NzSelectModule],
  exports: [
    SharedTitleModalComponent,
    SelectTradingPlatformComponent,
    LevelSystemComponent,
    QuestionnaireComponent,
    StepIndicatorComponent,
    DateCalendarByPartsComponent
  ]
})
export class SharedModule {
}
