<div class="title">{{ 'level-system' | translate }}</div>
<div class="sub-title">
  {{ 'points-accrue-and-push-you-from-tier-to-tier' | translate }}
</div>
<div class="chart-block" #chartBlock>
  <div class="flex-block-lvl-system" *ngFor="let val of candleList">
    <ng-container [ngSwitch]="val.type">
      <ng-container *ngSwitchCase="1">
        <lib-level-system-chart
          [current]="current"
          [styleBlock]="item"
          *ngFor="let item of val.list"
        ></lib-level-system-chart>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <lib-level-system-line
          [top]="val.top"
          [currentLightning]="val.currentLightning"
          [tier]="val.tier"
          [currentTier]="this.tier - 1"
        ></lib-level-system-line>
      </ng-container>
    </ng-container>
  </div>
</div>
