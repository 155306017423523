import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { FileLoader } from '../file.loader';
import { ROOT } from '../root';

@Injectable()
export class BrowserFileLoader extends FileLoader {
  constructor(
    transferState: TransferState,
    @Inject(ROOT) protected root: string,
    protected http: HttpClient
  ) {
    super(transferState, root);
  }

  loadFile(file: string): Observable<object> {
    // TODO: fix any
    // tslint:disable-next-line:no-any
    const data: any = this.transferState.get(makeStateKey(file), null);

    return data ? of(data) : this.http.get(this.getPath(file));
  }
}
