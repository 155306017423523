<div
  class="block-platform"
  [ngClass]="{
    'block-platform-selected': selectedPlatform == platform.Tradovate
  }"
  (click)="selectPlatform(platform.Tradovate)"
>
  <div *ngFor="let item of platformList | slice: 0:1" class="card-platform">
    <p class="card-platform-title">
      <span class="title">{{ item.title }}</span>
      <span *ngIf="item.recommended" class="recommendation">
        {{ 'recommended' | translate }}
      </span>
    </p>
    <p class="card-platform-subTitle">{{ item.subTitle }}</p>
    <div class="logo-block-platform">
      <div
        *ngFor="let val of item.items | slice: 0:setEnd; let index = index"
        class="logo-platform"
      >
        <img [src]="val.icon" [alt]="val.label" class="w-100" />
      </div>
    </div>
  </div>
</div>

<div
  class="block-platform"
  [ngClass]="{
    'block-platform-selected': selectedPlatform == platform.Rithmic
  }"
  (click)="selectPlatform(platform.Rithmic)"
>
  <div *ngFor="let item of platformList | slice: 1:2" class="card-platform">
    <p class="card-platform-title">
      <span class="title">{{ item.title }}</span>
      <span *ngIf="item.recommended" class="recommendation">
        {{ 'recommended' | translate }}
      </span>
    </p>
    <p class="card-platform-subTitle">{{ item.subTitle }}</p>
    <div class="logo-block-platform-more">
      <div
        *ngFor="let val of item.items | slice: 0:setEnd; let index = index"
        class="logo-platform-more"
      >
        <img [src]="val.icon" [alt]="val.label" class="w-100" />
      </div>
      <div
        *ngIf="item.items.length > 3"
        [class]="{ 'logo-platform': seeMore }"
        class="logo-platform-more"
      >
        <div
          class="w-100 text-underline see-link text-center"
          (click)="showMore(item.items)"
        >
          <img
            [src]="
              seeMore
                ? '/assets/platformNewImg/Vector.svg'
                : '/assets/platformNewImg/Union.svg'
            "
            alt="action"
          />
        </div>
      </div>
    </div>
  </div>
</div>
