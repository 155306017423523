import { Component, EventEmitter, Output } from '@angular/core';
import { IBaseStep } from '../base-step/base-step';

@Component({
  selector: 'lib-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements IBaseStep {
  @Output() showChange = new EventEmitter<boolean>();

  close(): void {
    this.showChange.emit(false);
  }
}
