import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IEventColorList, IEventList } from 'repository';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private readonly _http: HttpClient) {}

  getEventColorByRange(dateRange: {
    startDate: string;
    endDate: string;
  }): Observable<IEventColorList[]> {
    return this._http.get<IEventColorList[]>(
      environment.accountsUrl +
        `/api/CalendarColors?fromDate=${dateRange.startDate}&toDate=${dateRange.endDate}`
    );
  }
  getEventByRange(dateRange: {
    startDate: string;
    endDate: string;
  }): Observable<IEventList[]> {
    return this._http.get<IEventList[]>(
      environment.accountsUrl +
        `/api/CalendarEvents?fromDate=${dateRange.startDate}&toDate=${dateRange.endDate}`
    );
  }
}
