<div class="confirm">
  <lib-confirm-subscription-info
    [type]="type"
    [accountSize]="accountSize"
    [tradingPlatform]="tradingPlatform"
    [name]="name"
    [textPrice]="textPrice"
  ></lib-confirm-subscription-info>
  <div
    *ngIf="
      type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT &&
      type !== TypeOfCreateSubscription.CHANGE_PAYMENT
    "
  >
    <lib-add-promocode
      [update]="update"
      [actionType]="actionType"
      [accountType]="accountType"
      [type]="type"
      (promoPriceChange)="getPromoPrice($event)"
      [textPrice]="textPrice"
      [price]="price"
      [isConfirmSubscription]="isConfirmSubscription"
      (codeChange)="emitPromocode($event)"
    ></lib-add-promocode>
  </div>

  <lib-payment-method
    [type]="type"
    [accountId]="accountId"
    [price]="price"
    [setDefaultPayment]="setDefaultPayment"
    [promoPrice]="promoPrice"
    (paymentChange)="emitPaymentMethod($event)"
    (toPayment)="emitToPayment()"
  ></lib-payment-method>
</div>
