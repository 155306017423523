import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestTwilioType } from '../enums';
import { ITwilioLog } from '../interfaces';

@Injectable()
export class TwilioService {
  constructor(private readonly _http: HttpClient) {}

  getInfo(userId: number): Observable<ITwilioLog[]> {
    return this._http.get<ITwilioLog[]>(
      `${environment.identityUrl}/api/users/user-twilio-requests`,
      { params: { userId: userId.toString() } }
    );
  }

  getVerifyCode(userId: number): Observable<string> {
    const body = new URLSearchParams();
    body.set('UserId', String(userId));

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers };

    return this._http.post<string>(
      `${environment.identityUrl}/api/users/twilio-start-verification`,
      body.toString(),
      options
    );
  }

  checkVerifyCode(userId: number, code: string): Observable<string> {
    const body = new URLSearchParams();
    body.set('userId', String(userId));
    body.set('Code', code);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers };

    return this._http.post<string>(
      `${environment.identityUrl}/api/users/twilio-check-verification`,
      body.toString(),
      options
    );
  }

  checkExistPhone(
    body: { UserId: number; PhoneNumber: string }
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.put<any>(
      `${environment.identityUrl}/api/users/check-phone-number-duplicates`,
      body
    );
  }

  updateConfirmPhone(
    body: { userId: number; phoneNumber: string; phoneNumberConfirmed: boolean }
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.put<any>(
      `${environment.identityUrl}/api/users/change-phone-number-confirmed`,
      body
    );
  }

  existPhone(
    phoneNumber: string
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.get<any>(
      `${environment.identityUrl}/api/users/check-phone-number-duplicates?phoneNumber=${phoneNumber}`
    );
  }

  loggedCreateTwilioService(
    body: {
      UserId: number;
      RequestTwilioType: RequestTwilioType;
      Payload: string;
      ResponseTwilio: string;
    }
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.identityUrl}/api/users/twilio-request`,
      body
    );
  }
}
