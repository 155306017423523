import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopUpSize } from '../enums';

@Component({
  selector: 'lib-common-pop-up',
  templateUrl: './common-pop-up.component.html',
  styleUrls: ['./common-pop-up.component.scss'],
})
export class CommonPopUpComponent {
  @Input()
  public isShow = false;

  @Input()
  public size = PopUpSize.L;

  @Input()
  public canClose = true;

  @Input()
  public isLoading = false;

  @Output()
  public readonly isShowChange = new EventEmitter<boolean>();

  close(): void {
    this.isShow = false;
    this.isShowChange.emit(this.isShow);
  }
}
