<lib-modal-window [isVisible]="show">
  <img src="/assets/successful-reset-password.svg" alt="" />
  <div id="title" *ngIf="type === ResetComponent.SUCCESS">
    <p class="text">{{ 'your-password' | translate }}</p>
    <p class="text">{{ 'enter-acc' | translate }}</p>
  </div>

  <div id="title" *ngIf="type === ResetComponent.NOT_VALID_TOKEN">
    <p class="text">{{ 'try-again' | translate }}</p>
  </div>

  <div class="div-button">
    <a href="/?showLogin=y"
      ><button class="primary-btn">{{ 'log-in-up' | translate }}</button></a
    >
  </div>
</lib-modal-window>
