import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LocalStorage, MemoryStorage } from '../interfaces';
import { WINDOW } from '../shared';

@Injectable()
export class BaseLocalStorage implements LocalStorage {
  get length(): number {
    return this.storage.length;
  }
  /**
   * Storage
   */
  private readonly storage: Storage;

  constructor(
    private readonly memoryStorage: MemoryStorage,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window
  ) {
    this.storage = isPlatformBrowser(this.platformId)
      ? this.window.localStorage
      : this.memoryStorage;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}
