<div class="email-popup-content">
  <img src="/assets/logos/mail.svg" alt="" />

  <div class="mb-4">
    <h3 id="title" class="text">{{ 'confirm-email' | translate }}</h3>
    <p id="new-subscription" class="text">
      {{ 'continue-registration' | translate }}
    </p>
    <button class="primary-btn" (click)="close()">
      {{ 'ok' | translate }}
    </button>
  </div>
</div>
