import { Component, OnInit } from '@angular/core';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  IExistAffiliate,
  IPartnershipRequestStatus,
  IPlaidInfo,
  NotificationService,
  PartnershipRequestStatus,
  PlaidProducts,
  PlaidVerificationStatus,
  TypeMessage
} from 'repository';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, interval, of } from 'rxjs';
import { UserCabinetService } from './../../lib/user-cabinet.service';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from 'user';
import { TranslateService } from 'localization';
// tslint:disable-next-line:no-any
declare let Plaid: any;

@UntilDestroy()
@Component({
  selector: 'lib-add-new-affiliate-code',
  templateUrl: './add-new-affiliate-code.component.html',
  styleUrls: ['./add-new-affiliate-code.component.scss']
})
export class AddNewAffiliateCodeComponent implements OnInit {
  showLoader = false;
  showAffiliateWindow$: BehaviorSubject<string | number> = new BehaviorSubject<string | number>('createCode');
  plaidInfo: IPlaidInfo = {} as IPlaidInfo;

  // tslint:disable-next-line:no-any
  plaidIdentityVerificationHandler: any;
  plaidClientId = btoa(String(this._user.id));
  loader = 99;
  failedAttemptCounter = 98;

  public steps = 2;
  public step = 1;

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    AffiliateCode: {
      required: 'Affiliate code is required',
      codeIsExist:
        'This code is already taken, please insert a different code.',
      maxlength: 'Max length 30 symbol',
      pattern: 'Can contain only uppercase letters and numbers'
    },
    acceptAffiliateTermsConditions: {
      required: 'Please accept Affiliate Terms & Conditions'
    },
    companyName: {
      required: 'Company name cannot be empty',
      maxlength: 'Max length 35 symbol',
      minlength: 'Min length 3 symbol'
    }
  };

  form: FormGroup;
  public formQuestionnaire: FormGroup = this._formBuilder.group({});

  public showLoader$ = new BehaviorSubject<boolean>(false);

  public errors: ErrorsMap<string> = {};
  public affiliateErrorCode = false;
  public PlaidVerificationStatus = PlaidVerificationStatus;

  constructor(
    private readonly _errorFormService: ErrorFormService,
    private readonly _userCabinetService: UserCabinetService,
    private readonly notification: NotificationService,
    private readonly _translateService: TranslateService,
    private readonly _user: User,
    private readonly _dialogRef: MatDialogRef<AddNewAffiliateCodeComponent>,
    private readonly _formBuilder: FormBuilder,
    private readonly _errorHttpService: ErrorHttpService
  ) {
  }

  public get isCompanyName(): boolean {
    return !!this._user?.companyName ?? false;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.getWindowInfo();
    this.initPlaid();

  }

  public buildForm = (): void => {
    this.form = new FormGroup({
      AffiliateCode: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern('[A-Z0-9]*$'),
          Validators.maxLength(30)
        ],
        [this.validateAffiliateCode, this.validateExistAffiliateCode]
      ),
      companyName: new FormControl(
        null,
        [
          Validators.required,
          Validators.maxLength(35),
          Validators.minLength(3),
          this._trim
        ]
      ),
      acceptAffiliateTermsConditions: new FormControl(
        false,
        [Validators.required, this._checkAcceptAffiliateTermsConditions]
      )
    });

    this.form
      .get('companyName')
      .setValue(this._user.companyName);

  };

  // tslint:disable-next-line:no-any
  public addNewCode(responseList: any[]) {

    this.showAffiliateWindow$.next('create');
    this.showLoader$.next(true);

    this._userCabinetService.changeCompanyName(this.form.get('companyName').value, this._user.id)
      .pipe(untilDestroyed(this),
        distinctUntilChanged(),
        switchMap(() => {
          this._user.companyName = this.form.get('companyName').value;

          return this._userCabinetService.createNewCode(
            {
              partnerFirstName: this._user.firstName,
              partnerLastName: this._user.lastName,
              partnerEmail: this._user.email,
              referralCode: this.form.get('AffiliateCode').value,
              partnerId: this._user.id
            }
          );
        }),
        switchMap((value) => this._userCabinetService.sendQuestionnaire({
            userId: this._user.id,
          partnershipRequestId: value?.id,
            answers: JSON.stringify(responseList)
          })
        ))
      .subscribe(
        () => {
          this.showLoader$.next(false);

          this.showAffiliateWindow$.next('create');
        },
        (error) => {
          switch (error.error.errorCode) {
            case 3021:
              this.notification.showBlankNotification(
                'You cannot create more than 1 referral codes. ' +
                'Please contact support if you have any questions',
                TypeMessage.ERROR
              );
              break;
            default:
              this.notification.showBlankNotification(
                'Affiliate code already exist',
                TypeMessage.ERROR
              );
              break;
          }
          this.showLoader$.next(false);

        }
      );
  }

  getWindowInfo(): void {
    this.showLoader$.next(true);

    combineLatest([
      this._userCabinetService.getPartnershipInfo().pipe(
        catchError((err: unknown) => {
          return of({} as IPartnershipRequestStatus[]);
        })
      ),
      this._userCabinetService.getReferralCodesInfo().pipe(
        catchError((err: unknown) => {
          // tslint:disable-next-line:no-any
          return of({} as any[]);
        })
      ),
      this._userCabinetService.loadPlaidCustomer(this._user.id).pipe(
        catchError((err: unknown) => {
          return of({} as IPlaidInfo);
        })
      )
    ]).pipe(
      map(([partnershipList, referralCodesList, plaidCustomer]) => ({
        partnershipList,
        referralCodesList,
        plaidCustomer
      }))
    )
      .pipe(untilDestroyed(this)).subscribe(value => {
      if (value.referralCodesList?.length > 0) {
        this.showLoader$.next(false);

        this.showAffiliateWindow$.next('exist');

        return;
      }

      switch (value.partnershipList[value.partnershipList.length - 1]?.status) {
        case PartnershipRequestStatus.Completed:
          this.showAffiliateWindow$.next('exist');
          break;
        case PartnershipRequestStatus.Pending:
          this.showAffiliateWindow$.next('pending');
          break;
        case PartnershipRequestStatus.Verifying:
          if (!value.plaidCustomer?.id) {
            this.showAffiliateWindow$.next(this.PlaidVerificationStatus.Pending);
            break;
          }
          const status = value.plaidCustomer.attemptCounter >= 2 &&
          value.plaidCustomer.plaidVerificationStatus === this.PlaidVerificationStatus.Failed
            ? this.failedAttemptCounter
            : value.plaidCustomer.plaidVerificationStatus;

          if (value.plaidCustomer.plaidVerificationStatus === PlaidVerificationStatus.Success) {
            this.showAffiliateWindow$.next('pending');
            break;
          }

          this.showAffiliateWindow$.next(status);
          break;
        default:
          break;
      }
      this.showLoader$.next(false);

    });
  }

  next(): void {

    const form: FormGroup = this.form;
    this.form.get('companyName').setValue(this.form.get('companyName')?.value?.trim());

    if (!form.value.acceptAffiliateTermsConditions) {
      this.errors.acceptAffiliateTermsConditions = this.errorMessages.acceptAffiliateTermsConditions.required;
    }

    if (this.form.get('AffiliateCode').hasError('codeIsExist')) {
      this.errors.AffiliateCode =
        'This code is already taken, please insert a different code.';

      return;
    }

    if (
      form.invalid ||
      !form.value.acceptAffiliateTermsConditions
    ) {

      this.errors = this._errorFormService.verifyError(form, this.errorMessages);

      return;
    }

    if (this.form.get('companyName')?.hasError('minlength') ||
      this.form.get('companyName')?.hasError('required')) {
      this.errors.companyName = 'Min length 3 symbol';
      this.notification.showBlankNotification(
        'Company Name min length 3 symbol',
        TypeMessage.ERROR
      );

      return;

    }
    this.showLoader$.next(true);
    this.step = 2;
    this.showLoader$.next(false);

    this.showAffiliateWindow$.next('questionnaire');

  }

  tryAgainVerification(): void {
    this.showLoader = true;
    this._userCabinetService
      .identityVerificationAffiliateRetry(this._user.id)
      .pipe(
        untilDestroyed(this)).subscribe(() => {
      this.goToPlaid();
    }, error => {
      this.showLoader = false;
      this._errorHttpService.showMessage(error);
    });
  }

  initPlaid(): void {
    let plaidInfo: { plaidClientId: string, plaidVerificationStatus: PlaidVerificationStatus };

    this._userCabinetService.identityVerificationToken$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter((val) => val.plaidClientId && val.plaidVerificationStatus === PlaidVerificationStatus.Pending),
        switchMap((res) => {
          plaidInfo = res;
          const body = {
            userId: this._user.id,
            clientUserId: res.plaidClientId,
            product: PlaidProducts.identity_verification
          };

          return this._userCabinetService.createPlaidLinkTokenAffiliate(body);
        }),
        switchMap(res => {
          const body =
            {
              gaveConsent: true,
              isShareable: true,
              clientUserId: this.plaidClientId,
              firstName: this._user.firstName,
              lastName: this._user.lastName
            };

          return combineLatest([of(res), this._userCabinetService
            .setVerificationAffiliate(body).pipe(
              map((val) => JSON.parse(val)),
              switchMap((identityResponse) => {
                return this._userCabinetService.updatePlaidInfo(
                  {
                    ...this.plaidInfo,
                    identityVerificationId: identityResponse.id
                  },
                  this.plaidInfo.id
                );
              })
            )
          ]);
        })
      )
      .subscribe(([res, verification]) => {
        this.showLoader = false;
        this.plaidIdentityVerificationHandler = Plaid.create({
          token: res.linkToken,
          // tslint:disable-next-line:no-any
          onSuccess: (public_token: any, metadata: any) => {
            this.setVerification();
          },
          // tslint:disable-next-line:no-any
          onExit: (err: any, metadata: any) => {
            this.notification.showBlankNotification(
              'Could not identify your account',
              TypeMessage.ERROR
            );
          }
        });
        this.plaidIdentityVerificationHandler.open();
      });
  }

  goToPlaid(): void {

    this._userCabinetService
      .loadPlaidCustomer(this._user.id)
      .pipe(
        catchError((err: unknown) => {
          return of({} as IPlaidInfo);
        }),
        switchMap(value => {
          this.plaidInfo = value;
          if (!value?.id) {
            const body = {
              plaidClientId: this.plaidClientId,
              userId: this._user.id
            };

            return this._userCabinetService.createPlaidRequest(body);
          } else {
            return of(value);
          }

        })
      ).subscribe((val) => {
      this.plaidInfo = val;
      this._userCabinetService.identityVerificationToken$.next({
        plaidClientId: this.plaidClientId,
        plaidVerificationStatus: PlaidVerificationStatus.Pending
      });
    });

  }

  private setVerification(): void {
    this.updateUserInfo(5000);
  }

  private updateUserInfo(timer: number): void {
    this.showAffiliateWindow$.next(this.loader);

    interval(timer)
      .pipe(
        switchMap(() => this._userCabinetService.loadPlaidCustomer(this._user.id)),
        takeWhile((response) => {
          return response.plaidVerificationStatus !== this.PlaidVerificationStatus.Success;
        }, true),
        untilDestroyed(this)
      )
      .subscribe((response) => {

        if (response.attemptCounter >= 2 &&
          response.plaidVerificationStatus === this.PlaidVerificationStatus.Failed) {
          this.showAffiliateWindow$.next(this.failedAttemptCounter);

          return;
        }

        switch (response.plaidVerificationStatus) {
          case this.PlaidVerificationStatus.Success:
            this.notification.showBlankNotification('You have successfully passed your verification', TypeMessage.SUCCESS);
            this._dialogRef.close();
            break;
          case this.PlaidVerificationStatus.PendingReview:
            this.showAffiliateWindow$.next(this.PlaidVerificationStatus.PendingReview);
            break;
          case this.PlaidVerificationStatus.Failed:
            this.showAffiliateWindow$.next(this.PlaidVerificationStatus.Failed);
            break;
          default:
            break;
        }
      });
  }

  private readonly validateAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getReferral(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of({} as IExistAffiliate)),
      map((res) => {
        return !res?.id ? null : { codeIsExist: 'reerer' };
      })
    );
  };

  private readonly validateExistAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getPartnershipValidate(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of(false)),
      map((res) => {
        return res ? null : { codeIsExist: 'reerer' };
      })
    );
  };

  private readonly _trim = (
    control: FormControl
  ): ValidationErrors => {
    if (control?.value?.trim()?.length === 0) {
      control?.setValue(control?.value?.trim());

      return { required: true };
    } else {
      return null;
    }
  };

  private readonly _checkAcceptAffiliateTermsConditions = (
    control: FormControl
  ): ValidationErrors => {
    return control.value ? null : { notSame: true };
  };

}
