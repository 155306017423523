import { Component, Input, OnInit } from '@angular/core';
import { LocalStorage } from 'repository';

interface IPlatforms {
  id: number;
  title: string;
  subTitle: string;
  recommended: boolean;
  items: IList[];
}

interface IList {
  icon: string;
  label: string;
}

enum Platform {
  Rithmic,
  Tradovate,
}

enum PlatformDefault {
  NinjaTrader = 1,
  RTrader = 4,
}

@Component({
  selector: 'lib-choose-trading-platform',
  templateUrl: './choose-trading-platform.component.html',
  styleUrls: ['./choose-trading-platform.component.scss'],
})
export class ChooseTradingPlatformComponent implements OnInit {
  @Input() update: boolean;
  platform = Platform;
  setEnd = 3;
  seeMore = true;

  platformList: IPlatforms[] = [
    {
      id: this.platform.Tradovate,
      title: 'CQG Data Feed',
      subTitle: 'Platforms Available',
      recommended: true,
      items: [
        {
          icon: '/assets/platformNewImg/NinjaTrader.png',
          label: 'NinjaTrader',
        },
        {
          icon: '/assets/platformNewImg/tradingview.png',
          label: 'tradingview',
        },
        { icon: '/assets/platformNewImg/Tradovate.png', label: 'Tradovate' },
      ],
    },
    {
      id: this.platform.Rithmic,
      title: 'Rithmic Data Feed',
      subTitle: 'Platforms Available',
      recommended: false,
      items: [
        { icon: '/assets/platformNewImg/RTrader.png', label: 'RTrader' },
        { icon: '/assets/platformNewImg/quantower.png', label: 'quantower' },
        { icon: '/assets/platformNewImg/motivewave.png', label: 'motivewave' },
        { icon: '/assets/platformNewImg/bookmap.png', label: 'bookmap' },
        { icon: '/assets/platformNewImg/agena.png', label: 'agena' },
        {
          icon: '/assets/platformNewImg/SierraChart.png',
          label: 'SierraChart',
        },
        {
          icon: '/assets/platformNewImg/multicharts.png',
          label: 'multicharts',
        },
        {
          icon: '/assets/platformNewImg/JigsawTrader.png',
          label: 'JigsawTrader',
        },
        { icon: '/assets/platformNewImg/ATASTrader.png', label: 'ATASTrader' },
        { icon: '/assets/platformNewImg/eSignal.png', label: 'eSignal' },
        { icon: '/assets/platformNewImg/InvestorRT.png', label: 'InvestorRT' },
        {
          icon: '/assets/platformNewImg/TradeNavigator.png',
          label: 'TradeNavigator',
        },
        { icon: '/assets/platformNewImg/volfix.png', label: 'volfix' },
        {
          icon: '/assets/platformNewImg/PhotonTrader.png',
          label: 'PhotonTrader',
        },
        { icon: '/assets/platformNewImg/scalp.png', label: 'scalp' },
        { icon: '/assets/platformNewImg/ATAS.png', label: 'ATAS' },
      ],
    },
  ];
  selectedPlatform: number;
  constructor(private readonly _localStorage: LocalStorage) {}

  showMore(list: IPlatforms[]): void {
    if (this.setEnd === 3) {
      this.setEnd = list.length;
      this.seeMore = false;
    } else {
      this.setEnd = 3;
      this.seeMore = true;
    }
  }

  /*

    platformsList: IPlatform[] = [];
    selectedPlatform: IPlatform;
    filteredPlatforms: IPlatform[] = [];
    more: boolean;

    private _marketType = 0;

    constructor() {
      this.more = true;
    }*/

  ngOnInit(): void {
    this.selectPlatform(this.platform.Tradovate);
  }

  submit(): boolean {
    return true;
  }

  get() {
    return this.selectedPlatform;
  }

  selectPlatform(platformId: number): void {
    this.selectedPlatform = platformId;
    switch (platformId) {
      case 0:
        this._localStorage.setItem(
          'tradingPlatform',
          PlatformDefault.RTrader.toString()
        );

        break;
      case 1:
        this._localStorage.setItem(
          'tradingPlatform',
          PlatformDefault.NinjaTrader.toString()
        );
        break;
      default:
        break;
    }
    this._localStorage.setItem('tradingPlatformGlobal', platformId?.toString());
    this._localStorage.setItem('platformType', platformId?.toString());
  }
}
