import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

import { CommonModalService } from './common-modal.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'common-modal',
  templateUrl: 'common-modal.component.html',
  styleUrls: ['common-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommonModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private readonly _element: HTMLElement;

  constructor(
    private readonly _modalService: CommonModalService,
    private readonly _el: ElementRef,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
    this._element = _el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('modal must have an id');

      return;
    }
    if (isPlatformBrowser(this._platformId)) {
      document.body.appendChild(this._element);
    }

    this._element.addEventListener('click', (el: Event) => {
      if ((el.target as HTMLElement).className === 'common-modal') {
        this.close();
      }
    });

    this._modalService.add(this);
  }

  ngOnDestroy(): void {
    this._modalService.remove(this.id);
    this._element.remove();
  }

  open(): void {
    this._element.style.display = 'block';
    if (isPlatformBrowser(this._platformId)) {
      document.body.classList.add('common-modal-open');
    }
  }

  close(): void {
    this._element.style.display = 'none';
    if (isPlatformBrowser(this._platformId)) {
      document.body.classList.remove('common-modal-open');
    }
  }
}
