import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopUpSize } from 'common-pop-up';
import { TypeOfCreateSubscription } from '../enum';

@Component({
  selector: 'lib-confirm-credits-transaction',
  templateUrl: './confirm-credits-transaction.component.html',
  styleUrls: ['./confirm-credits-transaction.component.scss'],
})
export class ConfirmCreditsTransactionComponent {
  @Input() show: boolean;
  @Input() type: TypeOfCreateSubscription;

  @Output() payWithCredits: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  PopUpSize = PopUpSize;

  TypeOfCreateSubscription = TypeOfCreateSubscription;

  close(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  pay() {
    this.close();
    this.payWithCredits.emit(true);
  }
}
