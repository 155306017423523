import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorsMap, IErrorMessage } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ErrorFormService {
  verifyError(
    form: FormGroup,
    errorMessages: ErrorsMap<IErrorMessage>
  ): ErrorsMap<string> {
    const errors: ErrorsMap<string> = {};

    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        const control = form.controls[i];
        control.markAsDirty();
        control.updateValueAndValidity();
        errors[i] =
          (control.dirty || control.touched) && control.invalid
            ? this._getError(errorMessages, i, control.errors)
            : 'Not valid';
      }
    }

    return errors;
  }

  private _getError(
    messages: ErrorsMap<IErrorMessage>,
    key: string,
    errors: ErrorsMap<string>
  ): string {
    const errorMessages = messages[key];
    const index = 0;
    const errorKey = Object.keys(errors)[index];

    if (errorMessages && typeof errorMessages[errorKey] === 'string') {
      return errorMessages[errorKey] as string;
    }

    return 'Not valid';
  }
}
