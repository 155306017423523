import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  @Input() showBack = true;
  @Input() showConfirm = false;
  @Input() showNext = true;
  @Input() step: number;
  @Input() disable: boolean;

  @Output() number = new EventEmitter<number>();
  @Output() next = new EventEmitter<number>();
  @Output() prev = new EventEmitter<number>();

  constructor() {
    this.disable = false;
  }

  back(): void {
    this.number.emit(--this.step);
  }

  confirm(): void {
    this.number.emit(++this.step);
  }

  clickToNext(): void {
    this.next.emit();
  }
}
