import { Component, Input } from '@angular/core';
import { TypeOfCreateSubscription } from '../enum/type-of-create-subscription.enum';

@Component({
  selector: 'lib-confirm-subscription-info',
  templateUrl: './confirm-subscription-info.component.html',
  styleUrls: ['./confirm-subscription-info.component.scss'],
})
export class ConfirmSubscriptionInfoComponent {
  @Input() type: TypeOfCreateSubscription;
  @Input() accountSize: string;
  @Input() tradingPlatform: string;
  @Input() name: string;
  @Input() textPrice: string;

  TypeOfCreateSubscription = TypeOfCreateSubscription;
}
