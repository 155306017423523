export enum TransactionInfoAction {
  Create,
  Reset,
  Disable,
  CreateAgreement,
  Withdraw,
  DepositWallet,
  CommissionForPartner,
  ChangePaymentMethod,
  AdminDeposit,
  AdminWithdraw,
  Bonus,
}
