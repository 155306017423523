<form [formGroup]='form'
      class='primary-form sign-up-form'
      (ngSubmit)='test()'

>
  <ng-container *ngFor='let field of formList; let index = index'>
    <ng-container [ngSwitch]='field.type'>
      <ng-container *ngSwitchCase='1'>
        <nz-form-item class='mb-3'>
          <div class='label-block'>
            <div>{{index + 1}}.</div>
            <div>{{field.questionTranslate|translate}}</div>
          </div>
          <nz-form-control [nzErrorTip]="errors['response_'+field.id]">
        <textarea
          rows='3'
          maxlength='400'
          nz-input
          class='primary-input'
          [formControlName]="'response_' + field.id"
          placeholder='Your answer'
        ></textarea>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <ng-container *ngSwitchCase='2'>
        <nz-form-item class='mb-3'>
          <div class='label-block'>
            <div>{{index + 1}}.</div>
            <div>{{field.questionTranslate|translate}}</div>
          </div>
          <nz-form-control [nzErrorTip]="errors['response_'+field.id]">
            <div class='yes-no-block'>
              <div class='checkbox-block'>
                <input
                  #yes
                  nz-input
                  type='checkbox'
                  class='primary-input'
                  (change)='toggleYes(index, field.id, yes.checked)'
                  [checked]='yesChecked[index]'
                />
                <label>{{ 'yes' | translate }}</label>
              </div>
              <div class='checkbox-block'>
                <input
                  #no
                  nz-input
                  type='checkbox'
                  class='primary-input'
                  (change)='toggleNo(index, field.id, no.checked)'
                  [checked]='noChecked[index]'
                />
                <label>{{ 'no' | translate }}</label>
              </div>
            </div>

            <textarea
              rows='3'
              [ngStyle]="{'display': yesChecked[index] ? 'block' : 'none'}"
              nz-input
              maxlength='400'
              class='primary-input'
              [formControlName]="'response_' + field.id"
              placeholder='Your answer'
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <ng-container *ngSwitchCase='3'>
        <nz-form-item class='mb-3'>
          <div class='label-block'>
            <div>{{index + 1}}.</div>
            <div>{{field.questionTranslate|translate}}</div>
          </div>
          <nz-form-control [nzErrorTip]="errors['response_'+field.id]">
            <div class='checkbox-block'>

              <input
                nz-input
                type='checkbox'
                [id]="'response_' + field.id"
                class='primary-input'
                [formControlName]="'response_' + field.id"
              />
              <label
              >{{ 'i-confirm-that-i-am-the-owner' | translate }}</label
              >
            </div>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </ng-container>
  </ng-container>


  <button type='submit' class='primary-btn sign-up'>
    {{ nextButton | translate }}
  </button>

  <div class='primary-flex-start login mt-4' *ngIf='showLogin'>
    <p class='info text'>{{ 'are-you-already-registered' | translate }}</p>
    <button
      type='button'
      class='primary-invisible text info-btn main-color'
      (click)='login()'
    >
      {{ 'please-login' | translate }}
    </button>
  </div>
</form>
