/**
 * TakeProfitTrader.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MarketType = 0 | 1;

export const MarketType = {
  NUMBER_0: 0 as MarketType,
  NUMBER_1: 1 as MarketType,
};
