import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LoginForgotPasswordComponent } from '../login-forgot-password/login-forgot-password.component';
import { PopUpSize } from 'common-pop-up';

@Component({
	selector: 'lib-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	@Input()
	public show = false;

	@Input()
	public isGoToDashboard = false;

	@Input()
	public set isShowResendConfirmEmail(isShowResendConfirmEmail: boolean) {
		if (this.isShowResendConfirmEmail === isShowResendConfirmEmail) {
			return;
		}

		this._isShowResendConfirmEmail = isShowResendConfirmEmail;
		this.isShowResendConfirmEmailChange.emit(isShowResendConfirmEmail);
	}

	public get isShowResendConfirmEmail(): boolean {
		return this._isShowResendConfirmEmail;
	}

	@Input()
	public set isExpiredConfirmEmail(isExpiredConfirmEmail: boolean) {
		if (this.isExpiredConfirmEmail === isExpiredConfirmEmail) {
			return;
		}

		this._isExpiredConfirmEmail = isExpiredConfirmEmail;
		this.isExpiredConfirmEmailChange.emit(isExpiredConfirmEmail);
	}

	public get isExpiredConfirmEmail(): boolean {
		return this._isExpiredConfirmEmail;
	}

	@Output()
	public readonly isExpiredConfirmEmailChange = new EventEmitter<boolean>();

	@Output()
	public readonly isShowResendConfirmEmailChange = new EventEmitter<boolean>();

	@Output()
	public readonly showChange = new EventEmitter<boolean>();

	@Output()
	public readonly isLogin = new EventEmitter<boolean>();

	@Output()
	public readonly nativeOpen = new EventEmitter<boolean>();

	@Output()
	public readonly showSignUp = new EventEmitter<boolean>();

	@ViewChild(LoginForgotPasswordComponent)
	public loginForgotPassComponent: object;

	public readonly PopUpSize = PopUpSize;

	public showForgotPassword = false;
	public showLoginInfoSendmail = false;
	public showLoginResetPassword = false;

	private _isShowResendConfirmEmail = false;
	private _isExpiredConfirmEmail = true;

	public close(): void {
		this.show = false;
		this.nativeOpen.emit(this.show);
	}

	public signUp(): void {
		this.showSignUp.emit(true);
		this.close();
	}

	public emitLogin(event: boolean): void {
		this.isLogin.emit(event);
	}

	public toggleForgotPassword(): void {
		this.showForgotPassword = !this.showForgotPassword;
	}

	public closeReset(): void {
		this.showForgotPassword = false;
		this.showLoginInfoSendmail = true;
	}

	public showResendConfirmEmail(): void {
		this.isShowResendConfirmEmail = true;
	}
}
