import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IBodyResetPasswordWithCurrentPassword } from 'repository';
import { Observable } from 'rxjs/internal/Observable';
import { IBodyResetPassword } from '../interfaces/ibody-reset-password.inrterface';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(private readonly _http: HttpClient) {}

  sendMailForgotPassword(body: { email: string }): Observable<void> {
    return this._http.post<void>(
      `${environment.identityUrl}/accounts/forgot-password`,
      body
    );
  }

  resetPassword(body: IBodyResetPassword): Observable<void> {
    return this._http.post<void>(
      `${environment.identityUrl}/accounts/reset-password`,
      body
    );
  }
  // TODO: fix any
  // tslint:disable-next-line:no-any
  resetPasswordWithCurrentPassword(body: IBodyResetPasswordWithCurrentPassword): Observable<any> {
    return this._http.put(
      `${environment.identityUrl}/api/users/change-password`,
      body
    );
  }

  verificationResetToken(token: string): Observable<boolean> {
    return this._http.post<boolean>(
      `${environment.identityUrl}/accounts/verify-token?token=${token}`,
      {}
    );
  }

  getUrlVariable(name: string | RegExpExecArray) {
    /* tslint:disable */
    if (
      (name = new RegExp(
        '[?&]' + encodeURIComponent(name as string) + '=([^&]*)'
      ).exec(location.search))
    )
      return decodeURIComponent(name[1]);
    /* tslint:enable */
  }
}
