import { Inject, Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { FileLoader } from '../file.loader';
import { ROOT } from '../root';
import * as fs from 'fs';

@Injectable()
export class ServerFileLoader extends FileLoader {
  constructor(transferState: TransferState, @Inject(ROOT) root: string) {
    super(transferState, root);
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  loadFile(file: string): Observable<any> {
    // TODO: fix any
    // tslint:disable-next-line:no-any
    const data = JSON.parse(fs.readFileSync(this.getPath(file)) as any);

    this.transferState.set(makeStateKey(file), data);

    return of(data);
  }
}
