<div class='date-block'>
  <div class='months'>
    <nz-select class='select-block'
               id='month' [formControl]='month' (ngModelChange)='onMonthOrYearChange()' nzPlaceHolder='Month'>
      <nz-option *ngFor='let month of months' [nzValue]='month.value' [nzLabel]='month.name|translate'></nz-option>
    </nz-select>
  </div>

  <div class='days'>
    <nz-select class='select-block' id='date' [formControl]='date' (ngModelChange)='combineDate()' nzPlaceHolder='Day'>
      <nz-option *ngFor='let day of days' [nzValue]='day' [nzLabel]='day'></nz-option>
    </nz-select>
  </div>

  <div class='years'>
    <nz-select id='year' class='select-block' [formControl]='year' (ngModelChange)='onMonthOrYearChange()' nzPlaceHolder='Year'>
      <nz-option *ngFor='let year of years' [nzValue]='year' [nzLabel]='year'></nz-option>
    </nz-select>
  </div>
</div>
