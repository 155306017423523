import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponentsService } from '../base-components.service';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  NotificationService,
  TypeMessage
} from 'repository';
import { environment } from '@env/environment';

@Component({
  selector: 'lib-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  showLoader = false;
  @Input() show: boolean;
  @Output() nativeOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  errors: ErrorsMap<string> = {};
  public readonly environmentCaptchaToken = environment.captchaToken;

  readonly errorMessages: ErrorsMap<IErrorMessage> = {
    name: {
      required: 'Name is required'
    },
    email: {
      required: 'Email is required'
    },
    message: {
      required: 'Message is required'
    }
  };

  private _reCaptchaToken: null | string = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _baseComponentsService: BaseComponentsService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _notification: NotificationService,
    private readonly _errorHttpService: ErrorHttpService
  ) {
  }

  ngOnInit(): void {
    this.show = false;

    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      message: [null, [Validators.required]]
    });
  }

  close(): void {
    this.show = false;
    this.nativeOpen.emit(this.show);
  }

  resolved($event: string) {
    this._reCaptchaToken = $event;
  }

  // tslint:disable-next-line:no-any
  errored($event: any) {
    console.warn(`reCAPTCHA error encountered`, $event);
  }

  submit(): void {
    const form = this.form;

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (form.invalid) return;

    if (!this._reCaptchaToken) {
      this._notification.showBlankNotification(
        'Captcha must be solved',
        TypeMessage.ERROR
      );

      return;
    }

    this.showLoader = true;

    this._baseComponentsService.sendContactUs({ ...form.value, token: this._reCaptchaToken }).subscribe(
      () => {
        this._notification.showBlankNotification(
          'The message is successfully sent',
          TypeMessage.SUCCESS);
        this.showLoader = false;

        this.close();
      },
      // TODO: fix any
      // tslint:disable-next-line:no-any
      (error: any) => {
        this.showLoader = false;

        this._errorHttpService.showMessage(error);
      }
    );
  }
}
