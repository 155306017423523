<div class="container-flag" *ngIf="!menu">
  <img
    class="lang-change"
    [ngClass]="{ 'lang-change-active': setActiveEn }"
    (click)="setLang('en')"
    [src]="usaFlag"
    alt="es"
  />

  <img
    [src]="spainFlag"
    class="lang-change"
    [ngClass]="{ 'lang-change-active': setActiveEs }"
    (click)="setLang('es')"
    alt="es"
  />
</div>

<div class="container-flag-menu" *ngIf="menu">
  <img
    class="lang-change-menu"
    [ngClass]="{ 'lang-change-menu-active': setActiveEn }"
    (click)="setLang('en')"
    [src]="usaFlag"
    alt="es"
  />

  <img
    [src]="spainFlag"
    class="lang-change-menu"
    [ngClass]="{ 'lang-change-menu-active': setActiveEs }"
    (click)="setLang('es')"
    alt="es"
  />
</div>
