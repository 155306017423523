import { APP_INITIALIZER } from '@angular/core';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { User } from './user';

export function initializeUser(user: User) {
  return (): Promise<void> => {
    return user.init();
  };
}

@NgModule()
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [
        User,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeUser,
          deps: [User],
          multi: true,
        },
      ],
    };
  }
}
