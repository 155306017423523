<form [formGroup]="form" class="mt-3">
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.CcNumber">
      <nz-form-label
        ><span class="text-white">{{
          'card-number' | translate
        }}</span></nz-form-label
      >
      <input
        nz-input
        type="text"
        class="primary-input"
        formControlName="CcNumber"
        placeholder="CcNumber"
        maxlength="16"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="errors.CcExp" class="form-block">
      <nz-form-label
        ><span class="text-white">{{
          'date-expiration' | translate
        }}</span></nz-form-label
      >
      <div class="date-block">
        <input
          type="text"
          formControlName="CcExpMonth"
          placeholder="Month"
          class="primary-input"
          [maxLength]="2"
          [minlength]="2"
          nz-input
        />
        <span>/</span>
        <input
          type="text"
          formControlName="CcExpYear"
          placeholder="Year"
          class="primary-input"
          [maxLength]="2"
          [minlength]="2"
          nz-input
        />
      </div>
    </nz-form-control>
  </nz-form-item>
</form>
