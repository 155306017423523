import { PaymentMethod } from '../../enums';

export const paymentMethodList: PaymentMethod[] = [
  PaymentMethod.CREDIT_CARD,
  PaymentMethod.PAY_PAL,
  PaymentMethod.WALLET,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
  PaymentMethod.CREDIT_CARD_NUVEI,
];
