import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from 'projects/login/src/lib/services/forgot-password.service';

export enum ResetComponent {
  RESET_FORM,
  SUCCESS,
  NOT_VALID_TOKEN,
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showType = ResetComponent.NOT_VALID_TOKEN;

  ResetComponent = ResetComponent;

  constructor(private readonly _forgotPasswordService: ForgotPasswordService) {}

  ngOnInit(): void {
    this._isValidTokenForReset();
  }

  showSuccessfulUpdate(value: boolean): void {
    if (!value) return;

    this.showType = ResetComponent.SUCCESS;
  }

  private _isValidTokenForReset(): void {
    const code = this._forgotPasswordService.getUrlVariable('code');

    this._forgotPasswordService
      .verificationResetToken(code)
      .subscribe((res) => {
        if (res) {
          this.showType = ResetComponent.RESET_FORM;
        }
      });
  }
}
