import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-level-system-line',
  templateUrl: './level-system-line.component.html',
  styleUrls: ['./level-system-line.component.scss'],
})
export class LevelSystemLineComponent {
  @Input() top: string;
  @Input() tier: number;
  @Input() currentTier: number;
  @Input() currentLightning: string;

  lineBlock = '/assets/rewards/tier/line.svg';

  lineDefaultBlock = '/assets/rewards/tier/default.svg';
  lineActiveBlock = '/assets/rewards/tier/active.svg';
  linkDot = this.lineDefaultBlock;

  setMouseEnter() {
    this.linkDot = this.lineActiveBlock;
  }

  setMouseOut() {
    this.linkDot = this.lineDefaultBlock;
  }
}
