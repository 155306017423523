export enum SubscriptionStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DISABLED = 'disabled',
  STOPPED = 'stopped',
  FREE = 'free',
  ON_HOLD = 'OnHold',
}

export enum SubscriptionStatusNumber {
  ACTIVE,
  PENDING,
  DISABLED,
  FREE,
  ON_HOLD,
}
