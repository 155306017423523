import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import disclaimerContent from '../../../../../src/data/disclaimer.json';
import networkLinks from '../../../../../src/data/social-networks.json';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

interface INetworksLinks {
  type: string;
  link: string;
  source: string;
}

interface IDisclaimer {
  title: string;
  paragraphs: string[];
}

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  networks: INetworksLinks[] = networkLinks;
  disclaimer: IDisclaimer[] = disclaimerContent;

  showContactUs= false;
  isShowTerms: boolean;
  showDisclaimer = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
    let getUrl;
    if (isPlatformBrowser(this._platformId)) {
      getUrl = document.URL.split('/');
      if (getUrl[getUrl?.length - 1] === 'risk-disclosure') {
        this.showDisclaimer = false;
      }
    }
  }

  ngOnInit(): void {
    this.showContactUs = false;
    if (isPlatformBrowser(this._platformId)) {
      this.isShowTerms = !window.location.href.includes('/terms');
    }
  }

  onScrollToUp(): void {
    if (isPlatformBrowser(this._platformId)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  toggleShowContactUs(): void {

    if (isPlatformBrowser(this._platformId)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }

    this.showContactUs = !this.showContactUs;
  }
}
