import { NgModule } from '@angular/core';
import { CookieBannerComponent } from './cookie-banner.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CookieBannerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CookieBannerComponent],
})
export class CookieBannerModule {}
