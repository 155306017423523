<lib-modal-window [isVisible]="isShow" (isClose)="close()">
  <div class="logo-box">
    <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
  </div>

  <form [formGroup]="form" class="primary-form" (ngSubmit)="submit()">
    <div *ngIf="!isExpired; else isExpiredInfo">
      <h3 class="description">
        {{ 'please-enter-the-email' | translate }}
      </h3>
    </div>
    <ng-template #isExpiredInfo>
      <h2 class="title">{{ 'this-link-has-expired' | translate }}</h2>
      <h3 class="description">
        {{ 'please-enter-the-email' | translate }}
      </h3>
    </ng-template>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.email">
        <input
          nz-input
          type="email"
          name="email"
          class="primary-input"
          formControlName="email"
          placeholder="Email"
        />
      </nz-form-control>
    </nz-form-item>

    <button type="submit" class="primary-btn">
      {{ 'resend-link' | translate }}
    </button>
  </form>
</lib-modal-window>
