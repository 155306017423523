import { Component, EventEmitter, Inject, Input, NgZone, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { User } from 'user';
import { ErrorFormService, ErrorsMap, IErrorMessage, NotificationService, TypeMessage, WINDOW } from 'repository';
import { LoginInputModel } from 'identity';
import { TranslateService } from 'localization';

@Component({
  selector: 'lib-form-for-login',
  templateUrl: './form-for-login.component.html',
  styleUrls: ['./form-for-login.component.scss']
})
export class FormForLoginComponent {
  @Input()
  public isGoToDashboard = true;

  @Output()
  public readonly isLogin = new EventEmitter<boolean>();

  @Output()
  public readonly isForgotPassword = new EventEmitter<boolean>();

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    username: {
      required: 'Email is required',
      email: 'Email is required'
    },
    password: {
      required: 'Password is required'
    }
  };

  public readonly form: FormGroup = this._formBuilder.group({
    username: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]]
  });

  public showPassword = false;
  public errors: ErrorsMap<string> = {};
  public passwordValue: string | null = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _router: Router,
    private readonly _user: User,
    private readonly _errorFormService: ErrorFormService,
    private readonly _notification: NotificationService,
    private readonly zone: NgZone,
    private readonly _translate: TranslateService,
    @Inject(WINDOW) private readonly window: Window
  ) {
  }

  public handleSubmit(): void {
    const form = this.form;
    let value = form.value;

    const clientId = environment.clientId;
    const clientSecret = environment.clientSecret;
    const scope = environment.scope;

    value = { ...value, clientId, clientSecret, scope };

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (!form.valid || form.disabled) {
      return;
    }

    this.login(value);
  }

  public forgotPassword(): void {
    this.isForgotPassword.emit(true);
  }

  public passwordIconUrl = (show: boolean): string =>
    `assets/password_kit/${show ? 'show' : 'hide'}.svg`;

  public handlePasswordType = (show: boolean): string =>
    show ? 'text' : 'password';

  private login(value: LoginInputModel): void {
    this._user.login(value).subscribe(
      (res) => {
        this.zone.run(() => {
          if (this.isGoToDashboard) {
            this._router.navigate(['/', 'control-center']).then();
          } else {
            this._router.navigate(['/', 'control-center']).then();
            this.isLogin.emit(true);
          }
        });
      },
      (error) => {
        switch (this._translate.changeLangEventValue.code) {
          case 'es':
            this._notification.showBlankNotification('Se ingresaron credenciales no válidas', TypeMessage.ERROR);
            break;
          default:
            this._notification.showBlankNotification('Invalid credentials entered', TypeMessage.ERROR);
            break;
        }
      }
    );
  }
}
