import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'repository';

@Component({
  selector: 'lib-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  showBanner = 0;
  private showCookieBannerItem = 'showCookieBanner';

  constructor(private readonly _localStorage: LocalStorage) {}

  ngOnInit(): void {
    this.showBanner = +this._localStorage.getItem(this.showCookieBannerItem);
  }

  reject() {
    this._localStorage.setItem(this.showCookieBannerItem, String(2));
    this.showBanner = 2;
  }

  accept() {
    this._localStorage.setItem(this.showCookieBannerItem, String(1));
    this.showBanner = 1;
  }
}
