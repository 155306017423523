export enum Payment {
  STRIPE,
  PAY_PAL,
  WALLET,
  Account,
  System,
  NMI,
  NmiTest,
  StripeSandbox,
  CreditBank,
  Nuvei,
}
