<div class="block-banner" *ngIf="showBanner == 0">
  <p>{{ 'cookie-title' | translate }}</p>
  <div class="block-button">
    <button class="button-reject" (click)="reject()">
      {{ 'reject-all' | translate }}
    </button>
    <button class="button-accept" (click)="accept()">
      {{ 'accept' | translate }}
    </button>
  </div>
</div>
