import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PopUpSize } from 'common-pop-up';
import { User } from 'user';
import { IBaseStep } from '../base-step/base-step';
import { ConfirmEmailComponent } from '../confirm-email/confirm-email.component';
import { FormForSignUpComponent } from '../form-for-sign-up/form-for-sign-up.component';

@Component({
  selector: 'lib-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  @ViewChildren('step')
  public steps: QueryList<IBaseStep> = new QueryList();

  @Input()
  public show: boolean;

  @Output()
  public readonly showChange = new EventEmitter<boolean>();

  @Output()
  public readonly showLogin = new EventEmitter<boolean>();

  public userId: number;
  public readonly PopUpSize = PopUpSize;
  public tabIndex = 0;
  public isLoading = false;

  public isShowResendConfirmEmail = false;

  constructor(private readonly _user: User) {
    this.show = false;
    this.userId = this._user.id;
  }

  nextStep(): void {
    this.tabIndex++;
  }

  login(): void {
    this.showLogin.emit(true);
  }

  close(): void {
    this.show = false;
    this.showChange.emit(this.show);
    this.tabIndex = 0;
  }
}
