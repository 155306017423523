import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISuccessResponse } from 'repository';

@Injectable({
  providedIn: 'root'
})
export class ConfirmEmailService {

  constructor(
      private readonly http: HttpClient,
  ) {
  }

  public resendConfirmEmail(email: string): Observable<ISuccessResponse> {
    return this.http.put<ISuccessResponse>(`${environment.identityUrl}/api/users/confirm-email/resend`, { email });
  }
}
