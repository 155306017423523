import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-modal-window',
  templateUrl: 'modal-window.component.html',
  styleUrls: ['modal-window.component.scss'],
})
export class ModalWindowComponent {
  @Input() isVisible = false;
  @Input() isLoading = false;
  @Input() showClose = false;
  @Input() showCloseIcon = true;

  @Output()
  public readonly isClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleCancel(): void {
    if (!this.showCloseIcon) {
      return;
    }

    this.isClose.emit(false);
    this.isVisible = false;
  }
}
