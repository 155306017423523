<div style="position: relative">
  <img [src]="lineBlock" alt="line" />
  <img
    [ngStyle]="{ top: top }"
    class="line-block-dot"
    (mouseenter)="setMouseEnter()"
    (mouseout)="setMouseOut()"
    [src]="linkDot"
    alt="line"
    nz-tooltip
    [nzTooltipTitle]="titleTemplate"
    [nzTooltipColor]="'#3B4146'"
  />
</div>
<ng-template #titleTemplate>
  <lib-tier-tooltip
    [tier]="tier"
    [currentLightning]="currentLightning"
    [currentTier]="currentTier"
  ></lib-tier-tooltip>
</ng-template>
