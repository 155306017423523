import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IList, IPlatforms, LocalStorage, PlatformTypeId } from 'repository';

@Component({
  selector: 'lib-select-trading-platform',
  templateUrl: './select-trading-platform.component.html',
  styleUrls: ['./select-trading-platform.component.scss'],
})
export class SelectTradingPlatformComponent implements OnInit {
  @Output() selectPlatformEvent = new EventEmitter<IPlatforms>();

  platform = PlatformTypeId;
  setEnd = 3;
  seeMore = true;

  platformList: IPlatforms[] = [
    {
      id: this.platform.CQG,
      title: 'CQG Data Feed',
      subTitle: 'Platforms Available',
      recommended: true,
      items: [
        {
          icon: '/assets/platformNewImg/NinjaTrader.png',
          label: 'NinjaTrader',
        },
        {
          icon: '/assets/platformNewImg/tradingview.png',
          label: 'tradingview',
        },
        { icon: '/assets/platformNewImg/Tradovate.png', label: 'Tradovate' },
      ],
    },
    {
      id: this.platform.Rithmic,
      title: 'Rithmic Data Feed',
      subTitle: 'Platforms Available',
      recommended: false,
      items: [
        { icon: '/assets/platformNewImg/RTrader.png', label: 'RTrader' },
        { icon: '/assets/platformNewImg/quantower.png', label: 'quantower' },
        { icon: '/assets/platformNewImg/motivewave.png', label: 'motivewave' },
        { icon: '/assets/platformNewImg/bookmap.png', label: 'bookmap' },
        { icon: '/assets/platformNewImg/agena.png', label: 'agena' },
        {
          icon: '/assets/platformNewImg/SierraChart.png',
          label: 'SierraChart',
        },
        {
          icon: '/assets/platformNewImg/multicharts.png',
          label: 'multicharts',
        },
        {
          icon: '/assets/platformNewImg/JigsawTrader.png',
          label: 'JigsawTrader',
        },
        { icon: '/assets/platformNewImg/ATASTrader.png', label: 'ATASTrader' },
        { icon: '/assets/platformNewImg/eSignal.png', label: 'eSignal' },
        { icon: '/assets/platformNewImg/InvestorRT.png', label: 'InvestorRT' },
        {
          icon: '/assets/platformNewImg/TradeNavigator.png',
          label: 'TradeNavigator',
        },
        { icon: '/assets/platformNewImg/volfix.png', label: 'volfix' },
        {
          icon: '/assets/platformNewImg/PhotonTrader.png',
          label: 'PhotonTrader',
        },
        { icon: '/assets/platformNewImg/scalp.png', label: 'scalp' },
        { icon: '/assets/platformNewImg/ATAS.png', label: 'ATAS' },
      ],
    },
  ];
  selectedPlatform: number;

  constructor(private readonly _localStorage: LocalStorage) {}

  showMore(list: IList[]): void {
    if (this.setEnd === 3) {
      this.setEnd = list.length;
      this.seeMore = false;
    } else {
      this.setEnd = 3;
      this.seeMore = true;
    }
  }

  ngOnInit(): void {
    this.selectPlatform(this.platformList[0]);
  }

  selectPlatform(platform: IPlatforms): void {
    this.selectedPlatform = platform.id;
    this.selectPlatformEvent.emit(platform);
  }
}
