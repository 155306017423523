import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopUpSize } from 'common-pop-up';
import { ForgotPasswordService } from '../services/forgot-password.service';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  NotificationService,
  TypeMessage
} from 'repository';
import { environment } from '@env/environment';
import { TranslateService } from 'localization';

@Component({
  selector: 'lib-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss']
})
export class LoginForgotPasswordComponent {
  form: FormGroup;
  errors: ErrorsMap<string> = {};
  readonly errorMessages: ErrorsMap<IErrorMessage> = {
    email: {
      required: 'Email is required'
    }
  };

  @Input() show: boolean;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showInfoSendMail: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly environmentCaptchaToken = environment.captchaToken;

  PopUpSize = PopUpSize;
  private _reCaptchaToken: null | string = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _forgotPasswordService: ForgotPasswordService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _errorHttpService: ErrorHttpService,
    private readonly _notificationService: NotificationService,
  ) {
    this.show = false;
    this.form = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  resolved($event: string) {
    this._reCaptchaToken = $event;
  }

  // tslint:disable-next-line:no-any
  errored($event: any) {
    console.warn(`reCAPTCHA error encountered`, $event);
  }

  close(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  ngSubmit(): void {
    const form = this.form;

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (form.invalid) return;

    if (!this._reCaptchaToken) {
      this._notificationService.showBlankNotification(
        'Captcha must be solved',
        TypeMessage.ERROR
      );

      return;
    }

    this._forgotPasswordService.sendMailForgotPassword({ ...form.value, token: this._reCaptchaToken }).subscribe(
      () => {
        this.showInfoSendMail.emit(true);
        this.close();
      },
      () => {
        this.showInfoSendMail.emit(true);
        this.close();
      }
    );
  }
}
