// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  sentryDsn: 'https://b103b03a0a704be293d98897640390f9@sentry.resadsoft.com/11',
  version: env.npm_package_version + '-dev',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  identityUrl: './identity',
  accountsUrl: './accounts',
  /*accountsUrl: './accounts-tradovate',*/
  storeUrl: './store',
  paymentsUrl: './payments',
  spreedlyUrl: '/spreedly',
  spreedlyPayments: './SpreedlyPayments',
  promocodesUrl: './promocodes',
  bannersUrl: './api/banners',
  agreementsUrl: './agreements',
  quotesUrl: './quotes',
  notificationsUrl: './notifications',
  clientId: 'webclient',
  clientSecret: 'webclientSecret',
  scope:
    'openid profile email offline_access take-profit-store.api take-profit-accounts.api',
  stripe_key:
    'pk_test_51J0YKyBC8La3AgWwWMBdWNcCrxwMDhQ24jgM2dqsZOqznWv0GDce8QT8qL3w4g3dK4ZnVqGI6jQAE8tHO2L5KSxx00UY6RHN0F',
  plaid_clientId: '650a4675bd8162001bb39b95',
  plaid_secret: '82d6546ac3cb0450b6fa16bd0b3a74',
  plaid_template: 'flwtmp_aBY3x1c78Ckkkt',
  stripe_arm_gateway: '7O23nZabQUEx3hVr7OB7j2PWQF3',
  spreedly_stripe_apm: 4,
  spreedly_stripe_gatway: 3,
  spreedly_nmi_gatway: 0,
  nuveiMerchantId: '1164287968040039072',
  nuveiMerchantSiteId: '283796',
/*  nuveiMerchantId: '8972195453701179052',
  nuveiMerchantSiteId: '249918',*/
  captchaToken: '6LdwxswpAAAAAJqv6mnTM9DSgD6OEU-RykjQ-1J4',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
