import { Component, Input } from '@angular/core';
import { ICandleItem } from 'repository';

@Component({
  selector: 'lib-level-system-chart',
  templateUrl: './level-system-chart.component.html',
  styleUrls: ['./level-system-chart.component.scss'],
})
export class LevelSystemChartComponent {
  @Input() styleBlock: ICandleItem;
  @Input() current: number;
  dot = '/assets/rewards/tier/dot.svg';
  info = '/assets/rewards/tier/info.svg';
  lightning1 = '/assets/rewards/tier/lightning-1.svg';
  lightning2 = '/assets/rewards/tier/lightning-2.svg';
  lightning3 = '/assets/rewards/tier/lightning-3.svg';
  lightning4 = '/assets/rewards/tier/lightning-4.svg';
  lightning5 = '/assets/rewards/tier/lightning-5.svg';

  tier = 0;

  get currentLightning(): string {
    if (this.current >= 0 && this.current <= 9999) {
      this.tier = 0;

      return this.lightning1;
    } else if (this.current >= 10000 && this.current <= 24999) {
      this.tier = 1;

      return this.lightning2;
    } else if (this.current >= 25000 && this.current <= 49999) {
      this.tier = 2;

      return this.lightning3;
    } else if (this.current >= 50000 && this.current <= 99999) {
      this.tier = 3;

      return this.lightning4;
    } else if (this.current >= 100000) {
      this.tier = 4;

      return this.lightning5;
    }
  }
}
