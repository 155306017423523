import { PathNames } from '../enums';
import { IUserCabinetLinks } from '../interfaces';

export const UserCabinetLinks: IUserCabinetLinks[] = [
  { title: 'personal-info', url: PathNames.USER_INFO, notification: 0 },
  { title: 'wallet', url: PathNames.WALLET, notification: 1 },
  { title: 'rewards', url: PathNames.REWARDS, notification: 0 },
  { title: 'credit-bank', url: PathNames.CREDIT_BANK, notification: 0 },
  { title: 'calendar', url: PathNames.CALENDAR, notification: 2 },
  {
    title: 'change-password',
    url: PathNames.USER_PASSWORD,
    notification: 0,
  },
];
