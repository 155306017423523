import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { IValidatePromocodeBody } from '../interfaces/validate-promocode-body.interface';
import { IValidatePromocode } from '../interfaces/validate-promocode.interface';

@Injectable({
  providedIn: 'root',
})
export class PromocodeService {
  constructor(private readonly _http: HttpClient) {}

  verificate(body: IValidatePromocodeBody): Observable<IValidatePromocode> {
    return this._http.post<IValidatePromocode>(
      `${environment.promocodesUrl}/api/Promocodes/validate`,
      body
    );
  }
}
