import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { PopUpSize } from 'common-pop-up';
import { AgreementsService } from '../services/agreements.service';
import { ErrorHttpService } from 'repository';

@Component({
  selector: 'lib-info-go-to-pro',
  templateUrl: './info-go-to-pro.component.html',
  styleUrls: ['./info-go-to-pro.component.scss'],
})
export class InfoGoToProComponent implements OnChanges {
  @Input() show = false;
  @Input() isGoToPro = false;

  @Output() isGoToProChange = new EventEmitter<boolean>();
  @Output() showChange = new EventEmitter<boolean>();
  @Output() goToPayChange = new EventEmitter<boolean>();

  public readonly PopUpSize = PopUpSize;

  constructor(
    private readonly _agreementsService: AgreementsService,
    private readonly _errorHttpService: ErrorHttpService
  ) {}

  ngOnChanges(): void {
    if (!this.isGoToPro) return;

    this._show(true);
  }

  emitGoToPay(): void {
    this.goToPayChange.emit(true);
  }

  close(): void {
    this._show(false);
  }

  closeDouble(): void {
    this.goToPayChange.emit(false);
  }

  private _show(value: boolean): void {
    this.show = value;
    this.showChange.emit(this.show);
  }
}
