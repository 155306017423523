<div class="primary-background" *ngIf="isShow">
  <div class="primary-dialog-popup scroll"
       [ngClass]="'size-'+size"
  >
    <p *ngIf="canClose" class="primary-close" (click)="close()">
      <img src="./assets/close-icon.svg" alt="close" />
    </p>
    <section [ngClass]="{'loading': isLoading}">
      <ng-content>
      </ng-content>
    </section>
    <section id="loading" *ngIf="isLoading" class="primary-dialog-popup primary-flex-center">
      <lib-loading></lib-loading>
    </section>
  </div>
</div>
