export enum CreditTypeEnum {
  FreeTestReset,
  TestAccount12K,
  TestAccount25K,
  TestAccount50K,
  TestAccount75K,
  TestAccount100K,
  TestAccount150K,
  ProAccount50K,
  ProActivationFee,
}
