<div
  class="main-block"
  [ngClass]="{
    'not-active-element': current < styleBlock.start && current < styleBlock.end
  }"
>
  <div
    class="tooltip-block"
    *ngIf="current >= styleBlock.start && current <= styleBlock.end"
    [ngStyle]="{ top: styleBlock.infoBlock }"
  >
    <div class="your-balance" *ngIf="tier > 0">
      {{ 'tier' | translate }} {{ tier }}
    </div>
    <div class="tier-title">
      <span class="current">{{ current | number: '0.0-0' }}</span>
      <img
        [src]="info"
        *ngIf="tier > 0"
        alt="info"
        nz-tooltip
        [nzTooltipTitle]="titleTemplate"
        [nzTooltipColor]="'#3B4146'"
      />
    </div>
    <div class="your-balance">{{ 'your-tier-balance' | translate }}</div>
    <div class="lightning">
      <img [src]="currentLightning" alt="lightning" />
    </div>
    <div class="dot">
      <img [src]="dot" alt="dot" />
    </div>
  </div>
  <div
    class="candle-block-1"
    [ngClass]="[styleBlock.border, styleBlock.class]"
    [ngStyle]="styleBlock.main"
  ></div>
  <div
    class="candle-block-2"
    [ngStyle]="styleBlock.candle"
    [ngClass]="[styleBlock.class]"
  ></div>
</div>

<ng-template #titleTemplate>
  <lib-tier-tooltip
    [tier]="tier"
    [currentTier]="tier"
    [currentLightning]="currentLightning"
  ></lib-tier-tooltip>
</ng-template>
