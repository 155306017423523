import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  // TODO: fix any
  // tslint:disable-next-line:no-any
  private _modals: any[] = [];
  // TODO: fix any
  // tslint:disable-next-line:no-any
  add(modal: any) {
    this._modals.push(modal);
  }

  remove(id: string) {
    this._modals = this._modals.filter((x) => x.id !== id);
  }

  open(id: string) {
    const modal = this._modals.find((x) => x.id === id);
    modal.open();
  }

  close(id: string) {
    const modal = this._modals.find((x) => x.id === id);
    modal.close();
  }
}
