<section class="choose-account-size">
  <ul class="tabs">
    <li
      *ngFor="let m of marketTypes"
      [ngClass]="{ 'active-tab': market === m }"
      class="tabs-item"
    >
      <span *ngIf="m.status" (click)="selectMarket(m)">{{ m.type }}</span>
    </li>
  </ul>

  <div class="details">
    <div class="wrapper">
      <div
        *ngIf="currentSlide != 0"
        class="arrow-to-left-block arrow-to-block"
        (click)="carousel.slickPrev()"
      >
        <img src="./assets/arrow-without-line.svg" alt="" />
      </div>
      <div
        *ngIf="currentSlide != lastSlide"
        class="arrow-to-right-block arrow-to-block"
        (click)="carousel.slickNext()"
      >
        <img src="./assets/arrow-without-line.svg" alt="" />
      </div>

      <ngx-slick-carousel
        class="carousel"
        #carousel
        [config]="slideConfig.getValue()"
        (afterChange)="changeSlide($event)"
      >
        <li
          ngxSlickItem
          *ngFor="let s of sizes; let i = index"
          class="list-item"
          (click)="checkPlan(i)"
          [ngClass]="{ active: activeItemIndex === i }"
        >
          <div *ngIf="s?.discountPrice; else withoutPromotion">
            <p class="percent" *ngIf="s?.discountPrice">
              {{ s?.discountPercent }}%
            </p>
            <p class="amount">
              <span class="amount-price">{{ s.name }}</span>
            </p>
            <span class="divider"></span>
            <p class="amount">
              <span class="amount-per-month">{{
                s.discountPrice.toFixed(2)
              }}</span
              ><span class="amount-month" *ngIf="s.id != 12">
                / {{ '1st-month' | translate }}</span
              >
            </p>
            <p class="amount price">
              <span class="amount-per-month">{{ s.price.toFixed(2) }}</span
              ><span class="amount-month" *ngIf="s.id != 12">
                / {{ 'month' | translate }}</span
              >
            </p>
          </div>
          <ng-template #withoutPromotion>
            <p class="amount">
              <span class="amount-price">{{ s.name }}</span>
            </p>
            <span class="divider"></span>
            <p class="amount">
              <span class="amount-per-month">{{ s.price.toFixed(2) }}</span
              ><span class="amount-month" *ngIf="s.id != 12">
                / {{ 'month' | translate }}</span
              >
            </p>
          </ng-template>
        </li>
      </ngx-slick-carousel>
    </div>
    <ul class="info">
      <li class="info-item">
        <p class="text-field">{{ 'profit-target' | translate }}</p>
        <p class="text-value">${{ size?.profitTarget }}</p>
      </li>
      <li class="info-item">
        <p class="text-field">{{ 'max-position-size' | translate }}</p>
        <p class="text-value">{{ size?.maxPositionSize }} Contracts</p>
      </li>
      <li class="info-item">
        <p class="text-field">{{ 'daily-loss-limit' | translate }}</p>
        <p class="text-value">${{ size?.dailyLossLimit }}</p>
      </li>
      <li class="info-item">
        <p class="text-field">{{ 'EOD-trailing-drawdown' | translate }}</p>
        <p class="text-value">${{ size?.eodTrailingDrawdown }}</p>
      </li>
    </ul>
  </div>
</section>
