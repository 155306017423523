<div class="step" *ngIf="type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION">
  <div>
    <hr [ngClass]="number == 0 ? 'active' : ''" />
    <hr [ngClass]="number == 1 ? 'active' : ''" />
    <hr [ngClass]="number == 2 ? 'active' : ''" />
    <hr [ngClass]="number == 3 ? 'active' : ''" />
    <hr [ngClass]="number == 4 ? 'active' : ''" />
  </div>

  <div>
    <h3 class="title">{{ titleCreateSubscription[number] }}</h3>
  </div>
</div>

<div class="step" *ngIf="type === TypeOfCreateSubscription.RESET_ACCOUNT">
  <div>
    <hr [class]="number == 0 ? 'active' : ''" />
    <hr [class]="number == 1 || number == 2 ? 'active' : ''" />
  </div>

  <div>
    <h3 class="title">{{ titleResetAccount[number] }}</h3>
  </div>
</div>

<div class="step" *ngIf="type === TypeOfCreateSubscription.CHANGE_PAYMENT">
  <div>
    <hr [class]="number == 0 ? 'active' : ''" />
    <hr [class]="number == 1 || number == 2 ? 'active' : ''" />
  </div>

  <div>
    <h3 class="title">{{ titleChangePayment[number] }}</h3>
  </div>
</div>

<div class="step" *ngIf="type === TypeOfCreateSubscription.AGREEMENT_PAYMENT">
  <div>
    <hr [class]="number == 0 ? 'active' : ''" />
    <hr [class]="number == 1 || number == 2 ? 'active' : ''" />
  </div>

  <div>
    <h3 class="title">{{ titleAgreementPayment[number] }}</h3>
  </div>
</div>
