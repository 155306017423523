import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BaseComponentsModule } from 'projects/base-components/src/public-api';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { FormForSignUpComponent } from './form-for-sign-up/form-for-sign-up.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RouterModule } from '@angular/router';
import { CommonPopUpModule } from 'common-pop-up';
import { ResendConfirmEmailComponent } from './resend-confirm-email/resend-confirm-email.component';
import { ModalWindowModule } from 'modal-window';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { Translate } from 'localization';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from 'shared';

@NgModule({
  declarations: [
    SignUpComponent,
    FormForSignUpComponent,
    ConfirmEmailComponent,
    ResendConfirmEmailComponent,
  ],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzFormModule,
    NzPaginationModule,
    NzTabsModule,
    CommonPopUpModule,
    NzMessageModule,
    FormsModule,
    ReactiveFormsModule,
    BaseComponentsModule,
    SlickCarouselModule,
    NzAlertModule,
    ModalWindowModule,
    RouterModule,
    NgxIntlTelInputModule,
    Translate,
    NzInputModule,
    RecaptchaModule,
    SharedModule
  ],
  exports: [SignUpComponent, ResendConfirmEmailComponent],
})
export class SignUpModule {}
