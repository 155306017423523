<div class="email-popup-content">
  <lib-takeprofit-title
    class="tpt-logo"
    *ngIf="type === SuccessfulMessage.PAY_BY_PAY_PAL"
  ></lib-takeprofit-title>
  <img
    *ngIf="info[type]?.image"
    alt=""
    [src]="'/assets/' + info[type]?.image + '.svg'"
  />

  <div>
    <h3 class="title text" *ngIf="info[type]?.title">
      {{ info[type]?.title | translate }}
    </h3>
    <p class="description text" *ngIf="info[type]?.description">
      {{ info[type]?.description | translate }}
    </p>
    <p
      class="description text"
      *ngIf="
        type === SuccessfulMessage.RESET_PRO_ACCOUNT &&
        accountType !== AccountType.EXPRESS
      "
    >
      {{ 'resetAmount' | translate: { resetAmount: 2 - resetAmount } }}
    </p>

    <div class="failed-payment">
      <button
        class="primary-btn cancel-btn"
        *ngIf="info[type]?.cancelButtonText"
        (click)="close()"
      >
        {{ info[type]?.cancelButtonText }}
      </button>
      <button
        class="primary-btn"
        *ngIf="info[type]?.okButtonText"
        (click)="close()"
      >
        {{ info[type]?.okButtonText }}
      </button>
      <a
        *ngIf="urlForSign && info[type]?.linkText"
        class="pro-btn primary-btn primary-sign"
        target="_blank"
        [href]="urlForSign"
      >
        {{ info[type]?.linkText }}
      </a>
    </div>
  </div>
</div>
