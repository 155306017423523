import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'lib-dialog-header',
  templateUrl: 'shared-title-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./shared-title-modal.component.scss'],
})
export class SharedTitleModalComponent {
  @Input() title: string;
  @Input() tooltipHelp: string;

  @Output() closeEvent = new EventEmitter();
  closeImg = '/assets/close-icon.svg';

  closeDialog() {
    this.closeEvent.emit();
  }
}
