export enum SuccessfulMessage {
  PAY_BY_PAY_PAL = 'payByPayPal',
  DEPOSIT_BY_PAY_PAL = 'depositByPayPal',
  RESET_ACCOUNT = 'resetAccount',
  RESET_PRO_ACCOUNT = 'resetProAccount',
  CREATE_NEW_SUBSCRIPTION = 'createNewSubscription',
  AGREEMENT_PAYMENT = 'agreementPayment',
  DEPOSIT = 'deposit',
  CHANGE_PAYMENT = 'changePayment',
  DEPOSIT_IS_PAID = 'depositIsPaid',
  ERROR_DOUBLE_PAYMENT = 'errorDoublePayment',
}
