import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { AccountSizeService } from '@app/services/account-size.service';
import marketType from '../../../../../src/data/market-type.json';
import { User } from 'user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISizes } from 'how-it-works';
import { IAccountSize, IItems, IMarketType, LocalStorage } from 'repository';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export interface ICurrentSlide {
  // tslint:disable-next-line:no-any
  event: any;
  // tslint:disable-next-line:no-any
  slick: any;
  currentSlide: number;
  first: boolean;
  last: boolean;
}

enum ScreenWidth {
  MOBILE_XS = 350,
  MOBILE = 557,
  TABLET = 769,
  DESKTOP = 1441,
}

@Component({
  selector: 'lib-choose-account-size',
  templateUrl: './choose-account-size.component.html',
  styleUrls: ['./choose-account-size.component.scss'],
})
@UntilDestroy()
export class ChooseAccountSizeComponent implements OnInit, OnChanges {
  accountSizes: IItems<IAccountSize>;
  marketTypes: IMarketType[] = marketType;
  sizes: IAccountSize[] = [];
  activeItemIndex: number;
  activeTabIndexId: number;
  selectedTabType: string;
  isShow = true;
  currentSlide = 0;
  lastSlide: number;
  market: IMarketType;
  size: IAccountSize;

  @Input() update: boolean;

  @Output() choose: EventEmitter<{
    id: number;
    marketType: number;
  }> = new EventEmitter<{
    id: number;
    marketType: number;
  }>();

  @Output() chosen = new EventEmitter<{
    chosenIndex: number;
    // TODO: fix any
    // tslint:disable-next-line:no-any
    chosenDetails: any[];
    chosenId: number;
    chosenMarketType: string;
    chosenSizes: ISizes[];
  }>();
  @ViewChild('carousel') public carousel: SlickCarouselComponent;

  slideConfig = new BehaviorSubject({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
  });

  constructor(
    private readonly _accountSizeService: AccountSizeService,
    public readonly user: User,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _localStorage: LocalStorage,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
    this.activeItemIndex = 0;
    this.activeTabIndexId = 0;
    this.accountSizes = {
      items: [],
      count: 0,
      total: 0,
    };
  }

  submit(): boolean {
    return true;
  }

  ngOnInit(): void {
    this._getSubscriptionsList();
    if (isPlatformBrowser(this._platformId)) {
      this._slideConfigBuilder(window.innerWidth);
    }
  }

  public ngOnChanges(): void {
    this._getSubscriptionsList();
  }

  checkPlan(index: number): void {
    if (index < 0 && this.sizes.length > 0) return;

    if (this.sizes.length <= index) {
      index = 0;
    }

    this.size = this.sizes[index];
    this.activeItemIndex = index;
    this.selectSize(this.size);
    this.changeDetectorRef.detectChanges();
  }

  selectMarket(market: IMarketType): void {
    this.market = market;
  }

  getMarketType(market: IMarketType): void {
    if (!market.status) return;

    this.sizes = this.accountSizes.items.filter(
      (size: IAccountSize) => size.marketType === market.id
    );
    this.activeTabIndexId = market.id;

    if (this.selectedTabType !== market.type) {
      this.selectedTabType = market.type;
      this.activeItemIndex = 0;
    }
    this.changeDetectorRef.detectChanges();
  }

  verifyStatus(): void {
    for (let index: number = this.marketTypes.length - 1; index >= 0; index--) {
      const sizeList: IAccountSize[] = this.accountSizes.items.filter(
        (size: IAccountSize) => size.marketType === this.marketTypes[index].id
      );
      if (sizeList.length !== 0) {
        this.marketTypes[index].status = true;
        this.getMarketType(this.marketTypes[index]);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  selectSize(size: IAccountSize): void {
    this.size = size;

    this._localStorage.setItem('accountSize', JSON.stringify(size));
    this.changeDetectorRef.detectChanges();
  }

  changeSlide(event: ICurrentSlide): void {
    this.currentSlide = +event?.currentSlide;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  private getChangeWidth(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._slideConfigBuilder(window.innerWidth);
    }
  }

  private _slideConfigBuilder(width: number): void {
    let count: number;
    if (width <= ScreenWidth.MOBILE_XS) {
      count = 1;
      this.lastSlide = 4;
    } else if (width <= ScreenWidth.MOBILE) {
      count = 2;
      this.lastSlide = 4;
    } else if (width <= ScreenWidth.TABLET) {
      count = 2;
      this.lastSlide = 3;
    } else {
      count = 3;
      this.lastSlide = 3;
    }

    this.slideConfig.next({
      slidesToShow: count,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
    });
  }

  private _getSubscriptionsList(): void {
    const user = this.user;
    const referralCode = user.referralCode;
    const subscriptions = this.subscriptionsList;
    const expressAccountId = 12;

    let params = {};
    if (user.status === 0 && user.referralCode) {
      params = { referralCode };
    }
    this._accountSizeService
      .getSubscriptionList(params)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          res.items = res.items
            .map((value) => {
              if (value.id === expressAccountId) {
                value.discountPrice = 0;
              }
              value.discountPercent = Math.round(
                (1 - value.discountPrice / value.price) * 100
              );

              return value;
            })
            .filter((item) => subscriptions.includes(item.id));
          this.accountSizes = res;

          this._loadList();
          this.changeDetectorRef.detectChanges();
        },
        (error) => console.error(error)
      );
  }

  private _loadList(): void {
    const infoAccountSize: IAccountSize = JSON.parse(
      this._localStorage.getItem('accountSize')
    );
    const market = this.marketTypes[0];
    this.verifyStatus();
    this.getMarketType(market);
    this.selectMarket(market);

    this.size = null;
    let index = 0;

    if (!this.sizes && this.sizes.length < 2) return;

    if (infoAccountSize?.price) {
      this.size = infoAccountSize;
      index = this.sizes.findIndex((item) => item.id === this.size.id);
    } else {
      this.size = this.sizes[index];
    }

    this.checkPlan(index);
    this.changeDetectorRef.detectChanges();
  }

  private get subscriptionsList(): number[] {
    const baseList = [2, 3, 4, 5, 6];
    const dayStartId = 99;
    const dayEndId = 99;
    const startTime = 4;
    const endTime = 4;

    if (
      (new Date().getUTCDay() >= dayStartId &&
        new Date().getUTCHours() >= startTime) ||
      (new Date().getUTCDay() === dayEndId &&
        new Date().getUTCHours() < endTime)
    ) {
      baseList.push(12);
    }

    return baseList;
  }
}
