import { NgModule } from '@angular/core';
import { HeaderUserInfoComponent } from './header-user-info/header-user-info.component';
import { HeaderUserInfoMenuComponent } from './header-user-info-menu/header-user-info-menu.component';
import { CommonModule } from '@angular/common';
import { WalletNotificationModule } from 'wallet-notification';
import { BaseComponentsModule } from 'base-components';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ModalModule } from '@app/_modal';
import { RouterModule } from '@angular/router';
import { Translate } from 'localization';
import { ChangeLangModule } from 'change-lang';
import { DialogTitleModule } from 'dialog-title';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddNewAffiliateCodeComponent } from '../dialog/add-new-affilfiate-code/add-new-affiliate-code.component';
import { LoaderModule } from 'loader';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmAffiliateWindowComponent } from '../dialog/confirm-affiliate-window/confirm-affiliate-window.component';
import { ExistAffiliateWindowComponent } from '../dialog/exist-affiliate-window/exist-affiliate-window.component';
import { PendingAffiliateWindowComponent } from '../dialog/pending-affiliate-window/pending-affiliate-window.component';
import { SharedModule } from 'shared';

@NgModule({
  declarations: [
    HeaderUserInfoComponent,
    HeaderUserInfoMenuComponent,
    AddNewAffiliateCodeComponent,
    ConfirmAffiliateWindowComponent,
    ExistAffiliateWindowComponent,
    PendingAffiliateWindowComponent
  ],
  imports: [
    CommonModule,
    WalletNotificationModule,
    BaseComponentsModule,
    NzDropDownModule,
    ModalModule,
    RouterModule,
    Translate,
    ChangeLangModule,
    DialogTitleModule,
    NgxIntlTelInputModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,

    MatDialogModule,
    SharedModule
  ],
  exports: [HeaderUserInfoComponent],
})
export class UserInfoModule {}
