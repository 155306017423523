export enum SubscriptionSize {
  reset = 0,
  $12K = 1,
  $25K = 2,
  $50K = 3,
  $75K = 4,
  $100K = 5,
  $150K = 6,
  $25K_PLUS = 7,
  $50K_PLUS = 8,
  $75K_PLUS = 9,
  $100K_PLUS = 10,
  $150K_PLUS = 11,
  $130_PRO_FEE = 130,
}

export enum SubscriptionSizeNumber {
  $25K = 25000,
  $50K = 50000,
  $75K = 75000,
  $100K = 100000,
  $150K = 150000,
}
export enum SubscriptionSizePrice {
  $25K = 449,
  $50K = 649,
  $75K = 799,
  $100K = 999,
  $150K = 1499,
}
