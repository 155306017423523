/**
 * TakeProfitTrader.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ActionType = 0 | 1;

export const ActionType = {
  NUMBER_0: 0 as ActionType,
  NUMBER_1: 1 as ActionType,
};
