<div class="promocode">
  <div class="primary-space-between">
    <p class="label-info">{{ 'promocode' | translate }}</p>
    <p [ngClass]="!isShowForm ? 'add' : 'none'" (click)="addForm()">
      {{ 'add' | translate }}
    </p>
    <p [ngClass]="isShowForm ? 'remove' : 'none'" (click)="closeForm()">
      {{ 'close' | translate }}
    </p>
  </div>
  <form
    class="form-container"
    primary-space-between
    *ngIf="isShowForm"
    autocomplete="off"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <nz-form-control [nzErrorTip]="errors.code">
      <input
        type="text"
        id="promocode-form"
        name="code"
        formControlName="code"
      />
    </nz-form-control>
    <button id="promocode-btn" *ngIf="!showLoader">
      {{ 'apply' | translate }}
    </button>
    <div
      *ngIf="showLoader"
      style="position: relative; width: 100px; height: 36px"
    >
      <lib-loading></lib-loading>
    </div>
  </form>
  <hr />
  <div *ngIf="isValidPromocode">
    <div class="primary-space-between">
      <p class="label-info">{{ 'amount' | translate }}</p>
      <p>{{ textPrice }}</p>
    </div>
    <div class="primary-space-between">
      <p class="label-info">{{ 'discount' | translate }}</p>
      <p>
        {{ calculationType === CalculationType.FIXED_AMOUNT ? '$' : ''
        }}{{ discount
        }}{{ calculationType === CalculationType.PERCENTAGE ? '%' : '' }}
      </p>
    </div>
    <div class="primary-space-between">
      <p class="label-info">{{ 'total' | translate }}</p>
      <p class="total">${{ total }}</p>
    </div>
  </div>
  <div *ngIf="!isValidPromocode">
    <div class="primary-space-between">
      <p class="label-info">{{ 'total' | translate }}</p>
      <p class="total">{{ textPrice }}</p>
    </div>
  </div>
  <div
    class="more-info"
    *ngIf="type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION"
  ></div>
</div>
