import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SingletonService {
  showLoader = new BehaviorSubject(false);
  showLoader$ = this.showLoader.asObservable();
}
