<div class="sign-up-nav nav-box">
  <button id="back" *ngIf="showBack" (click)="prev.emit()">
    {{ 'back-big' | translate }}
  </button>
  <button
    id="confirm"
    *ngIf="showConfirm"
    (click)="confirm()"
    [disabled]="disable"
  >
    {{ 'confirm' | translate }}
  </button>
  <button
    id="next"
    *ngIf="showNext"
    [ngClass]="!showBack ? 'full-size' : ''"
    (click)="clickToNext()"
    [disabled]="disable"
  >
    {{ 'next' | translate }}
  </button>
</div>
