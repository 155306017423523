import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IFormSignUp, IUser } from 'repository';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  constructor(private readonly _http: HttpClient) {}

  // TODO: fix any
  // tslint:disable-next-line:no-any
  createUser(body: IFormSignUp): Observable<IUser> {
    // TODO: fix any
    // tslint:disable-next-line:no-any
    return this._http.post<IUser>(`${environment.identityUrl}/api/users`, body);
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  confirmEmail(body: {
    code: string;
    // tslint:disable-next-line:no-any
  }): Observable<{ isSuccess: boolean; error: any }> {
    // TODO: fix any
    // tslint:disable-next-line:no-any
    return this._http.put<{ isSuccess: boolean; error: any }>(
      `${environment.identityUrl}/api/users/confirm-email`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  checkCaptcha(body: { token: string }): Observable<any> {
    // TODO: fix any
    // tslint:disable-next-line:no-any
    return this._http.post<IUser>(
      `${environment.identityUrl}/api/users/captcha`,
      body
    );
  }
}
