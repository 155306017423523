import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { FileLoader } from 'platform-file-loader';
import { Observable, throwError } from 'rxjs';
import { getLocalizationFile } from './file';

@Injectable()
export class ModuleTranslateLoader implements TranslateLoader {
  constructor(@Inject(FileLoader) private readonly fileLoader: FileLoader) {}

  // TODO: fix any
  // tslint:disable-next-line:no-any
  public getTranslation(lang: string): Observable<any> {
    return this.fileLoader.loadFile(getLocalizationFile(lang));
  }
}
