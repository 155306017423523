<div class="form-info">
  <div class="primary-space-between info">
    <p class="text">{{ 'account-size' | translate }}</p>
    <div class="right">{{ accountSize }}</div>
  </div>

  <div class="primary-space-between info">
    <p class="text">{{ 'trading-platform' | translate }}</p>
    <div class="right">{{ tradingPlatform }}</div>
  </div>

  <div class="primary-space-between info">
    <p class="text">{{ 'first-last-name' | translate }}</p>
    <div class="right name">{{ name }}</div>
  </div>

  <div *ngIf="type === TypeOfCreateSubscription.AGREEMENT_PAYMENT">
    <hr />

    <div class="primary-space-between info">
      <p class="text">{{ 'total' | translate }}</p>
      <p class="right">{{ textPrice }}</p>
    </div>
  </div>
</div>
