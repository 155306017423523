<div class='block'>
  <img [src]='icon' alt='success' class='icon-block'>

  <p class='title'>
    {{'you-have-already-submitted'|translate}}
  </p>
  <p class='sub-title'>
    {{'we-usually-get-back'|translate}}
  </p>
  <button class="primary-btn mb-3" style='max-width: 220px' matDialogClose>
    {{ 'ok' | translate }}
  </button>
</div>


