export * from './accounts.service';
import { AccountsService } from './accounts.service';
export * from './countries.service';
import { CountriesService } from './countries.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './subscriptions.service';
import { SubscriptionsService } from './subscriptions.service';
export * from './tradingData.service';
import { TradingDataService } from './tradingData.service';
export * from './tradingPlatforms.service';
import { TradingPlatformsService } from './tradingPlatforms.service';
export * from './userSubscriptions.service';
import { UserSubscriptionsService } from './userSubscriptions.service';
export const APIS = [
  AccountsService,
  CountriesService,
  PaymentsService,
  SubscriptionsService,
  TradingDataService,
  TradingPlatformsService,
  UserSubscriptionsService,
];
