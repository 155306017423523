<div
  *ngIf="!small && walletInfo$ | async"
  class="wallet-notification balance-low"
  [ngClass]="toWallet ? 'to-wallet' : ''"
>
  <p *ngIf="!toWallet" class="text">
    {{ 'wallet-notification-1' | translate }}.
  </p>
  <p *ngIf="toWallet" class="text">
    {{ 'wallet-notification-2' | translate }}
    <a routerLink="/userinfo/wallet">{{ 'to-wallet' | translate }}</a>
  </p>
</div>
<ng-container [ngSwitch]="notification">
  <ng-container *ngSwitchCase="1">
    <ng-container *ngIf="small && walletInfo$ | async as walletInfo">
      <div *ngIf="walletInfo.length > 0" class="wallet-notification-indicator">
        <p>1</p>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <ng-container *ngIf="toCalendar && calendarEvent$ | async as calendarEvent">
      <div
        *ngIf="calendarEvent.length > 0"
        class="wallet-notification-indicator-success"
      >
        <p>{{ calendarEvent.length }}</p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
