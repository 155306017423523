import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from './guards/user.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TransactionGuard } from 'repository';

const routes: Routes = [
  // Fallback when no prior route is matched
  {
    path: '',
    loadChildren: () => import('home-page').then((m) => m.HomePageModule),
  },
  {
    path: 'control-center',
    canActivate: [UserGuard],
    canDeactivate: [TransactionGuard],
    loadChildren: () => import('dashboard').then((m) => m.DashboardModule),
  },

  {
    path: 'calendar',
    canActivate: [UserGuard],
    loadChildren: () => import('calendar').then((m) => m.CalendarModule),
  },

  {
    path: 'login',
    loadChildren: () => import('login-page').then((m) => m.LoginPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('sign-up-page').then((m) => m.SignUpPageModule),
  },

  {
    path: 'userinfo',
    canActivate: [UserGuard],
    loadChildren: () => import('user-cabinet').then((m) => m.UserCabinetModule),
  },

  {
    path: 'unsubscribe',
    loadChildren: () => import('unsubscribe').then((m) => m.UnsubscribeModule),
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
