import { Component, Input } from '@angular/core';
import { ResetComponent } from '@app/reset-password/reset-password.component';
import { PopUpSize } from 'common-pop-up';

@Component({
  selector: 'lib-login-password-successful-update',
  templateUrl: './login-password-successful-update.component.html',
  styleUrls: ['./login-password-successful-update.component.scss'],
})
export class LoginPasswordSuccessfulUpdateComponent {
  @Input() show: boolean;
  @Input() type: ResetComponent;

  ResetComponent = ResetComponent;
  PopUpSize = PopUpSize;

  constructor() {
    this.show = false;
  }
}
