<div class='block'>
  <img [src]='icon' alt='success' class='icon-block'>
  <p class='title'>
    {{'thank-you-for-sending-your-request-to-become-an-affiliate'|translate}}
  </p>
  <p class='sub-title'>{{'one-of-our-admins-will'|translate}}</p>
  <button class="primary-btn mb-3"  style='max-width: 220px'  matDialogClose>
    {{ 'ok' | translate }}
  </button>
</div>


