import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmEmailService } from '../confirm-email.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ErrorFormService,
  ErrorHttpService,
  ErrorsMap,
  IErrorMessage,
  NotificationService,
  TypeMessage
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopUpSize } from 'common-pop-up';
import { TranslateService } from 'localization';
import { Placement } from '@app/enums/norification-placement';

@UntilDestroy()
@Component({
  selector: 'lib-resend-confirm-email',
  templateUrl: './resend-confirm-email.component.html',
  styleUrls: ['./resend-confirm-email.component.scss']
})
export class ResendConfirmEmailComponent {
  @Input()
  public set isShow(isShow: boolean) {
    if (this._isShow === isShow) {
      return;
    }

    this._isShow = isShow;
    this.isShowChange.emit(isShow);
  }

  public get isShow(): boolean {
    return this._isShow;
  }

  @Output()
  public readonly isShowChange = new EventEmitter<boolean>();

  @Input()
  public set isExpired(isExpired: boolean) {
    this._isExpired = isExpired;
  }

  public get isExpired(): boolean {
    return this._isExpired;
  }

  public readonly PopUpSize = PopUpSize;
  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    email: {
      required: 'Email is required',
      email: 'Email is required'
    }
  };

  public readonly form: FormGroup;

  public errors: ErrorsMap<string> = {};

  private _isShow = false;
  private _isExpired = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly notification: NotificationService,
    private readonly errorFormService: ErrorFormService,
    private readonly errorHttpService: ErrorHttpService,
    private readonly confirmEmailService: ConfirmEmailService,
    private readonly _translate: TranslateService
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  public submit(): void {
    const form = this.form;
    const value = form.value;

    this.errors = this.errorFormService.verifyError(form, this.errorMessages);

    if (!form.valid || form.disabled) {
      return;
    }

    this.resendConfirmEmail(value.email);
  }

  close(): void {
    this.isShow = false;
  }

  private resendConfirmEmail(email: string): void {
    this.confirmEmailService
      .resendConfirmEmail(email)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isShow = false;

          switch (this._translate.changeLangEventValue.code) {
            case 'es':
              this.notification.showBlankNotification(
                'Si el correo electrónico que proporcionaste existe en nuestro sistema, te hemos enviado un mensaje con un enlace para confirmar tu correo electrónico. Por favor, revisa tu bandeja de entrada para continuar.', TypeMessage.SUCCESS,Placement.BOTTOM_RIGHT,10000);
              break;
            default:
              this.notification.showBlankNotification(
                'If the email you provided exists in our system, we\'ve sent a message with a link to confirm your email. Please check your inbox to continue',
                TypeMessage.SUCCESS,Placement.BOTTOM_RIGHT,10000
              );
              break;
          }

        },
        () => {
          this.isShow = false;
          switch (this._translate.changeLangEventValue.code) {
            case 'es':
              this.notification.showBlankNotification(
                'Si el correo electrónico que proporcionaste existe en nuestro sistema, te hemos enviado un mensaje con un enlace para confirmar tu correo electrónico. Por favor, revisa tu bandeja de entrada para continuar.', TypeMessage.SUCCESS,Placement.BOTTOM_RIGHT,10000);
              break;
            default:
              this.notification.showBlankNotification(
                'If the email you provided exists in our system, we\'ve sent a message with a link to confirm your email. Please check your inbox to continue'
                , TypeMessage.SUCCESS,Placement.BOTTOM_RIGHT,10000);
              break;
          }
        }
      );
  }
}
