import { Component } from '@angular/core';
import { IUserCabinetLinks } from '../../interfaces';
import { UserCabinetLinks } from '../../data';

@Component({
  selector: 'lib-user-cabinet-page',
  templateUrl: './user-cabinet-page.component.html',
  styleUrls: ['./user-cabinet-page.component.scss'],
})
export class UserCabinetPageComponent {
  public readonly links: IUserCabinetLinks[] = UserCabinetLinks;
}
