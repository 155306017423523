<lib-modal-window [isVisible]="show" (isClose)="closeDouble()">
  <div class="takeprofit-title">
    <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
  </div>

  <div class="content">
    <div>
      <h2 class="title">{{ 'to-become-a-PRO' | translate }}:</h2>
      <ol>
        <li>{{ 'make-a-lump-sum' | translate }}.</li>
        <li>{{ 'sign-a-contract' | translate }}</li>
        <li>{{ 'sign-a-poa' | translate }}</li>
      </ol>
      <p class="description">
        {{ 'as-the-PRO-account' | translate }}
      </p>
    </div>
    <button class="primary-btn" (click)="emitGoToPay()">
      {{ 'get-started-big' | translate }}
    </button>
  </div>
</lib-modal-window>
