import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAmountPayBody } from './interfaces/amount-pay-body.interface';
import { IChangePaymentMethodBody } from './interfaces/change-payment-method-body.interface';
import { IChangePaymentMethod } from './interfaces/change-payment-method.interface';
import { IPaymentData } from './interfaces/payment-data.interface';
import { IPayment } from './interfaces/payment.interface';
import { CreateSubscriptionHttpService } from './services/create-subscription-http.service';
import { PaymentsService } from './services/payments/payments.service';
import { PaypalService } from './services/payments/paypal.service';
import { StripeService } from './services/payments/stripe.service';
import { Payment } from './enum/payment.enum';
import { WalletService } from './services/payments/wallet.service';
import {
  ErrorHttpService,
  IAccountSize,
  IItems,
  ISubscription,
  NotificationService,
  PaymentMethod,
  TypeMessage
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NmiService } from './services/payments/nmi.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { CreditsService } from './services/payments/credits.service';
import { NuvaiService } from './services/payments/nuvei.service';
import { IGetSessionTokenNuvei, ISessionToken } from './interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { ICountry } from 'additional-info';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CreateSubscriptionService {
  startSpreedly = new BehaviorSubject(false);
  startNuvei = new BehaviorSubject(false);

  constructor(
    private readonly _createSubscriptionHttpService: CreateSubscriptionHttpService,
    private readonly _nmiService: NmiService,
    private readonly _stripeService: StripeService,
    private readonly _paypalService: PaypalService,
    private readonly _errorHttpService: ErrorHttpService,
    private readonly _paymentsService: PaymentsService,
    private readonly _walletService: WalletService,
    private readonly _creditService: CreditsService,
    private readonly _nuvaiService: NuvaiService,
    private readonly _notificationService: NotificationService
  ) {
  }

  getSessionToken(body: IGetSessionTokenNuvei): Observable<ISessionToken> {
    return this._nuvaiService.getSessionToken(body);
  }

  get getNuveiService(): NuvaiService {
    return this._nuvaiService;
  }

  getCountryList(): Observable<IItems<ICountry>> {
    return this._nuvaiService.getCountryList();
  }

  createSubscribe(
    paymentMethod: PaymentMethod,
    promocodeId: number,
    cb: (data: IPaymentData | undefined) => void,
    CcNumber?: number,
    CcExp?: number,
    paymentMethodToken?: string,
    transactionId?: string,
    userPaymentOptionId?: string
  ): void {
    let value = this._getSubscriptionFromLocalStorage();
    const tptSubscriptionId = +value.SubscriptionId;
    const subscriptionCost = +value.SubscriptionCost;

    if (paymentMethod === PaymentMethod.USER_CREDITS) {
      value = { ...value, isExpired: true };
    }

    this._createSubscriptionHttpService.create(value).subscribe(
      (accountId: number) => {
        const paymentService: IPayment = this._choosePaymentMethod(
          paymentMethod
        );
        paymentService
          .createSubscriptions({
            tptSubscriptionId,
            promocodeId,
            info: { accountId },
            CcNumber,
            CcExp,
            amount: subscriptionCost,
            isTest: !environment.production,
            paymentMethodToken,
            transactionId,
            userPaymentOptionId
          })
          .subscribe(
            (data) => {
              if (data) {
                data.subscriptionRes = accountId;
              }
              cb(data);
            },
            (error) => {
              if (CcNumber && CcExp && +error?.error?.errorCode === 4) {
                const errorMsg =
                  'Only promocodes with percentage discounts can be applied.';
                this._notificationService.showBlankNotification(
                  errorMsg,
                  TypeMessage.ERROR
                );
              }
              cb(undefined);
            }
          );
      },
      (error) => {
        this._errorHttpService.showMessage(error);
        cb(undefined);
      }
    );
  }

  resetAccount(
    paymentMethod: PaymentMethod,
    amount: number,
    promocodeId: number,
    accountId: number,
    cb: (data: IPaymentData | undefined) => void,
    CcNumber?: number,
    CcExp?: number,
    paymentMethodToken?: string,
    transactionId?: string,
    userPaymentOptionId?: string
  ): void {
    const value: IAmountPayBody = {
      amount,
      promocodeId,
      info: { accountId },
      CcNumber,
      CcExp,
      isTest: !environment.production,
      paymentMethodToken,
      transactionId,
      userPaymentOptionId
    };

    const paymentService: IPayment = this._choosePaymentMethod(paymentMethod);

    paymentService.resetAccount(value).subscribe(
      (res) => {
        cb(res);
      },
      (error) => {
        this._errorHttpService.showMessage(error);
        cb(undefined);
      }
    );
  }

  payAgreement(
    paymentMethod: PaymentMethod,
    promocodeId: number,
    accountId: number,
    cb: (data: IPaymentData | undefined) => void,
    CcNumber?: number,
    CcExp?: number,
    paymentMethodToken?: string,
    transactionId?: string,
    userPaymentOptionId?: string
  ): void {
    const value: IAmountPayBody = {
      amount: 130,
      promocodeId,
      info: { accountId },
      CcNumber,
      CcExp,
      isTest: !environment.production,
      paymentMethodToken,
      transactionId,
      userPaymentOptionId
    };
    const errorCodeDoublePayment = 3027;
    const paymentService: IPayment = this._choosePaymentMethod(paymentMethod);
    const resError: IPaymentData = { clientSecret: '', paymentIntentId: '' };
    paymentService.payAgreement(value).subscribe(
      (res) => {
        cb(res);
      },
      (error) => {
        if (error?.error?.errorCode === errorCodeDoublePayment) {
          resError.errorDoublePayment = errorCodeDoublePayment;
          cb(resError);
        } else {
          this._errorHttpService.showMessage(error);
          cb(undefined);
        }
      }
    );
  }

  depositWallet(
    paymentMethod: PaymentMethod,
    amount: number,
    cb: (data: IPaymentData | undefined) => void,
    CcNumber?: number,
    CcExp?: number
  ): void {
    const value: IAmountPayBody = { amount, CcNumber, CcExp };

    const paymentService: IPayment = this._choosePaymentMethod(paymentMethod);

    paymentService.depositWallet(value).subscribe(
      (res) => {
        cb(res);
      },
      (error) => {
        this._errorHttpService.showMessage(error);
        cb(undefined);
      }
    );
  }

  changePaymentMethod(
    paymentServiceToChangeTo: Payment,
    accountId: number,
    cb: (data: IChangePaymentMethod | undefined) => void,
    CcNumber?: number,
    CcExp?: number,
    transactionId?: string,
    userPaymentOptionId?: string
  ): void {
    const changePayment: IChangePaymentMethodBody = {
      paymentServiceToChangeTo,
      info: {
        accountId
      },
      CcNumber,
      CcExp,
      transactionId,
      userPaymentOptionId
    };

    this._paymentsService
      .changePaymentMethod(changePayment)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IChangePaymentMethod) => {
          cb(res);
        },
        (error: HttpErrorResponse) => {
          this._errorHttpService.showMessage(error);
          cb(undefined);
        }
      );
  }

  _getSubscriptionFromLocalStorage(): ISubscription {
    const accountSizeD: string = localStorage.getItem('accountSize');
    const tradingPlatform: number = +localStorage.getItem('tradingPlatform');
    const platformType: number = +localStorage.getItem('tradingPlatformGlobal');

    let accountSize: IAccountSize;
    if (accountSizeD) {
      accountSize = JSON.parse(accountSizeD);
    }

    return {
      MarketType: accountSize.marketType,
      SubscriptionId: accountSize.id,
      SubscriptionCost: accountSize.price,
      PlatformType: platformType,
      TradingPlatformId: tradingPlatform
    };
  }

  private _choosePaymentMethod(paymentMethod: PaymentMethod): IPayment {
    let paymentService: IPayment;

    switch (paymentMethod) {
      case PaymentMethod.CREDIT_CARD:
        paymentService = this._stripeService;
        break;
      case PaymentMethod.CREDIT_CARD_NMI:
        paymentService = this._nmiService;
        break;
      case PaymentMethod.PAY_PAL:
        paymentService = this._paypalService;
        break;
      case PaymentMethod.CREDIT_CARD_NUVEI:
        paymentService = this._nuvaiService;
        break;
      case PaymentMethod.WALLET:
        paymentService = this._walletService;
        break;
      case PaymentMethod.USER_CREDITS:
        paymentService = this._creditService;
        break;
      default:
        break;
    }

    return paymentService;
  }
}
