import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  constructor(private readonly _router: Router) {
  }

  back(): void {
    this._router.navigate(['../control-center']).then();

  }
}
