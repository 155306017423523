import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IAccountSize, IItems } from 'repository';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AccountSizeService {
  constructor(private readonly _http: HttpClient) {}

  getSubscriptionList(
    params: { referralCode: string } | {} = {}
  ): Observable<IItems<IAccountSize>> {
    return this._http.get<IItems<IAccountSize>>(
      `${environment.accountsUrl}/api/subscriptions`,
      { params }
    );
  }
}
