import { Component } from '@angular/core';
import { UserCabinetLinks } from '../../data';
import { IUserCabinetLinks } from '../../interfaces';
import { UserCabinetService } from '../user-cabinet.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNewAffiliateCodeComponent } from '../../dialog/add-new-affilfiate-code/add-new-affiliate-code.component';

@Component({
  selector: 'lib-header-user-info-menu',
  templateUrl: './header-user-info-menu.component.html',
  styleUrls: ['./header-user-info-menu.component.scss'],
})
export class HeaderUserInfoMenuComponent {
  public readonly MenuLinks: IUserCabinetLinks[] = UserCabinetLinks;

  constructor(
    private readonly userCabinetService: UserCabinetService,
    private readonly dialog: MatDialog
  ) {}

  public signOut(): void {
    this.userCabinetService.signOut();
  }

  // tslint:disable-next-line:no-any
  public addNewAffiliateCode($event: any) {
    $event.preventDefault();
    this.dialog.open(AddNewAffiliateCodeComponent, {
      maxHeight: '95vh',
      minWidth: '20vw',
      maxWidth: '20vw',
    });
  }
}
