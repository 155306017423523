<lib-modal-window [isVisible]="show" (isClose)="close()">
  <h1 id="title" class="primary-block-by-center">
    {{ 'reset-password' | translate }}
  </h1>
  <h3 id="text" class="primary-block-by-center">
    {{ 'please-enter' | translate }}
  </h3>

  <form
    [formGroup]="form"
    class="primary-form reset-password-form"
    (ngSubmit)="ngSubmit(form.value)"
  >
    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.password">
        <div class="input-container">
          <div class="password-box">
            <input
              nz-input
              [type]="handlePasswordType(showPassword)"
              name="password"
              id="password"
              class="primary-input"
              formControlName="password"
              placeholder="Create Password"
            />
            <div class="indicators">
              <img
                class="show-switch"
                (click)="showPassword = !showPassword"
                [src]="passwordIconUrl(showPassword)"
              />
            </div>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="errors.confirmPassword">
        <div class="input-container">
          <div class="active-group">
            <div class="password-box">
              <input
                nz-input
                [type]="handlePasswordType(showConfirmedPassword)"
                name="confirmPassword"
                id="confirmPassword"
                class="primary-input"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
              />
              <div class="indicators">
                <img
                  class="show-switch"
                  (click)="showConfirmedPassword = !showConfirmedPassword"
                  [src]="passwordIconUrl(showConfirmedPassword)"
                />
              </div>
            </div>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>

    <p [ngClass]="showCloseBtn ? 'spacing' : ''" id="description">
      {{ 'pass-valid' | translate }}
    </p>
    <div [ngClass]="showCloseBtn ? 'actions' : ''">
      <p *ngIf="showCloseBtn" id="cancel" class="primary-btn" (click)="close()">
        {{ 'cancel' | translate }}
      </p>
      <button id="reset" class="primary-btn">
        {{ 'create-pass' | translate }}
      </button>
    </div>
  </form>
</lib-modal-window>
