import { Component, Input } from '@angular/core';
import { TypeOfCreateSubscription } from '../enum/type-of-create-subscription.enum';

@Component({
  selector: 'lib-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent {
  @Input() number: number;
  @Input() type = TypeOfCreateSubscription.CREATE_SUBSCRIPTION;

  TypeOfCreateSubscription = TypeOfCreateSubscription;

  public readonly titleCreateSubscription: string[] = [
    '1. To create subscription choose your account size',
    '2. Select a trading platform',
    '3. Confirm your subscription',
    '4. Enter credit card details',
  ];

  public readonly titleResetAccount: string[] = [
    'Reset account',
    'Credit card details'
  ];

  public readonly titleChangePayment: string[] = [
    'Change payment',
    'Credit card details'
  ];

  public readonly titleAgreementPayment: string[] = [
    'Details of Your Pro Account',
    'Enter credit card details'
  ];
}
