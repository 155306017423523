export enum AccountType {
  PRO_REQUESTED,
  PRO,
  TEST,
  PRO_DECLINED,
  PRO_CONFIRMED,
  PRO_PLUS_REQUESTED,
  PRO_PLUS,
  EXPRESS,
}
