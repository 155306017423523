import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-dialog-title',
  templateUrl: 'dialog-title.component.html',
})
export class DialogTitleComponent {
  public readonly closeIcon: string = '/assets/close-icon.svg';
  public readonly logoIcon: string = '/assets/desktop-logo.svg';

  @Input()
  public set title(title: string) {
    if (this._title === title) {
      return;
    }

    this._title = title;
  }

  public get title(): string {
    return this._title ?? '';
  }

  @Input()
  public set showClose(showClose: boolean) {
    if (this._showClose === showClose) {
      return;
    }

    this._showClose = showClose;
  }

  public get showClose(): boolean {
    return this._showClose ?? true;
  }

  @Input()
  public set showIcon(showIcon: boolean) {
    if (this._showIcon === showIcon) {
      return;
    }

    this._showIcon = showIcon;
  }

  public get showIcon(): boolean {
    return this._showIcon ?? true;
  }

  private _title: string;
  private _showClose: boolean;
  private _showIcon: boolean;
}
