<lib-header-user-info></lib-header-user-info>
<div class="container">
  <div class="user-info-nav">
    <div class="back-link">
      <a routerLink="/control-center">
      <img
        id="back-icon"
        class="back-arrow"
        src="assets/user-info/back.png"
        alt="go back"
      />
      <span class='link-text'>{{
        'back-to-control-center' | translate
        }}</span></a>
    </div>
    <div class="overflow-box">
      <ul class="list">
        <li
          *ngFor="let item of links"
          class="link-container"
          routerLinkActive="active-rout"
        >
          <a [routerLink]="item?.url" class="list-link">{{
            item?.title | translate
          }}</a>

          <lib-notification [notification]="item?.notification" [small]="true">
          </lib-notification>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="container">
  <router-outlet></router-outlet>
</div>
