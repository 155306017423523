import { UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'lib-pending-affiliate-window',
  templateUrl: './pending-affiliate-window.component.html',
  styleUrls: ['./pending-affiliate-window.component.scss']
})

export class PendingAffiliateWindowComponent {
  icon = '/assets/success registration.svg';
}
