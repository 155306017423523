<nav>
  <ul>
    <li *ngFor="let item of MenuLinks">
      <a [routerLink]="item?.url"
        >{{ item?.title | translate }}
        <lib-notification
          *ngIf="item?.notification"
          [small]="true"
          [toCalendar]="true"
          [notification]="item?.notification"
        >
        </lib-notification
      ></a>
    </li>
    <hr />
    <li class="tablet-guide">
      <a
        id="freeGuide"
        target="_blank"
        href="https://takeprofittrader.myclickfunnels.com/playbook--36698"
        class="element knowledge-base"
        >{{ 'free-playbook' | translate }}</a
      >
    </li>
    <li class="tablet">
      <a
        id="knowledgeBase"
        target="_blank"
        href="https://takeprofittraderhelp.zendesk.com/hc/en-us"
        class="element knowledge-base"
        >{{ 'knowledge-base' | translate }}</a
      >
    </li>
    <li class="tablet">
      <a
        id="become-an-affiliate"
        href="#"
        class="element knowledge-base"
        (click)="addNewAffiliateCode($event)"
        >{{ 'become-an-affiliate' | translate }}</a
      >
    </li>
    <hr class="tablet" />
    <li (click)="signOut()">{{ 'sign-out' | translate }}</li>
  </ul>
</nav>
