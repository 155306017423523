import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-date-calendar-by-parts',
  templateUrl: './date-calendar-by-parts.component.html',
  styleUrls: ['./date-calendar-by-parts.component.scss']
})
export class DateCalendarByPartsComponent implements OnInit {

  @Output() calendarData: EventEmitter<string> = new EventEmitter<string>();

  months = [
    { value: 1, name: 'january' },
    { value: 2, name: 'february' },
    { value: 3, name: 'march' },
    { value: 4, name: 'april' },
    { value: 5, name: 'may' },
    { value: 6, name: 'june' },
    { value: 7, name: 'july' },
    { value: 8, name: 'august' },
    { value: 9, name: 'september' },
    { value: 10, name: 'october' },
    { value: 11, name: 'november' },
    { value: 12, name: 'december' }
  ];

  days: number[] = [];
  years: number[] = [];

  month: FormControl = new FormControl(new Date().getMonth() + 1);
  date: FormControl = new FormControl(null);
  year: FormControl = new FormControl(new Date().getFullYear());
  combinedDate = '';

  ngOnInit() {
    this.populateYears();
    this.onMonthOrYearChange();
  }

  populateYears() {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 100 }, (v, k) => currentYear - k);
  }

  onMonthOrYearChange() {
    this.populateDays();
    if (this.date.value > this.days.length) {
      this.date.setValue(this.days.length);
    }
    this.combineDate();
  }

  populateDays() {
    const daysInMonth = this.getDaysInMonth(this.month.value, this.year.value);
    this.days = Array.from({ length: daysInMonth }, (v, k) => k + 1);
  }

  getDaysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }

  combineDate() {
    if (this.month.value && this.date.value && this.year.value) {
      this.combinedDate = `${this.year.value}-${this.padNumber(this.month.value)}-${this.padNumber(this.date.value)}`;
      this.calendarData.emit(this.combinedDate);
    }

  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

}
