import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WalletNotificationService {

  // tslint:disable-next-line:no-empty
  constructor() { }
}
