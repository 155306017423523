import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
@Injectable()
export class ServerURLInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private readonly _platformId: object) {}

  intercept(
    // TODO: fix any
    // tslint:disable-next-line:no-any
    req: HttpRequest<any>,
    next: HttpHandler
    // TODO: fix any
    // tslint:disable-next-line:no-any
  ): Observable<HttpEvent<any>> {
    const url = isPlatformServer(this._platformId)
      ? ('https://takeprofittrader.com' + req.url).replace('./', '/')
      : req.url;
    const dupReq = req.clone({ url });

    // TODO: fix any
    // tslint:disable-next-line:no-any
    return next.handle(dupReq).pipe(
      // TODO: fix any
      // tslint:disable-next-line:no-any
      map((event: HttpEvent<any>) => {
        // TODO: Fix magic number
        // tslint:disable-next-line:no-magic-numbers
        if (
          event instanceof HttpResponse &&
          // tslint:disable-next-line:no-magic-numbers
          event.status >= 200 &&
          // tslint:disable-next-line:no-magic-numbers
          event.status < 300
        ) {
          return event.clone({
            body: event.body?.result,
          });
        }

        return event;
      })
    );
  }
}
