import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ErrorFormService, ErrorsMap, IErrorMessage, NotificationService, TypeMessage } from 'repository';

@Component({
  selector: 'lib-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnChanges {

  @Input() nextButton = 'next-up';
  @Input() form: FormGroup;
  @Input() showLogin = true;

  @Output() loginEvent = new EventEmitter();
  @Output() sendFormEvent = new EventEmitter();

  public yesChecked: boolean[] = [];
  public noChecked: boolean[] = [];

  public errorMessages: ErrorsMap<IErrorMessage> = {};
  public errors: ErrorsMap<string> = {};

  formList = [
    {
      id: 1,
      question: 'How did you hear about the Take profit Trader affiliate program?',
      questionTranslate: 'questionnaire-1',
      type: 1,
      validators: true
    },
    {
      id: 2,
      question: 'Have you worked as an affiliate with other brands in the past? If so, please provide names.',
      questionTranslate: 'questionnaire-2',
      type: 2,
      validators: true
    },
    {
      id: 3,
      question: 'Are you currently working as an affiliate with any other prop firms? If so, please provide names.',
      questionTranslate: 'questionnaire-3',
      type: 1,
      validators: true
    },
    {
      id: 4,
      question: 'Please share details about how you plan to promote Take profit Trader.',
      questionTranslate: 'questionnaire-4',
      type: 1,
      validators: true
    },
    {
      id: 5,
      question: 'If you are planning to promote TPT via Social media please provide the links to your social media accounts.',
      questionTranslate: 'questionnaire-5',
      type: 1,
      validators: true
    },
    {
      id: 6,
      question: 'If you are planning to promote TPT via website/blog/discord group, please provide links to those sites.',
      questionTranslate: 'questionnaire-6',
      type: 1,
      validators: true

    },
    {
      id: 7,
      question: 'Please confirm that you are the owner of the website, social media account, group that you have provided above',
      questionTranslate: 'questionnaire-7',
      type: 3,
      validators: true
    }
  ];

  constructor(private fb: FormBuilder,
              private readonly notification: NotificationService,
              private readonly _errorFormService: ErrorFormService
  ) {
  }

  toggleYes(index: number, id: number, value: boolean) {

    if (!value) {
      this.toggleNo(index, id, true);

      return;
    }

    this.yesChecked[index] = true;
    this.noChecked[index] = false;

    this.form.get('response_' + id).reset();
    this.form.get('response_' + id).setValidators([Validators.required]);
    this.form.get('response_' + id).updateValueAndValidity();
  }

  toggleNo(index: number, id: number, value: boolean) {
    if (!value) {
      this.toggleYes(index, id, true);

      return;
    }

    this.noChecked[index] = true;
    this.yesChecked[index] = false;

    this.form.get('response_' + id).setValue('No');
    this.form.get('response_' + id).clearValidators();
    this.form.get('response_' + id).updateValueAndValidity();
  }

  ngOnChanges() {
    this.buildForm();
  }

  buildForm() {
    if (this.formList?.length > 0) {
      this.formList.forEach(field => {
        let control: FormControl;
        switch (field.type) {
          case 3:
            control = field.validators
              ? this.fb.control('', [this.checkboxRequired])
              : this.fb.control('');
            break;
          default:
            control = field.validators
              ? this.fb.control('', [Validators.required])
              : this.fb.control('');
            break;
        }

        this.errorMessages['response_' + field.id] = {
          required: 'Answer is required'
        };

        this.form.addControl('response_' + field.id, control);
      });
    }

  }

  login(): void {
    this.loginEvent.emit();
  }

  test(): void {

    if (this.form.invalid) {
      this.errors = this._errorFormService.verifyError(this.form, this.errorMessages);

      this.notification.showBlankNotification(
        'One or more required fields are not filled in',
        TypeMessage.ERROR
      );

     return;
    }

      const outputArray = Object.keys(this.form.value).map(key => {
        // tslint:disable-next-line:radix
        const keyId = parseInt(key.split('_')[1]);

        return {
          question: this.formList.find(item => item.id === keyId).question,
          answer: String(this.form.value[key]).trim()
        };
      });

    this.sendFormEvent.emit(outputArray);
  }

  private checkboxRequired = (control: AbstractControl) => {
    return control.value === true ? null : { required: true };
  };

  private readonly trimValidator = (
    control: FormControl
  ): ValidationErrors | null => {
    if (control.value) {
      const value = control.value?.toString() || '';
      const trimmedValue = value.trim();
      if (trimmedValue !== control.value) {
        control.setValue(trimmedValue, { emitEvent: false });
      }
      if (trimmedValue.length === 0) {
        return { required: true };
      }
    }

    return null;
  };
}
