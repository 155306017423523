import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { SubscriptionStatus, TestStatus } from '../enums';
import { ISubscriptions } from '../interfaces/i-subscription';
import { ITradingData } from '../interfaces/trading-data.interface';
import {
  AccountType,
  IItems,
  IPaymentResponse,
  IPlaidCreateLinkToken,
  IPlaidInfo,
  IPromocodeActionType,
  ISuccessRequest, IUpdateTradingFields,
  IUserSubscriptions, IValidateFields,
  PaymentTransactionActionType, PlaidVerificationStatus
} from 'repository';
import { map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import * as moment from 'moment';
import { ICurrentPaymentService, IGetSessionTokenNuvei, ISessionToken } from 'create-subscription';
import { ICountry } from 'additional-info';

export const status: SubscriptionStatus[] = [
  SubscriptionStatus.PENDING,
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.DISABLED,
  SubscriptionStatus.FREE,
  SubscriptionStatus.ON_HOLD
];

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  showAlertChangePayment$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  identityVerificationToken$ = new BehaviorSubject<{ plaidClientId: string, plaidVerificationStatus: PlaidVerificationStatus }>(
    {} as { plaidClientId: string, plaidVerificationStatus: PlaidVerificationStatus }
  );

  constructor(private readonly _http: HttpClient) {
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  getCredentials(id: string): Observable<any> {
    return this._http.get(
      `${environment.accountsUrl}/api/user-subscriptions/${id}/credentials`
    );
  }

  closeOpenPositions(id: number): Observable<ISuccessRequest<null>> {
    return this._http.put(
      `${environment.accountsUrl}/api/accounts/${id}/liquidate-position`,
      {}
    );
  }

  getChosenSubscription(id: string | number): Observable<IUserSubscriptions> {
    return this._http.get<IUserSubscriptions>(
      `${environment.accountsUrl}/api/user-subscriptions/${id}`
    );
  }

  getFirstResetPromocode(
    id: string | number
  ): Observable<IPromocodeActionType> {
    return this._http.get<IPromocodeActionType>(
      `${environment.paymentsUrl}/api/Payments/subscription-promocode/${id}`
    );
  }

  getSubscriptionsDetails(): Observable<IItems<ISubscriptions>> {
    return this._http.get<IItems<ISubscriptions>>(
      `${environment.accountsUrl}/api/subscriptions`
    );
  }

  public getCurrentPaymentMethod(
    accountId: number
  ): Observable<ICurrentPaymentService> {
    return this._http.get<ICurrentPaymentService>(
      `${environment.paymentsUrl}/api/PaymentServices/${accountId}`
    );
  }

  getSubscriptionTransactions(
    accountId: number
  ): Observable<ISuccessRequest<IPaymentResponse[]>> {
    return this._http.get<ISuccessRequest<IPaymentResponse[]>>(
      `${environment.paymentsUrl}/api/payments/transactions/${accountId}`
    );
  }

  getTradingData(
    id: string | number,
    tradingPlatformId: number
  ): Observable<IItems<ITradingData>> {
    const endPoint =
      +tradingPlatformId === 1 ? 'tradovate-trading-data' : 'trading-data';

    return this._http.get<IItems<ITradingData>>(
      `${environment.accountsUrl}/api/${endPoint}?accountId=${id}`
    );
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  disablePaymentMethod(id: string): Observable<any> {
    return this._http.post(
      `${environment.accountsUrl}/api/Accounts/${id}/disable`,
      {}
    );
  }

  hasActualTradingData(subscription: IUserSubscriptions): Observable<boolean> {
    if (!subscription.tradingAccountDisabledAt) return of(true);

    const startDayTimestamp = (date: string | Date): number => {
      const dateString = moment(date).format('YYYY-MM-DD');

      return new Date(dateString).getTime();
    };

    const toDayOnly = new Date(moment().utc().format('YYYY-MM-DD')).getTime();

    const isActualTradingData = (tradingData: ITradingData) => {
      const tradingAccountDisabledAtTimestamp = startDayTimestamp(
        subscription.tradingAccountDisabledAt
      );

      const tradingAccountDisabledAtTimestampDay = new Date(
        moment(tradingAccountDisabledAtTimestamp).utc().format('YYYY-MM-DD')
      ).getTime();

      return tradingAccountDisabledAtTimestampDay < toDayOnly;
    };

    return this.getTradingData(
      String(subscription.id),
      subscription.platformType
    ).pipe(
      map((response) => response.items),
      map((tradingData) => {
        const [data] = tradingData;

        return data && data.brokerAccountId !== null
          ? isActualTradingData(tradingData[0])
          : false;
      })
    );
  }

  isResetAccount = (subscription: IUserSubscriptions): boolean => {
    if (subscription && !subscription?.endPeriod) {
      subscription.endPeriod = new Date(subscription?.endPeriod);
    }

    return (
      this.subscriptionStatusIsDisabled(subscription) &&
      this.tradingAccountStatusIsDisabled(subscription) &&
      this.resetSubscriptionDependingOnType(subscription) &&
      this.subscriptionEndPeriodActive(subscription) &&
      !subscription?.resetAt
    );
  };

  isMenu(subscription: IUserSubscriptions): boolean {
    switch (subscription?.accountType) {
      case AccountType.PRO:
        return (
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.PENDING &&
          (subscription?.previousAccounts.length < 3 ||
            (status[subscription?.tradingAccountStatus] !==
              SubscriptionStatus.DISABLED &&
              !subscription?.resetAt))
        );

      case AccountType.TEST:
      case AccountType.PRO_DECLINED:
        return (
          status[subscription?.status] !== SubscriptionStatus.PENDING &&
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.PENDING &&
          (this.isCancel(subscription) || this.isResetAccount(subscription))
        );
      case AccountType.PRO_CONFIRMED:
        return false;
      default:
        return false;
    }
  }

  isCancel(subscription: IUserSubscriptions): boolean {
    switch (subscription?.accountType) {
      case AccountType.PRO_PLUS:
      case AccountType.PRO:
        return (
          status[subscription?.status] !== SubscriptionStatus.DISABLED &&
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.DISABLED
        );
      case AccountType.TEST:
        return (
          (status[subscription?.status] === SubscriptionStatus.ACTIVE ||
          status[subscription?.status] === SubscriptionStatus.FREE) &&
          this.isDifferenceLessThan30Days(subscription.startPeriod,subscription.endPeriod)
        );
      case AccountType.PRO_DECLINED:
        return (
          status[subscription?.status] === SubscriptionStatus.ACTIVE ||
          status[subscription?.status] === SubscriptionStatus.FREE
        );
      default:
        return false;
    }
  }

  getDateDifference(startDate: Date, endDate: Date): number {
    const start = moment(startDate);
    const end = moment(endDate);

    if (end.format('YYYY-MM-DD') === '1970-01-01') {
      return 99;
    }

    return end.diff(start, 'days');
  }

  public isWithdraw(subscription: IUserSubscriptions | undefined): boolean {
    if (subscription?.accountType) {
      return (
        subscription?.accountType === AccountType.PRO ||
        subscription?.accountType === AccountType.PRO_PLUS
      );
    }

    return false;
  }

  public isPro(subscription: IUserSubscriptions | undefined): boolean {
    return (
      subscription?.accountType === AccountType.PRO ||
      subscription?.accountType === AccountType.PRO_CONFIRMED
    );
  }

  getSessionToken(body: IGetSessionTokenNuvei): Observable<ISessionToken> {
    return this._http.post<ISessionToken>(
      `${environment.paymentsUrl}/api/NuveiPayments/openOrder`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  editSubscriptionNuvei(body: {
    accountId: number;
    userPaymentOptionId: string;
    userId: number;
    // tslint:disable-next-line:no-any
  }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.paymentsUrl}/api/Subscriptions/edit-subscription-nuvei`,
      body
    );
  }

  getCountryList(): Observable<IItems<ICountry>> {
    return this._http.get<IItems<ICountry>>(
      environment.accountsUrl + '/api/Countries'
    );
  }

  // tslint:disable-next-line:no-any
  pendingNuveiTransactionCommand(body: {
    clientUniqueId: string;
    accountId: number;
    promocodeId: number;
    action: PaymentTransactionActionType;
    amount: number;
    // tslint:disable-next-line:no-any
  }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      environment.paymentsUrl + '/api/Payments/nuvei/pending',
      body
    );
  }

  createPlaidLinkToken(body: {
    clientUserId: string,
    product: number,
    userId: number
  }): Observable<IPlaidCreateLinkToken> {
    return this._http.post<IPlaidCreateLinkToken>(
      `${environment.identityUrl}/api/plaid-users/plaid-link-token`,
      body
    );
  }

  setVerification(plaidClientId: string) {
    const body = {
      GaveConsent: true,
      IsShareable: true,
      clientUserId: plaidClientId
    };

    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.identityUrl}/api/plaid-users/identity-verification-create`,
      body
    );
  }

  identityVerificationRetry(userId: number) {
    const body = {
      clientUserId: btoa(String(userId))
    };

    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.identityUrl}/api/plaid-users/identity-verification-retry`,
      body
    );
  }

  updateUserTradingData(body: IUpdateTradingFields) {
    // tslint:disable-next-line:no-any
    return this._http.put<any>(
      `${environment.identityUrl}/api/users/user-trading-info`,
      body
    );
  }

  updatePlaidInfo(body: {
    identityVerificationId: string,
    isVerified: boolean
  }, plaidId: number): Observable<IPlaidInfo> {
    return this._http.put<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/${plaidId}`,
      body
    );
  }

  createPlaidRequest(body: {
    plaidClientId: string;
    userId: number;
  }): Observable<IPlaidInfo> {
    return this._http.post<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/`,
      body
    );
  }

  loadPlaidCustomer(id: number): Observable<IPlaidInfo> {
    return this._http.get<IPlaidInfo>(
      `${environment.paymentsUrl}/api/PlaidCustomers/${id}`
    );
  }

  private subscriptionStatusIsDisabled(
    subscription: IUserSubscriptions
  ): boolean {
    switch (subscription?.accountType) {
      case AccountType.PRO:
        return (
          status[subscription?.status] !== SubscriptionStatus.PENDING &&
          status[subscription?.status] !== SubscriptionStatus.DISABLED
        );
      case AccountType.TEST:
      case AccountType.PRO_DECLINED:
      case AccountType.EXPRESS:
        return status[subscription?.status] !== SubscriptionStatus.PENDING;
      default:
        return false;
    }
  }

  private tradingAccountStatusIsDisabled(
    subscription: IUserSubscriptions
  ): boolean {
    switch (subscription?.accountType) {
      case AccountType.PRO:
        return (
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.PENDING &&
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.ACTIVE &&
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.ON_HOLD
        );
      case AccountType.TEST:
      case AccountType.PRO_DECLINED:
      case AccountType.EXPRESS:
        return (
          status[subscription?.tradingAccountStatus] !==
          SubscriptionStatus.PENDING
        );
      default:
        return false;
    }
  }

  private resetSubscriptionDependingOnType(
    subscription: IUserSubscriptions
  ): boolean {
    const freeAccount = 3;
    switch (subscription?.accountType) {
      case AccountType.PRO:
        return subscription?.previousAccounts.length < 3;
      case AccountType.TEST:
      case AccountType.PRO_DECLINED:
      case AccountType.EXPRESS:
        return subscription?.testStatus !== TestStatus.Passed;
      default:
        return false;
    }
  }

  private isDifferenceLessThan30Days(startDate: string | Date, endDate: string | Date): boolean {
    // Преобразуем входные данные в объекты Date
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);

    // Вычисляем разницу в миллисекундах
    const diffInMs = Math.abs(d1.getTime() - d2.getTime());

    // Переводим миллисекунды в дни
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // Проверяем, больше ли разница 30 дней
    return diffInDays < 32;
  }

  private subscriptionEndPeriodActive(
    subscription: IUserSubscriptions
  ): boolean {
    const freeAccount = 3;

    switch (subscription?.accountType) {
      case AccountType.PRO:
        return true;
      case AccountType.TEST:
      case AccountType.PRO_DECLINED:
      case AccountType.EXPRESS:
        if (subscription.status === freeAccount) {
          return true;
        } else if (status[subscription?.status] === SubscriptionStatus.ACTIVE) {
          return true;
        } else {
          return (
            new Date(subscription?.endPeriod).getTime() > new Date().getTime()
          );
        }
      default:
        return false;
    }
  }
}
