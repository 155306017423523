import { AfterViewInit, Component, Input } from '@angular/core';
import { TranslateService } from 'localization';
import { LocalStorage } from 'repository';

@Component({
  selector: 'lib-change-lang',
  templateUrl: 'change-lang.component.html',
  styleUrls: ['change-lang.component.scss'],
})
export class ChangeLangComponent implements AfterViewInit {
  @Input()
  public set menu(menu: boolean) {
    if (this._menu === menu) {
      return;
    }
    this._menu = menu;
  }

  public get menu(): boolean {
    return this._menu;
  }

  get setActiveEn(): boolean {
    return this._setActiveEn;
  }

  set setActiveEn(setActiveEn: boolean) {
    if (this.setActiveEn === setActiveEn) {
      return;
    }
    this._setActiveEn = setActiveEn;
  }

  get setActiveEs(): boolean {
    return this._setActiveEs;
  }

  set setActiveEs(setActiveEs: boolean) {
    if (this.setActiveEs === setActiveEs) {
      return;
    }
    this._setActiveEs = setActiveEs;
  }

  public readonly usaFlag: string = '/assets/flags/united-states.png';
  public readonly spainFlag: string = '/assets/flags/spain.png';

  private _setActiveEn = false;
  private _setActiveEs = false;
  private _menu = false;

  constructor(
    public readonly _translateService: TranslateService,
    private readonly _localStorage: LocalStorage
  ) {}

  ngAfterViewInit(): void {
    const lang: string = this._localStorage.getItem('lang');
    if (!lang) {
      this.setLang('en');

      return;
    }

    this.setLang(lang);
  }

  setLang(sp: string): void {
    this._translateService.changeLang(sp);

    switch (sp) {
      case 'es':
        this.setActiveEn = false;
        this.setActiveEs = true;
        break;
      default:
        this.setActiveEn = true;
        this.setActiveEs = false;
        break;
    }

    this._localStorage.setItem('lang', sp);
  }
}
