import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { User } from 'user';
import data from '../../../../../src/data/header-links.json';
import { NavigationService } from '../../../../../src/services/header-navigation-service';
import { ModalService } from '@app/_modal';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';

interface ILink {
  link: string;
  fragment?: string;
  target?: string;
  text: string;
}

@UntilDestroy()
@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  links: ILink[] = data;
  isShowToHeaderBtn = false;
  queryParams = {};
  redirectUrl = '';

  @Output() login: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly _navigation: NavigationService,
    public readonly modalService: ModalService,
    public readonly user: User,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    // tslint:disable-next-line:no-any
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}

  @HostListener('document:scroll', [])
  onScrollToHeader(): void {
    let scrollTop = 0;
    let scrollTop2 = 0;
    if (isPlatformBrowser(this._platformId)) {
      scrollTop = document.body.scrollTop;
      scrollTop2 = document.documentElement.scrollTop;
    }
    if (scrollTop > 400 || scrollTop2 > 400) {
      this.isShowToHeaderBtn = true;

      return;
    }

    this.isShowToHeaderBtn = false;
  }

  back(): void {
    this._navigation.back();
  }

  backToAccountSizes(): void {
    this._navigation.backToAccountSizes();
  }

  backToSection(sectionLink: string, queryParams?: object): void {
    this._navigation.backToParticularSection(sectionLink, queryParams);
  }

  toggleLogin(): void {
    this.login.emit(true);
    this.modalService.close('mobile-menu-modal');
  }

  public goToSMarket(): void {
    if (isPlatformBrowser(this._platformId)) {
      document.getElementById('s-market').scrollIntoView();
    }
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.queryParams = val;
      });

    this._activatedRoute.fragment
      .pipe(untilDestroyed(this))
      .subscribe((fragment) => {
        if (fragment) {
          if (isPlatformBrowser(this._platformId)) {
            document.querySelector('#' + fragment).scrollIntoView();
          }
        }
      });
  }
}
