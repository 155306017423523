import * as moment from 'moment/moment';

export const timeComparison = (dateTime: string | Date): boolean => {
  const toDay = new Date(
    moment().utc().format('YYYY-MM-DDT03:00:00Z')
  ).getTime();
  const date = new Date(
    moment(dateTime).utc().format('YYYY-MM-DDTHH:00:00Z')
  ).getTime();

  return date >= toDay;
};
