<lib-modal-window
  [isVisible]="show"
  (isClose)="close()"
  [isLoading]="isLoading"
>
  <div>
    <div class="takeprofit-title">
      <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
    </div>
    <nz-tabset [nzSelectedIndex]="tabIndex">
      <nz-tab>
        <lib-form-for-sign-up
          #step
          [(isLoading)]="isLoading"
          [(isShowResendConfirmEmail)]="isShowResendConfirmEmail"
          (next)="nextStep()"
          (showLogin)="login()"
        ></lib-form-for-sign-up>
      </nz-tab>
      <nz-tab>
        <lib-confirm-email #step (showChange)="close()"></lib-confirm-email>
      </nz-tab>
    </nz-tabset>
  </div>
</lib-modal-window>

<lib-resend-confirm-email
  *ngIf="isShowResendConfirmEmail"
  [(isShow)]="isShowResendConfirmEmail"
></lib-resend-confirm-email>
