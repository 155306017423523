import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PopUpSize } from 'common-pop-up';
import { ErrorFormService, ErrorHttpService, ErrorsMap, IErrorMessage } from 'repository';
import { IBodyResetPassword } from '../interfaces/ibody-reset-password.inrterface';
import { ForgotPasswordService } from '../services/forgot-password.service';

@Component({
  selector: 'lib-login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss'],
})
export class LoginResetPasswordComponent {
  form: FormGroup;
  showPassword: boolean;
  showConfirmedPassword: boolean;
  requirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9_]).{8,}$/;
  errors: ErrorsMap<string> = {};
  readonly errorMessages: ErrorsMap<IErrorMessage> = {
    password: {
      required: 'Password is required',
    },
    confirmPassword: {
      required: 'Confirm password has to equal to the password',
    },
  };

  @Input() show: boolean;
  @Input() showCloseBtn: boolean;
  @Output() nativeOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nativeReset: EventEmitter<boolean> = new EventEmitter<boolean>();

  PopUpSize = PopUpSize;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _forgotPasswordService: ForgotPasswordService,
    private readonly _errorFormService: ErrorFormService,
    private readonly _errorHttpService: ErrorHttpService,
  ) {
    this.showCloseBtn = false;
    this.showPassword = false;
    this.showConfirmedPassword = false;
    this.form = this._formBuilder.group({
      password: [null, [Validators.required, this._validatePassword]],
      confirmPassword: [null, [Validators.required, this._checkPassword]],
    });
  }

  signUp(): void {
    this.nativeReset.emit(true);
  }

  close(): void {
    this.show = false;
    this.nativeOpen.emit(false);
  }

  ngSubmit(values: IBodyResetPassword): void {
    const form: FormGroup = this.form;
    const formValue = form.value;

    this.errors = this._errorFormService.verifyError(form, this.errorMessages);

    if (this.requirements.test(formValue.password) === false) {
      this.errors.password = 'Password did not pass requirements';
    }

    if (formValue.password !== formValue.confirmPassword) {
      this.errors.confirmPassword = 'Confirm password have to equal to the password';
    }

    if (form.invalid) return;

    values.code = this._forgotPasswordService.getUrlVariable('code');
    this._forgotPasswordService.resetPassword(values).subscribe(
      (res) => {
        this.nativeReset.emit(true);
      },
      (error) => this._errorHttpService.showMessage(error)
    );
  }

  passwordIconUrl = (show: boolean): string => `assets/password_kit/${show ? 'show' : 'hide'}.svg`;

  handlePasswordType = (show: boolean): string => (show ? 'text' : 'password');

  private readonly _validatePassword = (control: FormGroup): boolean | { notValid: boolean } => {
    const password: string = control.value;

    return this.requirements.test(password) ? null : { notValid: true };
  };

  private readonly _checkPassword = (control: FormControl): boolean | { notSame: boolean } => {
    if (!control.value) return;

    if (control.value !== this.form.controls.password.value) {
      return { notSame: true };
    }
  };
}
