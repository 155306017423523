import { NgModule } from '@angular/core';
import { WalletNotificationComponent } from './notification/wallet-notification.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Translate } from 'localization';

@NgModule({
  declarations: [WalletNotificationComponent],
  imports: [RouterModule, CommonModule, Translate],
  exports: [WalletNotificationComponent],
})
export class WalletNotificationModule {}
