<lib-modal-window [isVisible]="show" (isClose)="close()">
  <img class="letter-icon" src="./assets/logos/mail.svg" alt="" />
  <br />
  <p id="time" class="text">
    {{ 'forgot-password-message' | translate}}
  </p>

  <div class="div-button">
    <button class="primary-btn" (click)="close()">
      {{ 'ok' | translate }}
    </button>
  </div>
</lib-modal-window>
