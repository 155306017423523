import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PopUpSize } from 'common-pop-up';

@Component({
  selector: 'lib-login-info-send-mail',
  templateUrl: './login-info-send-mail.component.html',
  styleUrls: ['./login-info-send-mail.component.scss'],
})
export class LoginInfoSendMailComponent implements OnChanges {
  @Input() show: boolean;
  @Input() loginForgotPassComponent: { form?: { controls?: { email: {} } } };
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  blurredEmail: string;
  emailInput: { value?: string };

  PopUpSize = PopUpSize

  constructor() {
    this.show = false;
    this.blurredEmail = '';
  }

  ngOnChanges(): void {
    this.emailInput = this.loginForgotPassComponent?.form?.controls?.email;
    this.blurredEmail = this.handleEmail(this.emailInput?.value);
  }

  handleEmail(email: string): string {
    if (!email) return;

    const atIndex: number = email?.indexOf('@');
    const emailProvider: string = email?.slice(atIndex);

    return '' + email[0] + '******' + emailProvider;
  }

  close(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }
}
