<lib-modal-window [isVisible]="show" (isClose)="close()">
  <h1>{{ 'are-you-sure-you-want-to-pay-with-credits' | translate }}</h1>
  <h3 *ngIf="type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION">
    {{ 'your-subscription-purchased' | translate }}
  </h3>

  <div class="nav-btn">
    <button class="cancel-btn primary-btn" (click)="close()">{{ 'no' }}</button>
    <button class="delete-btn primary-btn" (click)="pay()">
      {{ 'yes' | translate }}
    </button>
  </div>
</lib-modal-window>
