import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserCabinetPageComponent } from './user-cabinet-page/user-cabinet-page.component';

const routes: Routes = [
  {
    path: '',
    component: UserCabinetPageComponent,
    children: [
      {
        path: 'password',
        loadChildren: () =>
          import('password-info').then((m) => m.PasswordInfoModule),
      },
      {
        path: 'wallet',
        loadChildren: () =>
          import('wallet-info').then((m) => m.WalletInfoModule),
      },
      {
        path: 'personal',
        loadChildren: () =>
          import('personal-info').then((m) => m.PersonalInfoModule),
      },

      {
        path: 'rewards',
        loadChildren: () => import('rewards').then((m) => m.RewardsModule),
      },
      {
        path: 'credit-bank',
        loadChildren: () =>
          import('credit-bank').then((m) => m.CreditBankModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CabinetPageRoutingModule {}
