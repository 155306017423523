import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly _history: string[] = [];

  constructor(
    private readonly _router: Router,
    private readonly _location: Location
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl('/');
    }
  }

  backToAccountSizes(): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._router.navigateByUrl('/#account-sizes');
    } else {
      this._router.navigateByUrl('/#account-sizes');
    }
  }

  backToParticularSection(sectionLink: string, queryParams?: object): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._router.navigate([`/`], { queryParams, fragment: sectionLink });
    } else {
      this._router.navigate([`/`], { queryParams, fragment: sectionLink });
    }
  }
}
