import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AccountsService } from './api/accounts.service';
import { CountriesService } from './api/countries.service';
import { PaymentsService } from './api/payments.service';
import { SubscriptionsService } from './api/subscriptions.service';
import { TradingDataService } from './api/tradingData.service';
import { TradingPlatformsService } from './api/tradingPlatforms.service';
import { UserSubscriptionsService } from './api/userSubscriptions.service';
import { Configuration } from './configuration';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AccountsService,
    CountriesService,
    PaymentsService,
    SubscriptionsService,
    TradingDataService,
    TradingPlatformsService,
    UserSubscriptionsService,
  ],
})
export class AccountsModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<AccountsModule> {
    return {
      ngModule: AccountsModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AccountsModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
