import { SubscriptionSize } from 'create-subscription';

export enum CreditTypeToSubscriptionsEnum {
  FreeTestReset = SubscriptionSize.reset,
  TestAccount12K = SubscriptionSize.$12K,
  TestAccount25K = SubscriptionSize.$25K,
  TestAccount50K = SubscriptionSize.$50K,
  TestAccount75K = SubscriptionSize.$75K,
  TestAccount100K = SubscriptionSize.$100K,
  TestAccount150K = SubscriptionSize.$150K,
  ProActivationFee = SubscriptionSize.$130_PRO_FEE,
}
