import { NgModule } from '@angular/core';
import { ModalWindowComponent } from './modal-window.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { LoaderModule } from 'loader';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ModalWindowComponent],
  imports: [NzModalModule, LoaderModule, CommonModule],
  exports: [ModalWindowComponent],
})
export class ModalWindowModule {}
