/**
 * TPT.Identity.API
 * Welcome to identity swagger API endpoint.
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoginInputModel {
  clientId?: string;
  clientSecret?: string;
  scope?: string;
  username?: string;
  password?: string;
  rememberLogin?: boolean;
  returnUrl?: string;
}
