import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService as NGXTranslateService,
} from '@ngx-translate/core';
import { AppMissingTranslationHandler } from './app-missing-translation.handler';
import { ModuleTranslateLoader } from './module-translate.loader';
import {
  TranslateAlias,
  translateServiceFactory,
} from './translate-service.factory';
import { TranslateService } from './translate.service';

export const translateFactory = translateServiceFactory();

export function _initLanguages(service: TranslateService) {
  return (): Promise<void> => service.initLanguage();
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ModuleTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler,
      },
    }),
  ],
  exports: [TranslateModule],
})
export class Translate {
  public static forRoot(): ModuleWithProviders<Translate> {
    return {
      ngModule: Translate,
      providers: [
        TranslateService,
        {
          provide: APP_INITIALIZER,
          useFactory: _initLanguages,
          deps: [TranslateService],
          multi: true,
        },
      ],
    };
  }

  public static localize(): ModuleWithProviders<Translate> {
    return {
      ngModule: Translate,
      providers: [TranslatePipe],
    };
  }

  public static localizeComponent(
    module: string,
    provider?: unknown
  ): Provider[] {
    return [
      {
        provide: provider || NGXTranslateService,
        useFactory: translateFactory,
        deps: [Injector, TranslateAlias],
      },
      {
        provide: TranslateAlias,
        useValue: module,
      },
    ];
  }

  constructor(
    private readonly _ngxTranslateService: NGXTranslateService,
    private readonly _translateService: TranslateService
  ) {
    this._translateService.register(this._ngxTranslateService);
  }
}
