export enum PaymentTransactionActionType {
  Create,
  Reset,
  Disable,
  CreateAgreement,
  Withdraw,
  DepositWallet,
  CommissionForPartner,
  ChangePaymentMethod,
  AdminDeposit,
  AdminWithdraw,
}
