import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { User } from 'user';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(private readonly _router: Router, private readonly _user: User) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this._user
      .updateUserInfo()
      .toPromise()
      .then(() => {
        const isLogined = this._user.isLogined;
        if (!isLogined) {
          this._router.navigate(['']);
        }

        return isLogined;
      })
      .catch(() => {
        this._router.navigate(['']);

        return false;
      });
  }
}
